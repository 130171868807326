import React, { useState, useEffect } from 'react'

//Styles
import '../assets/style.css'

//Components
import Search from '../components/Search'
import DetailedInfo from '../components/DetailedInfo'
import CounselorDetailedInfo from '../components/counselors/CounselorDetailedInfo'
import CounselorSearch from '../components/counselors/Search'

export default function Counselors({setShowSidebar, activeSchedule, activities, settings, setActivities, setSettings, pullDatabase, counselors, singleSchedule, printScheduleLogic, setPrintScheduleLogic}) {

  setShowSidebar(true);
  var camperOrCounselor = 'Counselor';
  var isActivity = false;
  var location = 'Cabin 1';

  const [counselorIndex, setCounselorIndex] = useState(0);
  // console.log(counselors)
  const sortedCounselors = [...counselors].sort((a, b) => {
    if (a.first_name < b.first_name) {
      return -1;
    }
    if (a.first_name > b.first_name) {
      return 1;
    }
    return 0;
  });  
  const counselor = sortedCounselors[counselorIndex];

  useEffect(() => {
    console.log(counselorIndex)
  }, [counselorIndex])

  return (
    <div className='main-content'>
      <CounselorSearch activeSchedule={activeSchedule}  counselors={sortedCounselors} setCounselorIndex={setCounselorIndex} counselor={counselor} activities={activities} pullDatabase={pullDatabase}/>
      {counselor !== undefined && counselor !== null && <CounselorDetailedInfo activeSchedule={activeSchedule} activities={activities} counselors={sortedCounselors} setCounselorIndex={setCounselorIndex} counselor={counselor} settings={settings} pullDatabase={pullDatabase} singleSchedule={singleSchedule} printScheduleLogic={printScheduleLogic} setPrintScheduleLogic={setPrintScheduleLogic}/>}
    </div>
  )
}