import React, { useState } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';

export default function ActivitiesRequiredCounselorSkills({activeSchedule, activity, activites, pullDatabase, settings}) {

  const [timer, setTimer] = useState(null)
  const [newInputValue, setNewInputValue] = useState({});
  const [shareableSkill, setShareableSkill] = useState(false);

  const handleSkillChange = async (e, activityName) => {
      clearTimeout(timer)

      const newTimer = setTimeout( async () => {
          const updatedValue = e.target.value;
          const formattedName = activityName.replace(/ /g, "_").toLowerCase();
          const docRef = doc(db, 'schedules', activeSchedule, 'activities', formattedName);

          try {
              await runTransaction(db, async (transaction) => {
                  const doc = await transaction.get(docRef);
                  if (!doc.exists()) {
                      throw "Document does not exist!";
                  }
                  const tempDoc = doc.data();

                  const newSkills = {...tempDoc.required_counselor_skills};
                  newSkills[formattedName] = updatedValue;
                  tempDoc.required_counselor_skills = newSkills;
                  console.log(tempDoc)
                  //transaction.update(docRef, tempDoc);
              });
              console.log("Transaction successfully committed!");
          } catch (e) {
              console.log("Transaction failed: ", e);
          }

          //pullDatabase();
      }, 1000)

      setTimer(newTimer)
  }

  const handleNewSkill = (e, activityName, checked) => {
      setNewInputValue({...newInputValue, [activityName]: e.target.value});
      setShareableSkill(checked);
  }

  const submitNewSkill = async (activityName) => {
      const newSkill = newInputValue[activityName];
      const formattedName = activityName.replace(/ /g, "_").toLowerCase();
      const docRef = doc(db, 'schedules', activeSchedule, 'activities', formattedName);

      try {
          await runTransaction(db, async (transaction) => {
              const doc = await transaction.get(docRef);
              if (!doc.exists()) {
                  throw "Document does not exist!";
              }
              const tempDoc = doc.data();
              let newCounselorSkill = {
                  skill: newSkill,
                  shareable: shareableSkill
              }
              tempDoc.required_counselor_skills.push(newCounselorSkill);
              console.log(tempDoc);

              transaction.set(docRef, tempDoc);

          });
          console.log("Transaction successfully committed!");
          setNewInputValue({...newInputValue, [activityName]: ""});
      } catch (e) {
          console.log("Transaction failed: ", e);
      }

      pullDatabase();
  }

  const removeCounselorSkill = async (e, activityName) => {

      const formattedName = activityName.replace(/ /g, "_").toLowerCase();
      const docRef = doc(db, 'schedules', activeSchedule, 'activities', formattedName);
      const element = e.currentTarget;
      const siblingInputValue = element.previousSibling.value;


          try {
              await runTransaction(db, async (transaction) => {
                  const doc = await transaction.get(docRef);
                  if (!doc.exists()) {
                      throw "Document does not exist!";
                  }
                  const tempDoc = doc.data();
                  let filteredArray = tempDoc.required_counselor_skills.filter(
                      obj => obj.skill !== siblingInputValue
                  );
                  tempDoc.required_counselor_skills = filteredArray;
                  console.log(siblingInputValue)
                  console.log(filteredArray);
                  console.log(tempDoc.required_counselor_skills)
                  transaction.update(docRef, tempDoc);
              });
              console.log("Transaction successfully committed!");
          } catch (e) {
              console.log("Transaction failed: ", e);
          }

          pullDatabase();
  }

  return (
    <div className='settings-block skills'>
      <div className='activity'>
          <h5 className='activity-name'>{activity.name}</h5>
          <div className="required-skills" >

              {Object.entries(activity.required_counselor_skills).map(([key, value]) => (
                  <div
                      key={`key${Math.random()}`}
                      className={`required-skill `}
                  >
                    {value.shareable && <span className='shareable-skill'>Shareable</span>}
                      <input
                          type="text"
                          className="required-skill-input"
                          value={value.skill}
                          onChange={(e) => handleSkillChange(e, activity.name)}
                      />
                      <div className="delete-required-skill" onClick={(e) => removeCounselorSkill(e, activity.name)}>x</div>
                  </div>
              ))}
          </div>
          <div className='add-required-skill-wrapper' >
            <div>
            <input
                  type='text'
                  className='add-required-skill-input'
                  value={newInputValue[activity.name]}
                  placeholder="+ Add Counselor Skill"
                  onChange={(e) => handleNewSkill(e, activity.name, shareableSkill)}
              />
              <input
                  type='checkbox'
                  className='is-shared-checkbox'
                  checked={shareableSkill}
                  onClick={() => setShareableSkill(!shareableSkill)}
              />
              <label className='is-shared-label'>Role Can Be Split</label>
            </div>
              <button onClick={() => submitNewSkill(activity.name)}>Submit New Skill</button>
          </div>
      </div>
    </div>
  )
}
