import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from '../../FirebaseConfig.js';


export default function SettingsNumberActivityPreferences({activeSchedule, settings, pullDatabase}) {

    const campersIndex = settings.findIndex((item) => item.settingId === 'campers');

    const [numberPreferences, setNumberPreferences] = useState(settings[campersIndex].number_activity_preferences || "");
    const [timer, setTimer] = useState(null)


    const handleChange = async (e) => {
        // Clear the timeout if it has already been set
        clearTimeout(timer)

        let newValue = e.target.value;
        if (newValue === "") {
            newValue = "";
        }

        setNumberPreferences(newValue);

        let targetName = e.target.name;

        const settingsDoc = settings;
       //console.log(settingsDoc);


        //Set a new timeout that will run the update function after the user has finished typing
        const newTimer = setTimeout( async () => {
          const docRef = doc(db, 'schedules', activeSchedule, 'settings', 'campers');

          try {
            await runTransaction(db, async (transaction) => {
              const doc = await transaction.get(docRef);
              if (!doc.exists()) {
                throw "Document does not exist!";
              }
                const tempDoc = doc.data();
                tempDoc.number_activity_preferences = newValue;

                //console.log(tempDoc);

                transaction.set(docRef, tempDoc);
            });
            console.log("Transaction successfully committed!");
          } catch (e) {
            console.log("Transaction failed: ", e);
          }

          pullDatabase();
        }, 1000);
        setTimer(newTimer)
    }


  return (
    <div className="settings-block">
        <h5>Campers</h5>
        <div className='input-wrapper'>
            <p># of activity preferences for them to rank:</p>
            <input type="number" value={numberPreferences} onChange={(e) => handleChange(e)} />
        </div>
    </div>
  )
}