import React, { useEffect, useState, useRef } from 'react'
import { getDocs, collection } from "firebase/firestore";
import { db } from '../FirebaseConfig';
import { useNavigate } from 'react-router-dom';

import Printer from '../assets/images/printer.svg'

export default function IndividualSchedule({activities, singleSchedule, counselor, camper, activeSchedule, printScheduleLogic, setPrintScheduleLogic}) {
    console.log(singleSchedule);
    const navigate = useNavigate();
    const [counselorList, setCounselorList] = useState(Object.keys(singleSchedule).length && singleSchedule?.counselor_list_data?.counselorListData || []);
    const [camperList, setCamperList] = useState([]);
    const [sortedGDayActivities, setSortedGDayActivities] = useState([]);
    const [sortedKDayActivities, setSortedKDayActivities] = useState([]);

    const [scheduleByActivity, setScheduleByActivity] = useState(Object.keys(singleSchedule).length && singleSchedule?.schedule_by_activity || {});
    let activitiesIdObjRef = useRef({});
    let currentActivityIdObjRef = activitiesIdObjRef.current;

    const getScheduleFieldData = async (fieldName) => {
      const chunkCollection = collection(db, 'schedules', activeSchedule, 'schedule', fieldName, 'chunks');
      const chunkSnapshot = await getDocs(chunkCollection);
      let fieldData;
      chunkSnapshot.forEach(doc => {
        const chunkData = doc.data().arrayData || doc.data();
        if (Array.isArray(chunkData)) {
          fieldData = [...(fieldData || []), ...chunkData];
        } else if(typeof chunkData === 'object' && chunkData !== null) {
          if (chunkData.data) {  // If chunkData has a 'data' property, use its value
            fieldData = [...(fieldData || []), ...chunkData.data];
          } else {
            fieldData = { ...(fieldData || {}), ...chunkData };
          }
        } else {
          console.error(`Data from document ${doc.id} is not an array or an object`, chunkData);
        }
      });
      return fieldData;
    };

    useEffect(() => {
      if(activities) {
  
        activitiesIdObjRef.current = activities.reduce((acc, activity) => {
          acc[activity.activityId] = activity;
          return acc;
        }, {});
  
        currentActivityIdObjRef = activitiesIdObjRef.current;
      }
  
    }, [activities]);

    const renderDay = (day, schedule) => {
        // Otherwise, render the day and periods
        console.log(schedule);

        const masterKPeriods = ['K1', 'K2', 'K3', 'K4', 'K5'];
        const masterGPeriods = ['G1', 'G2', 'G3', 'G4', 'G5'];
    
        const masterPeriods = ['monday', 'wednesday', 'friday'].includes(day.toLowerCase()) ? masterKPeriods : masterGPeriods;
  
        const allPeriodsUnavailable = masterPeriods.every(period => schedule[period] && schedule[period].not_available !== undefined);
          
        return (
          <div className="day" key={day}>
            <h2>{day}</h2>
            {Object.entries(schedule)
            .sort((a, b) => {
              //sort alphanumerically
              return a[0].localeCompare(b[0], undefined, {numeric: true, sensitivity: 'base'});
            })
            .map(([period, value]) => {
              if(value.period_off !== undefined) {
                let period_off = value.period_off;

                return (
                  <h6 test={period} key={period}>
                    <span className="period-off">{period} - {period_off}</span>
                  </h6>
                );
              }
              else if(value.not_available !== undefined) {
                let period_off = value.not_available;

                return (
                  <h6 test={period} key={period}>
                    {allPeriodsUnavailable ? <span className="unavailable">{period} - Day Off</span> : <span className="unavailable">{period} - {period_off}</span>}
                  </h6>
                );
              }
              else {
                let activityName = value.activityId;
                let roleName = value.role;

                // Format the activityName and roleName strings
                activityName = formatString(activityName);
                roleName = formatString(roleName);

                return (
                  <h6 test={period} key={period}>
                    <span className="activity-name">{period} - {activityName}</span> |{" "}
                    <span className="role">{roleName}</span>
                  </h6>
                );
              }
            })}
          </div>
        );
    };

  const formatString = (str) => {
      return str
      .replace(/_/g, " ")
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
      })
      .replace(/\s+/g, " ");
  };

  const handlePrintClick = (id, method) => {
    if(method === 'counselor') {
      console.log('counselor', id);
      setPrintScheduleLogic({
        ...setPrintScheduleLogic,
        showAll: false,
        onlyCampers: false,
        onlyCounselors: true,
        individualCamperId: '',
        individualCounselorId: id,
        counselorsByDay: false,
        activeCabins: [],
        activityRoster: false
      })
    }
    else if(method === 'camper') {
      console.log('camper', id);
      setPrintScheduleLogic({
        ...setPrintScheduleLogic,
        showAll: false,
        onlyCampers: true,
        onlyCounselors: false,
        individualCamperId: id,
        individualCounselorId: '',
        counselorsByDay: false,
        activeCabins: [],
        activityRoster: false
      })
    }

    navigate('/print-schedule');
  };


  const createCamperSchedule = () => {

    let gDayActivities = {};
    let kDayActivities = {};
    const uniquePeriods = new Set();  // It seems like uniquePeriods was not defined before, added a definition here

    Object.entries(scheduleByActivity).forEach(([activity, queriedActivities]) => {
        Object.entries(queriedActivities).forEach(([day, activityData]) => {
            Object.entries(activityData).forEach(([period, activityDetails]) => {
                if (activityDetails.campers.includes(camper.camperId) && !uniquePeriods.has(period)) {
                    uniquePeriods.add(period);
                    const activityInfo = { id: activity, period: period };
                    if (period.startsWith('G')) {
                        gDayActivities[period] = activityInfo;
                    } else if (period.startsWith('K')) {
                        kDayActivities[period] = activityInfo;
                    }
                }
            });
        });
    });

    const sortedGDayActivitiesTemp = Object.entries(gDayActivities).sort(([period1], [period2]) => period1.localeCompare(period2));
    const sortedKDayActivitiesTemp = Object.entries(kDayActivities).sort(([period1], [period2]) => period1.localeCompare(period2));

    return { sortedGDayActivitiesTemp, sortedKDayActivitiesTemp };
  }

  useEffect(() => {
    if (camper) {
      const { sortedGDayActivitiesTemp, sortedKDayActivitiesTemp } = createCamperSchedule();
      setSortedGDayActivities(sortedGDayActivitiesTemp);
      setSortedKDayActivities(sortedKDayActivitiesTemp);
    }

  }, [scheduleByActivity, camper]);

  useEffect(() => {
    console.log(counselorList);
  }, [counselorList]);


  return (
    <div className="detail-info-wrapper">
        <>
          {counselor && counselorList.length > 0 &&
            <>
              <div className='title-wrapper'>
                <h6>Counselor Schedule</h6>
                <img className='printer' src={Printer} alt='printer' onClick={() => handlePrintClick(counselor.counselorId, 'counselor')}/>
              </div>
              <div className="counselors-wrapper">
                  {counselorList &&
                  counselorList.map((individualCounselor, index) => {
                      if(counselor.counselorId === individualCounselor.counselorId) {
                          return (
                              <div className="counselor-wrapper" key={index}>
                                  <div className="counselor">
                                      <>
                                          {renderDay(
                                              "Monday",
                                              individualCounselor.schedule.monday
                                          )}
                                          {renderDay(
                                              "Tuesday",
                                              individualCounselor.schedule.tuesday
                                          )}
                                          {renderDay(
                                              "Wednesday",
                                              individualCounselor.schedule.wednesday
                                          )}
                                          {renderDay(
                                              "Thursday",
                                              individualCounselor.schedule.thursday
                                          )}
                                          {renderDay(
                                              "Friday",
                                              individualCounselor.schedule.friday
                                          )}
                                          {renderDay(
                                              "Saturday",
                                              individualCounselor.schedule.saturday
                                          )}
                                      </>
                                  </div>
                              </div>
                          );
                      }
                  })}
              </div>
            </>
          }
          {camper && sortedGDayActivities && sortedKDayActivities &&
            <>
            <div className='title-wrapper'>
                <h6>Camper Schedule</h6>
                <img className='printer' src={Printer} alt='printer' onClick={() => handlePrintClick(camper.camperId, 'camper')}/>
              </div>
              <div className="campers-wrapper">
                  {
                    <div className={`camper-wrapper print-element`}>
                      {console.log(sortedGDayActivities)}
                        <div className="camper">
                            <h2>K Day</h2>
                            <h2>G Day</h2>
                            <div className="activity-block">
                                <div className="activity-wrapper">
                                    {sortedKDayActivities.map(([period, activity]) => (
                                        <h6 key={period}>{period}</h6>
                                    ))}
                                </div>
                                <div className="activity-wrapper days">
                                    {sortedKDayActivities.map(([period, activity]) => (
                                        <h6 data-period={period || 'random'} key={period}>
                                            {currentActivityIdObjRef[activity.id].name}{activity.randomlyAssigned ? '(random)' : ''}
                                        </h6>
                                    ))}
                                </div>
                            </div>
                            <div className="activity-block">
                                <div className="activity-wrapper">
                                    {sortedGDayActivities.map(([period, activity]) => (
                                        <h6 key={period}>{period}</h6>
                                    ))}
                                </div>
                                <div className="activity-wrapper days">
                                    {sortedGDayActivities.map(([period, activity]) => (
                                        <h6 data-period={period || 'random'} key={period}>
                                            {currentActivityIdObjRef[activity.id].name}{activity.randomlyAssigned ? '(random)' : ''}
                                        </h6>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                  }
              </div>
            </>
          }
        </>
    </div>
  )
}
