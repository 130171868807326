import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from '../../FirebaseConfig.js';



export default function SettingsAvailablePeriods({activeSchedule, settings, pullDatabase}) {

    const dayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    const activitiesIndex = settings.findIndex((item) => item.settingId === 'activities');

    const changePeriod = async (e) => {

        //const parent = e.currentTarget.parentNode;
        const element = e.currentTarget;
        const parent = e.currentTarget.closest('.day-wrapper');
        const day = element.closest('.day-wrapper').querySelector('.day-header');
        const periodWrappers = parent.querySelectorAll('.period-wrapper');
        const selectedDay = day.textContent.toLowerCase();

        console.log(periodWrappers);

        let nextPeriod;
        let tempArray;

        if (e.currentTarget.classList.contains('add-another-period')) {
            const lastPeriod = periodWrappers[periodWrappers.length - 1].textContent;
            console.log(lastPeriod);
            if (lastPeriod.startsWith('K')) {
              const number = parseInt(lastPeriod.slice(1), 10);
              nextPeriod = `K${number + 1}`;
            } else if (lastPeriod.startsWith('G')) {
              const number = parseInt(lastPeriod.slice(1), 10);
              nextPeriod = `G${number + 1}`;
            }
        }

        console.log(nextPeriod);

        //Set a new timeout that will run the update function after the user has finished typing
        const docRef = doc(db, 'schedules', activeSchedule, 'settings', 'activities');

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                let tempArray = tempDoc.days[selectedDay];

                if (element.classList.contains('add-another-period')) {
                    tempArray.push(nextPeriod);
                } else {
                    tempArray.pop();
                }
                tempDoc.days[selectedDay] = tempArray;

                console.log(tempDoc);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }


  return (
    <div className="settings-block days">


            {
                //convert an object to an array
                Object.entries(settings[activitiesIndex].days)
                .sort((a, b) => dayOrder.indexOf(a[0]) - dayOrder.indexOf(b[0]))
                .map(([day, periods], dayIndex) => (
                    <div key={dayIndex} className="day-wrapper">
                        <h6 className="day-header">{day.charAt(0).toUpperCase() + day.slice(1)}</h6>
                        <div className='periods-wrapper'>
                            {periods.map((period, periodIndex) => (
                                <div key={periodIndex} className="period-wrapper"><span className='period'>{period}</span><div className='remove-period minus' onClick={changePeriod}>-</div></div>
                            ))}
                            <div className="add-another-period" onClick={changePeriod}>
                                +
                            </div>
                        </div>
                    </div>
                ))

            }
    </div>
  )
}