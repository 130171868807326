import { auth } from '../FirebaseConfig.js';
import { useState } from 'react'
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { set } from 'lodash';

export default function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');

  const handleSignUp = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      // clear the inputs
      setEmail('');
      setPassword('');
      setSuccessMessage('Account created successfully!');
    } catch (error) {
      console.log(error)
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError(`Sorry, the email address "${email}" is already in use. Please use a different email address.`);
          break;
        case 'auth/invalid-email':
          setError(`Sorry, the email address "${email}" is not valid. Please enter a valid email address.`);
          break;
        case 'auth/weak-password':
          setError(`Sorry, the password is too weak. Please enter a stronger password.`);
          break;
        case 'auth/missing-email':
          setError(`Sorry, you must enter an email address.`);
          break;
        case 'auth/missing-password':
        case 'auth/internal-error':
          setError(`Sorry, you must enter a password.`);
          break;
        default:
          setError(`Sorry, an error occurred: ${error.message}`);
          break;
      }
    }
  };


  return (
    <div id='register'>
      <div className='signup-wrapper'>
        <h1>Signup</h1>
        <input placeholder='Email' type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input placeholder='Password' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button className='signup-btn' onClick={handleSignUp}>
          <h6>Sign Up</h6>
        </button>
      </div>
      {successMessage && <h6>{successMessage}</h6>}
      {error && <h6>{error}</h6>}
    </div>
  );
}