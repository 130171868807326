import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from '../../FirebaseConfig.js';



export default function ActivityMinMaxKids({activeSchedule, activity, activities, pullDatabase}) {

  console.log('activity', activity);

    //const activityIndex = activities.findIndex((item) => item.activityId === activity.activityId);
    // const minKids = activity.min_kids;
    // const maxKids = activity.max_kids;
    const [minKids, setMinKids] = useState(activity.min_kids !== "" ? activity.min_kids : 0);
    const [maxKids, setMaxKids] = useState(activity.max_kids !== "" ? activity.max_kids  : 0);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        setMinKids(activity.min_kids !== "" ? activity.min_kids : 0);
        setMaxKids(activity.max_kids !== "" ? activity.max_kids  : 0);
    }, [activity]);

    const handleChange = async (e) => {
        // Clear the timeout if it has already been set
        clearTimeout(timer);

        let newValue = e.target.value;
        let targetName = e.target.name;

        if (targetName === "max_kids") {
          setMaxKids(newValue);
        } else if (targetName === "min_kids") {
          setMinKids(newValue);
        }

        const activityDoc = activity.activityId;

        // Set a new timeout that will run the update function after the user has finished typing
        const typingTimeout = setTimeout(async () => {
          const docRef = doc(db, 'schedules', activeSchedule, 'activities', activityDoc);

          try {
            await runTransaction(db, async (transaction) => {
              const doc = await transaction.get(docRef);
              if (!doc.exists()) {
                throw "Document does not exist!";
              }
                const tempDoc = doc.data();
                if (targetName === "max_kids") {
                    tempDoc.max_kids = newValue;
                } else if (targetName === "min_kids") {
                    tempDoc.min_kids = newValue;
                }
                console.log(tempDoc);

                transaction.set(docRef, tempDoc);
            });
            console.log("Transaction successfully committed!");
          } catch (e) {
            console.log("Transaction failed: ", e);
          }

          pullDatabase();
        }, 500);

        setTimer(typingTimeout);
      }


  return (
    <>
        <div className="attribute-wrapper min-max-kids">
            <h6 className="attribute-type">Max # of kids:</h6>
            <input type="text" name="max_kids" className="attribute-value" value={maxKids} onChange={(e) => handleChange(e)} />
        </div>
        <div className="attribute-wrapper min-max-kids">
            <h6 className="attribute-type">Min # of kids:</h6>
            <input type="text" name="min_kids" className="attribute-value" value={minKids} onChange={(e) => handleChange(e)} />
        </div>
    </>
  )
}