import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';
import { runTransaction, writeBatch, setDoc, doc, collection } from 'firebase/firestore';



export default function SettingsAddCamper({activeSchedule, settings, pullDatabase, activities, campers}) {

    const activityOptions = activities.map((activity) => {
        return {value: activity.activityId, label: activity.name}
    });

    const [activityPreferences, setActivityPreferences] = useState([]);


    const handleChange = async (event) => {
        const selectElement = event.target;
        const selectedOption = selectElement.selectedOptions[0].text;
        if (activityPreferences.length < 13 && !activityPreferences.find(({ activity }) => activity === selectedOption)) {
          const newActivityPreferences = [...activityPreferences, { activity: selectedOption, priority: false }];
          await setActivityPreferences(newActivityPreferences);
        }
      }

      const handleMoveUp = async (index) => {
        const newPreferences = [...activityPreferences];
        if (index > 0) {
          const temp = newPreferences[index];
          newPreferences[index] = newPreferences[index - 1];
          newPreferences[index - 1] = temp;
          await setActivityPreferences(newPreferences);
        }
      }

      const handleMoveDown = async (index) => {
        const newPreferences = [...activityPreferences];
        if (index < newPreferences.length - 1) {
          const temp = newPreferences[index];
          newPreferences[index] = newPreferences[index + 1];
          newPreferences[index + 1] = temp;
          await setActivityPreferences(newPreferences);
        }
      }

      const handleRemove = async (index) => {
        const newPreferences = [...activityPreferences];
        newPreferences.splice(index, 1);
        await setActivityPreferences(newPreferences);
      }



    const campersIndex = settings.findIndex((item) => item.settingId === 'campers');
    const [cabinGroups, setCabinGroups] = useState(settings[campersIndex].cabin_data || {
        unused: {
          name: "Unused",
          cabins: [
            { value: "cabin_1", label: "Cabin 1" },
            { value: "cabin_2", label: "Cabin 2" },
            { value: "cabin_3", label: "Cabin 3" },
            { value: "cabin_4", label: "Cabin 4" },
            { value: "cabin_5", label: "Cabin 5" },
            { value: "cabin_6", label: "Cabin 6" },
            { value: "cabin_7", label: "Cabin 7" },
            { value: "cabin_8", label: "Cabin 8" },
            { value: "cabin_9", label: "Cabin 9" },
            { value: "cabin_10", label: "Cabin 10" },
          ],
        },
        dudes: {
          name: "Dudes",
          cabins: [],
        },
        pioneers: {
          name: "Pioneers",
          cabins: [],
        },
        rangers: {
          name: "Rangers",
          cabins: [],
        },
    });

    function resetAllValues() {
        document.getElementById('firstName').value = '';
        document.getElementById('lastName').value = '';
        document.getElementById('cabin').value = '';
        document.getElementById('campingSkills').value = '';
        document.getElementById('swimTest').value = '';
        document.getElementById('tennisAcademy').value = '';
        setActivityPreferences([]);
        document.getElementById('notes').value = '';
    }


    async function addCamper() {
        let firstName = document.getElementById('firstName').value;
        let lastName = document.getElementById('lastName').value;
        //get label from cabin value
        let cabinLabel = document.getElementById('cabin').options[document.getElementById('cabin').selectedIndex].text;
        let campingSkills = document.getElementById('campingSkills').value;
        let swimTest = document.getElementById('swimTest').value;
        let tennisAcademy = document.getElementById('tennisAcademy').value;
        let waterskiingTest = document.getElementById('tennisAcademy').value;
        let notes = document.getElementById('notes').value;
        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "cabin": cabinLabel,
            "camping_skills_data": {
                "camping_skills_placeholder": campingSkills,
                "camping_skills_options": [
                    "Yes",
                    "No"
                ],
                "camping_skills": campingSkills
            },
            "swim_test_data": {
                "swim_test_placeholder": swimTest,
                "swim_test_options": [
                    "Yes",
                    "No"
                ],
                "swim_test": swimTest
            },
            "waterskiing_test_data": {
                "waterskiing_test_placeholder": waterskiingTest,
                "waterskiing_test_options": [
                    "Yes",
                    "No"
                ],
                "waterskiing_test": waterskiingTest
            },
            "tennis_academy_data": {
                "tennis_academy_placeholder": tennisAcademy,
                "tennis_academy_options": [
                    "Yes",
                    "No"
                ],
                "tennis_academy": tennisAcademy
            },
            "activity_preferences": activityPreferences,
            "notes": notes
        }
        console.log(data);
        console.log(campers.length)
        let newDocName = "camper_" + (campers.length + 1);
        const newDocNameFormatted = newDocName.replace(/\s+/g, '_').toLowerCase();
        const newDocRef = doc(db, 'schedules', activeSchedule, 'campers', newDocNameFormatted);
        console.log(newDocName);
        setDoc(newDocRef, data);
        pullDatabase();
        resetAllValues();
    }


    async function addRandomCamper() {
        let listOfRandomFirstNames = ["John", "Jane", "Bob", "Sally", "Joe", "Mary", "Tom", "Sue", "Bill", "Jill", "Jim", "Sara", "Mike", "Sarah", "Steve", "Samantha", "Chris", "Jill", "Chris"];
        let listOfRandomLastNames = ["Smith", "Johnson", "Williams", "Jones", "Brown", "Davis", "Miller", "Wilson", "Moore", "Taylor", "Anderson", "Thomas", "Jackson", "White", "Harris", "Martin", "Thompson", "Garcia", "Martinez", "Robinson", "Clark", "Rodriguez", "Lewis", "Lee", "Walker", "Hall", "Allen", "Young", "Hernandez", "King", "Wright", "Lopez", "Hill", "Scott", "Green", "Adams", "Baker", "Gonzalez", "Nelson", "Carter", "Mitchell", "Perez", "Roberts", "Turner", "Phillips", "Campbell", "Parker", "Evans", "Edwards", "Collins", "Stewart", "Sanchez", "Morris", "Rogers", "Reed", "Cook", "Morgan", "Bell", "Murphy", "Bailey", "Rivera", "Cooper", "Richardson", "Cox", "Howard", "Ward", "Torres", "Peterson", "Gray", "Ramirez", "James", "Watson", "Brooks", "Kelly", "Sanders", "Price", "Bennett", "Wood", "Barnes", "Ross", "Henderson", "Coleman", "Jenkins", "Perry", "Powell", "Long", "Patterson", "Hughes", "Flores", "Washington", "Butler", "Simmons", "Foster", "Gonzales", "Bryant", "Alexander", "Russell", "Griffin", "Diaz", "Hayes"];

        let randomFirstName = listOfRandomFirstNames[Math.floor(Math.random() * listOfRandomFirstNames.length)];
        let randomLastName = listOfRandomLastNames[Math.floor(Math.random() * listOfRandomLastNames.length)];

        //if firstname lastname combo already exists, generate new name
        while (campers.some((camper) => camper.first_name === randomFirstName && camper.last_name === randomLastName)) {
            randomFirstName = listOfRandomFirstNames[Math.floor(Math.random() * listOfRandomFirstNames.length)];
            randomLastName = listOfRandomLastNames[Math.floor(Math.random() * listOfRandomLastNames.length)];
        }

        let randomCabin = document.getElementById('cabin').options[Math.floor(Math.random() * document.getElementById('cabin').options.length)].label;

        //find age group based on cabinGroups
        let ageGroup = "";
        for (const group of Object.values(cabinGroups)) {
          if (group.cabins.some((cabin) => cabin.label === randomCabin)) {
            ageGroup = group.name;
            break;
          }
        }

        let randomCampSkills = document.getElementById('campingSkills').options[Math.floor(Math.random() * document.getElementById('campingSkills').options.length)].value;
        let randomSwimTest = document.getElementById('swimTest').options[Math.floor(Math.random() * document.getElementById('swimTest').options.length)].value;
        let waterskiingTest = document.getElementById('waterskiingTest').options[Math.floor(Math.random() * document.getElementById('waterskiingTest').options.length)].value;
        let randomTennisAcademy = document.getElementById('tennisAcademy').options[Math.floor(Math.random() * document.getElementById('tennisAcademy').options.length)].value;
        let randomNotes = "This camper was added randomly";

        //pick 13 random activities
        const generateRandomActivityPreferences = () => {
            const randomActivityPreferences = [];
            const availableActivityOptions = [...activityOptions]; // Create a copy of activityOptions
            while (randomActivityPreferences.length < 13) {
                const randomIndex = Math.floor(Math.random() * availableActivityOptions.length);
                const randomActivity = availableActivityOptions[randomIndex];
                // Remove the chosen activity from the available options
                availableActivityOptions.splice(randomIndex, 1);
                randomActivityPreferences.push({ activity: randomActivity.label, priority: false });
            }
            return randomActivityPreferences;
        };
        let randomActivityPreferences = generateRandomActivityPreferences();


        let data = {
            "first_name": randomFirstName,
            "last_name": randomLastName,
            "cabin": randomCabin,
            "age_group": ageGroup,
            "camping_skills_data": {
                "camping_skills_placeholder": randomCampSkills,
                "camping_skills_options": [
                    "Yes",
                    "No"
                ],
                "camping_skills": randomCampSkills,
            },
            "swim_test_data": {
                "swim_test_placeholder": randomSwimTest,
                "swim_test_options": [
                    "Yes",
                    "No"
                ],
                "swim_test": randomSwimTest
            },
            "waterskiing_test_data": {
                "waterskiing_test_placeholder": waterskiingTest,
                "waterskiing_test_options": [
                    "Yes",
                    "No"
                ],
                "waterskiing_test": waterskiingTest
            },
            "tennis_academy_data": {
                "tennis_academy_placeholder": randomTennisAcademy,
                "tennis_academy_options": [
                    "Yes",
                    "No"
                ],
                "tennis_academy": randomTennisAcademy
            },
            "activity_preferences": randomActivityPreferences,
            "notes": randomNotes
        }
        console.log(data);
        console.log(campers.length)
        let newDocName = "camper_" + (campers.length + Math.random()).toString(36).substring(2, 15);
        const newDocNameFormatted = newDocName.replace(/\s+/g, '_').toLowerCase();
        const newDocRef = doc(db, 'schedules', activeSchedule, 'campers', newDocNameFormatted);
        console.log(newDocName);
        setDoc(newDocRef, data);
        pullDatabase();
        resetAllValues();
    }

  return (
    <div className="settings-block add">
        <div className='settings-block-wrapper'>
            <input id="firstName" type="text" placeholder="First Name" />
            <input id="lastName" type="text" placeholder="Last Name" />
        </div>
        <div className='settings-block-wrapper'>
            <label>Cabin</label>
            <select id="cabin" >
            {Object.values(cabinGroups)
                .flatMap((group) => group.cabins)
                .sort((a, b) => {
                const numA = parseInt(a.label.match(/\d+/)[0]);
                const numB = parseInt(b.label.match(/\d+/)[0]);
                return numA - numB;
                })
                .map((cab, index) => (
                <option key={cab.value} value={cab.value}>
                    {cab.label}
                </option>
                ))}
            </select>
        </div>
        <div className='settings-block-wrapper'>
            <label>Camping Skills</label>
            <select id="campingSkills" >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </div>
        <div className='settings-block-wrapper'>
            <label>Swim Test</label>
            <select id="swimTest" >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </div>
        <div className='settings-block-wrapper'>
            <label>Waterskiing Test</label>
            <select id="waterskiingTest" >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </div>
        <div className='settings-block-wrapper'>
            <label>Tennis Academy</label>
            <select id="tennisAcademy" >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </div>
        <form>
            <label>
            Select an activity preference:
            </label>
                <select onChange={handleChange}>
                    <option value="">Select an activity</option>
                    {activityOptions.map((activity, index) => (
                        <option key={index} value={activity.value} name={activity.label}>{activity.label}</option>
                    ))}
                </select>
        </form>
        <ul>
            {activityPreferences.map((preference, index) => (
                <li key={index}>
                <label>
                    Preference {index + 1}: {preference.activity}
                </label>
                <button onClick={() => handleMoveUp(index)}>Move Up</button>
                <button onClick={() => handleMoveDown(index)}>Move Down</button>
                <button onClick={() => handleRemove(index)}>Remove</button>
                <input
                    type="checkbox"
                    checked={preference.priority}
                    onChange={(event) => {
                    const checked = event.target.checked;
                    const newActivityPreferences = [...activityPreferences];
                    newActivityPreferences[index].priority = checked;
                    setActivityPreferences(newActivityPreferences);
                    }}
                />
                <label>
                    Priority?
                </label>
                </li>
            ))}
        </ul>
        <div className='settings-block-wrapper'>
            <textarea id="notes" placeholder="Notes..." ></textarea>
        </div>
        <button onClick={addCamper} >Add Camper</button>
        <button onClick={addRandomCamper} >Magically Add Random Camper</button>
    </div>
  )
}