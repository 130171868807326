import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from '../../FirebaseConfig.js';
import { update } from 'lodash';



export default function ActivityLocation({activeSchedule, activity, pullDatabase}) {

    //const activityIndex = activities.findIndex((item) => item.activityId === activity.activityId);

    const [isShareable, setIsShareable] = useState(activity.locationIsShareable);
    //activity.is_two_periods
    const [isTwoPeriods, setIsTwoPeriods] = useState(false);
    const [location, setLocation] = useState(activity.location || "");
    const [timer, setTimer] = useState(null);
    const [runActivityFirst, setRunActivityFirst] = useState(false);
    const [onlyRequiredCabins, setOnlyRequiredCabins] = useState(false);

    useEffect(() => {
        setIsShareable(activity.locationIsShareable || false);
        setIsTwoPeriods(activity.is_two_periods || false);
        setLocation(activity.location || "");
        setRunActivityFirst(activity.run_activity_first || false);
        setOnlyRequiredCabins(activity.only_required_cabins || false);
    }, [activity]);

    const handleChange = async (e) => {
        //e,preventDefault();

        clearTimeout(timer);
        let newLocation = e.target.value;

        setLocation(newLocation);

        const dbUpdateTimer = setTimeout(async () => {

            const activityDoc = activity.activityId;
            const docRef = doc(db, 'schedules', activeSchedule, 'activities', activityDoc);

            try {
                await runTransaction(db, async (transaction) => {
                    const doc = await transaction.get(docRef);
                    if (!doc.exists()) {
                        throw "Document does not exist!";
                    }
                    const tempDoc = doc.data();
                    tempDoc.location = newLocation;

                    transaction.set(docRef, tempDoc);

                });
                console.log("Transaction successfully committed!");
            } catch (e) {
                console.log("Transaction failed: ", e);
            }

            pullDatabase();

        }, 1000);

        setTimer(dbUpdateTimer);
    }

    const handleShareable = async (e) => {

        const checked = e.target.checked;
        setIsShareable();
        const activityDoc = activity.activityId;
        const docRef = doc(db, 'schedules', activeSchedule, 'activities', activityDoc);

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                tempDoc.locationIsShareable = checked;
                console.log(tempDoc.locationIsShareable);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

    const handleIsTwoPeriods = async (e) => {

        const checked = e.target.checked;
        setIsTwoPeriods();
        const activityDoc = activity.activityId;
        const docRef = doc(db, 'schedules', activeSchedule, 'activities', activityDoc);

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                tempDoc.is_two_periods = checked;
                console.log(tempDoc.is_two_periods);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

    const handleRunActivityFirst = async (e) => {

        const checked = e.target.checked;
        setRunActivityFirst();
        const activityDoc = activity.activityId;
        const docRef = doc(db, 'schedules', activeSchedule, 'activities', activityDoc);

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                tempDoc.run_activity_first = checked;
                console.log(tempDoc.run_activity_first);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

    const handleOnlyRequiredCabins = async (e) => {

        const checked = e.target.checked;
        setRunActivityFirst();
        const activityDoc = activity.activityId;
        const docRef = doc(db, 'schedules', activeSchedule, 'activities', activityDoc);

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                tempDoc.only_required_cabins = checked;
                console.log(tempDoc.only_required_cabins);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

  return (
    <div className="detail-info-wrapper">
        <div className="attribute-wrapper location">
            <h6 className="attribute-type">Location:</h6>
            <input
                className="attribute-value"
                type="text"
                value={location && location}
                onChange={handleChange}
            />
        </div>
        <div className="attribute-wrapper shareable">
            <input
                className="attribute-value"
                type="checkbox"
                checked={isShareable}
                onChange={handleShareable}
            />
            <label>Shareable</label>
        </div>
        <div className="attribute-wrapper shareable">
            <input
                className="attribute-value"
                type="checkbox"
                checked={isTwoPeriods}
                onChange={handleIsTwoPeriods}
            />
            <label>Activity Takes Two Periods</label>
        </div>
        <div className="attribute-wrapper shareable">
            <input
                className="attribute-value"
                type="checkbox"
                checked={runActivityFirst}
                onChange={handleRunActivityFirst}
            />
            <label>Schedule Counselor Roles First</label>
        </div>
        <div className="attribute-wrapper shareable">
            <input
                className="attribute-value"
                type="checkbox"
                checked={onlyRequiredCabins}
                onChange={handleOnlyRequiredCabins}
            />
            <label>Only Schedule Required Activities</label>
        </div>
    </div>
  )
}