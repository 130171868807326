import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
//import { useAuthContext } from './hooks/useAuthContext';

//firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { query, where, getDocs, getDoc, doc, collection, runTransaction } from 'firebase/firestore';
import { useState, useEffect, useRef } from 'react';
import { db } from './FirebaseConfig.js';

//Styles
import './App.css';
import './assets/style.css'

//Compnents
import Navbar from './components/Navbar.js';
import Sidebar from './components/Sidebar.js';
import Notes from './components/Notes';
import Search from './components/Search';
import Login from './components/Login'

//Pages
import Campers from './pages/Campers'
import Counselors from './pages/Counselors'
import Activities from './pages/Activities'
import Reports from './pages/Reports'
import Settings from './pages/Settings'
import Scheduler from './pages/Scheduler';
import Test from './pages/Test';
import Periods from './components/Periods';
import PrintSchedule from './pages/PrintSchedule.js';

function App() {
  //const {user, authIsReady} = useAuthContext();
  const [user, setUser] = useState(null);
  const [activities, setActivities] = useState(null);
  const [settings, setSettings] = useState(null);
  const [counselors, setCounselors] = useState(null);
  const [campers, setCampers] = useState(null);
  const [schedules, setSchedules] = useState(null);
  const [singleSchedule, setSingleSchedule] = useState(null);
  const activitiesRef = useRef(activities).current;
  const settingsRef = useRef(settings).current;
  const [showSidebar, setShowSidebar] = useState(true);
  const [printScheduleLogic, setPrintScheduleLogic] = useState({
    showAll: true,
    onlyCampers: true,
    onlyCounselors: true,
    individualCamperId: '',
    individualCounselorId: '',
    counselorsByDay: false,
    activityRoster: false
  });
  const currentPath = window.location.pathname;
  const className = currentPath === '/print-schedule' ? 'App print-schedule' : 'App';
  const docRef = doc(db, 'settings', 'general');
  const [activeSchedule, setActiveSchedule] = useState(null);


  useEffect(() => {
    console.log('printScheduleLogic', printScheduleLogic);
  }, [printScheduleLogic]);

  async function setSchedule() {
    let schedule;
    try {
      const doc = await getDoc(docRef);
      if (!doc.exists()) {
        throw "Document does not exist!";
      }
      schedule = doc.data().active_schedule_id.trim(); //needs to be changed to active_schedule_id
      console.log(schedule);
    } catch (e) {
      console.log("Transaction failed: ", e);
    }
    return schedule;
  }



  const pullDatabase = async function(schedule) {

    const campersCollection = query(collection(db,'schedules',  activeSchedule, 'campers'));
    const campersDocuments = await getDocs(campersCollection);
    const campersObjects = [];
    campersDocuments.forEach((doc) => {
      const camperData = doc.data();
      const camperId = doc.id;
      camperData.camperId = camperId;  // add the activityId to activityData
      campersObjects.push(camperData);
    });

    setCampers(campersObjects);

    const counselorsCollection = query(collection(db,'schedules', activeSchedule, 'counselors'));
    const counselorsDocuments = await getDocs(counselorsCollection);
    const counselorsObjects = [];
    counselorsDocuments.forEach((doc) => {
      const counselorData = doc.data();
      const counselorId = doc.id;
      counselorData.counselorId = counselorId;  // add the activityId to activityData
      counselorsObjects.push(counselorData);
    });

    setCounselors(counselorsObjects);

    const activitiesCollection = query(collection(db,'schedules', activeSchedule, 'activities'));
    const activitiesDocuments = await getDocs(activitiesCollection);
    const activitiesObjects = [];
    activitiesDocuments.forEach((doc) => {
      const activityData = doc.data();
      const activityId = doc.id;
      activityData.activityId = activityId;  // add the activityId to activityData
      activitiesObjects.push(activityData);
    });

    setActivities(activitiesObjects);

    const scheduleCollection = query(collection(db,'schedules', activeSchedule, 'schedule'));
    const scheduleDocuments = await getDocs(scheduleCollection);
    let tempScheduleObject = {};

    scheduleDocuments.forEach((doc) => {
      const scheduleData = doc.data();
      const scheduleId = doc.id;

      tempScheduleObject[scheduleId] = scheduleData;
    });

    setSingleSchedule(tempScheduleObject);

    const settingsCollection = query(collection(db,'schedules', activeSchedule, 'settings'));
    const settingsDocuments = await getDocs(settingsCollection);
    const settingsObjects = [];
    settingsDocuments.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const settingsData = doc.data();
      const settingsDataId = doc.id;
      settingsData.settingId = settingsDataId;
      settingsObjects.push(settingsData);
    });
    setSettings(settingsObjects);

    const schedulesCollectionRef = collection(db, 'schedules');
    const schedulesSnapshot = await getDocs(schedulesCollectionRef);

    const schedulesObjects = [];
    for (let document of schedulesSnapshot.docs) {
      const scheduleData = document.data();
      const scheduleId = document.id;

      // Now, for each schedule, get the 'general' document from its 'settings' subcollection
      const generalDocRef = doc(db, 'schedules', scheduleId, 'settings', 'general');
      const generalDocSnapshot = await getDoc(generalDocRef);

      if (generalDocSnapshot.exists()) {
        const generalData = generalDocSnapshot.data();

        const docStart = generalData.start;
        const docEnd = generalData.end;

        // Convert the start and end dates to a string that is the month and day
        const start = new Date(docStart);
        const end = new Date(docEnd);
        const startYear = start.getFullYear();
        const startMonth = start.getMonth() + 1;
        const startDay = start.getDate();
        const endMonth = end.getMonth() + 1;
        const endDay = end.getDate();
        const endYear = end.getFullYear();
        const startString = `${startMonth}/${startDay}/${startYear}`;
        const endString = `${endMonth}/${endDay}/${endYear}`;
        const dateRange = `${startString} - ${endString}`;

        scheduleData.value = scheduleId;
        scheduleData.label = scheduleId.replace('_', ' ');
        scheduleData.label = dateRange;
        scheduleData.selected = scheduleId === activeSchedule;
        scheduleData.startDate = start
        scheduleData.endDate = end;
        scheduleData.dateRange = dateRange;

        schedulesObjects.push(scheduleData);
      }
    }
    setSchedules(schedulesObjects);

  }

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        const schedule = await setSchedule();
        if (schedule) {
          setActiveSchedule(schedule);
        }

        //setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (activeSchedule !== null) {
      pullDatabase(activeSchedule);
    }
}, [activeSchedule]);

useEffect(() => {
  if(singleSchedule !== null) {
    console.log(singleSchedule);
  }
}, [singleSchedule]);

  return (
      <div className={className}>
        {user ? (
            <BrowserRouter>
              {activeSchedule && showSidebar && <Sidebar schedules={schedules} activeSchedule={activeSchedule} setActiveSchedule={setActiveSchedule}/>}
              <Routes>
                <Route
                  path='/'
                  element={<Navigate to='/campers' />}
                />
                <Route
                  path='/campers'
                  element={activeSchedule ? campers && settings && singleSchedule && <Campers activeSchedule={activeSchedule} activities={activities} pullDatabase={pullDatabase} campers={campers} settings={settings} setActivities={setActivities} setSettings={setSettings} singleSchedule={singleSchedule} printScheduleLogic={printScheduleLogic} setPrintScheduleLogic={setPrintScheduleLogic} setShowSidebar={setShowSidebar} /> : <div>Loading...</div>}
                />
                <Route
                  path='/scheduler'
                  element={activeSchedule && singleSchedule && schedules && <Scheduler settings={settings} activities={activities} pullDatabase={pullDatabase} counselors={counselors} campers={campers} singleSchedule={singleSchedule} activeSchedule={activeSchedule} schedules={schedules} setShowSidebar={setShowSidebar} />}
                />
                <Route
                  path='/counselors'
                  element={activeSchedule && activities && settings && singleSchedule && <Counselors activeSchedule={activeSchedule} pullDatabase={pullDatabase} activities={activities} settings={settings} setActivities={setActivities} setSettings={setSettings} counselors={counselors} singleSchedule={singleSchedule} printScheduleLogic={printScheduleLogic} setPrintScheduleLogic={setPrintScheduleLogic} setShowSidebar={setShowSidebar} />}
                />
                <Route
                  path='/activities'
                  element={activeSchedule && activities && settings && <Activities activeSchedule={activeSchedule} pullDatabase={pullDatabase} activities={activities} settings={settings} setActivities={setActivities} setSettings={setSettings} setShowSidebar={setShowSidebar} />}
                />
                <Route
                  path='/reports'
                  element={singleSchedule && settings && activities && counselors && campers && <Reports activities={activities} counselors={counselors} campers={campers} settings={settings} singleSchedule={singleSchedule} printScheduleLogic={printScheduleLogic} setPrintScheduleLogic={setPrintScheduleLogic} setShowSidebar={setShowSidebar} />}
                />
                <Route
                  path='/settings'
                  element={activeSchedule && settings && <Settings activeSchedule={activeSchedule} settings={settings} activities={activities} pullDatabase={pullDatabase} counselors={counselors} campers={campers} setShowSidebar={setShowSidebar} />}
                />
                <Route
                  path='/test'
                  element={activeSchedule && activities && <Periods activeSchedule={activeSchedule} activities={activities} />}
                />
                <Route
                  path='/print-schedule'
                  element={singleSchedule && printScheduleLogic && campers && counselors && activities && <PrintSchedule singleSchedule={singleSchedule} campers={campers} printScheduleLogic={printScheduleLogic} counselors={counselors} activities={activities} setShowSidebar={setShowSidebar} />}
                />
              </Routes>
            </BrowserRouter> ) : (
              <Login />
            )
        }
      </div>
  );
}

export default App;