import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import * as Papa from 'papaparse';

//Styles
import '../assets/style.css'

//Components
import Notes from '../components/Notes'

export default function Reports({campers, counselors, activities, singleSchedule, printScheduleLogic, setPrintScheduleLogic, setShowSidebar, settings}) {

  console.log(singleSchedule)

  setShowSidebar(true);
  const navigate = useNavigate();

  const campersDataIndex = settings.findIndex((item) => item?.settingId === 'campers');
  const cabinsInSettings = settings[campersDataIndex].cabin_data;
  const [activeCabins, setActiveCabins] = useState([]);
  const [cabins, setCabins] = useState([]);

  const periodOrder = ["K1", "K2", "K3", "K4", "K5", "G1", "G2", "G3", "G4", "G5"];


  useEffect(() => {
    const tempCabins = [];
    Object.values(cabinsInSettings).forEach((cabin) => {
      (cabin.cabins).forEach((c) => {
        tempCabins.push(c.label);
      });
    });

    tempCabins.sort((a, b) => {
      const numA = parseInt(a.match(/\d+/)[0]);
      const numB = parseInt(b.match(/\d+/)[0]);

      if (numA < numB) {
          return -1;
      } else if (numA > numB) {
          return 1;
      } else {
          return 0;
      }
  });

    setCabins(tempCabins);
  }, [cabinsInSettings]);

  const handleCabinClick = async (e, cabin) => {
    let tempActiveCabins;

    if (activeCabins.includes(cabin)) {
      tempActiveCabins = activeCabins.filter((item) => item !== cabin);
    } else {
      tempActiveCabins = [...activeCabins, cabin];
    }

    setActiveCabins(tempActiveCabins);
  }


  const [selectedScheduleType, setSelectedScheduleType] = useState('');

  const handlePrintScheduleClick = () => {

    if (selectedScheduleType === 'campers') {
      setPrintScheduleLogic(
        {...printScheduleLogic,
          onlyCampers: true,
          onlyCounselors: false,
          showAll: false,
          individualCamperId: '',
          individualCounselorId: '',
          counselorsByDay: false,
          activeCabins: activeCabins,
          activityRoster: false
        }
      )
    }
    if (selectedScheduleType === 'counselors') {
      setPrintScheduleLogic(
        {...printScheduleLogic,
          onlyCampers: false,
          onlyCounselors: true,
          showAll: false,
          individualCamperId: '',
          individualCounselorId: '',
          counselorsByDay: false,
          activeCabins: activeCabins,
          activityRoster: false
        }
      )
    }
    if (selectedScheduleType === 'both') {
      setPrintScheduleLogic(
        {...printScheduleLogic,
          onlyCounselors: true,
          onlyCampers: true,
          showAll: true,
          individualCamperId: '',
          individualCounselorId: '',
          counselorsByDay: false,
          activeCabins: activeCabins,
          activityRoster: false
        }
      )
    }
    if (selectedScheduleType === 'counselorsByDay') {
      setPrintScheduleLogic(
        {...printScheduleLogic,
          onlyCounselors: true,
          onlyCampers: false,
          showAll: false,
          individualCamperId: '',
          individualCounselorId: '',
          counselorsByDay: true,
          activeCabins: activeCabins,
          activityRoster: false
        }
      )
    }
    if (selectedScheduleType === 'activityRoster') {
      setPrintScheduleLogic(
        {...printScheduleLogic,
          onlyCounselors: false,
          onlyCampers: false,
          showAll: false,
          individualCamperId: '',
          individualCounselorId: '',
          counselorsByDay: false,
          activeCabins: activeCabins,
          activityRoster: true
        }
      )
    }

    navigate('/print-schedule');
  }

  const handleOptionChange = (e) => {
    setSelectedScheduleType(e.target.value);
  }


  // Function to combine activityId and role
  const combineActivityAndRoleForCounselor = (data) => {
    if (data['activityId']) {
        return `${data['activityId']} - ${data['role']}`;
    } else if (data['period_off']) {
        return `period_off - ${data['period_off']}`;
    } else if (data['not_available']) {
        return `not_available - ${data['not_available']}`;
    }
  };

  // Function to format data for CSV
  const formatDataForCounselorCSV = (data) => {
    // Define the order of the days of the week and periods
    const daysOrder = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    const periodsOrder = ["K1", "K2", "K3", "K4", "K5", "G1", "G2", "G3", "G4", "G5"];
    
    return data.map(item => {
        let counselor = counselors.find(c => c.counselorId === item['counselorId']);
        let formattedItem = { 
          'counselor_id': item['counselorId'],
          'first_name': counselor ? counselor.first_name : '',
          'last_name': counselor ? counselor.last_name : ''
        };

        // Sort the days and periods
        daysOrder.forEach(day => {
          periodsOrder.forEach(period => {
            let columnName = `${day} (${period})`;
            if (item['schedule'][day] && item['schedule'][day][period]) {
              formattedItem[columnName] = combineActivityAndRoleForCounselor(item['schedule'][day][period]);
            }
          });
        });

        return formattedItem;
    });
  };

  const downloadCounselorData = (fileName, counselors) => {
      const unformattedData = singleSchedule.counselor_list_data.counselorListData;
      console.log(unformattedData);
      const data = formatDataForCounselorCSV(unformattedData, counselors);
      console.log("Formatted data: ", data);
      if (data.length > 0) {
        const csvData = Papa.unparse(data);
        const blob = new Blob([csvData], {type: "text/csv;charset=utf-8"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
  };


  function formatDataForCamperCSV() {
    const formattedData = [];
    const scheduleByActivity = singleSchedule.schedule_by_activity;

    for (let activityId in scheduleByActivity) {
        const activitySchedule = scheduleByActivity[activityId];
        const activityName = activities.find(a => a.activityId === activityId).name;
        for (let day in activitySchedule) {
            const daySchedule = activitySchedule[day];

            for (let period in daySchedule) {
                const periodData = daySchedule[period];

                periodData.campers.forEach(camperId => {
                    const camper = campers.find(c => c.camperId === camperId);

                    if (!camper) {
                        console.warn(`Could not find camper with ID ${camperId}`);
                        return;
                    }

                    let row = formattedData.find(r => r.camperId === camperId);
                    if (!row) {
                        row = {
                            "camperId": camperId,
                            "firstName": camper.first_name,
                            "lastName": camper.last_name
                        };
                        periodOrder.forEach(period => { row[period] = ""; }); // Initialize all periods with empty string
                        formattedData.push(row);
                    }

                    // Assign activity to the relevant period for the camper
                    if (periodOrder.includes(period)) {
                        row[period] = activityName;
                    }
                });
            }
        }
    }

    return formattedData;
}

function generateCamperCSV() {
    const data = formatDataForCamperCSV();
    const csv = Papa.unparse(data);

    // Download CSV file
    const blob = new Blob([csv], {type: "text/csv"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'campers_schedule.csv';
    link.click();
}


  const downloadCamperData = (fileName) => {
    const data = singleSchedule.camper_data;
    if (Object.keys(data).length > 0) {
      const csvData = Papa.parse(data, {header: true, delimiter: ","}).data;
      const blob = new Blob([csvData], {type: "text/csv;charset=utf-8"});
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
    
  return (
    <div className="reports" id="reports">
      <h4>Select Schedule Type</h4>
      <div className='schedule-types'>
        <label>
          <input
            type="radio"
            value="campers"
            checked={selectedScheduleType === "campers"}
            onChange={handleOptionChange}
          />
          Print Camper Schedule
        </label>
        <label>
          <input
            type="radio"
            value="counselors"
            checked={selectedScheduleType === "counselors"}
            onChange={handleOptionChange}
          />
          Print Counselor Schedule
        </label>
        <label>
          <input
            type="radio"
            value="counselorsByDay"
            checked={selectedScheduleType === "counselorsByDay"}
            onChange={handleOptionChange}
          />
          Print Counselors By Day
        </label>
        <label>
          <input
            type="radio"
            value="both"
            checked={selectedScheduleType === "both"}
            onChange={handleOptionChange}
          />
          Print Both Schedules
        </label>
        <label>
          <input
            type="radio"
            value="activityRoster"
            checked={selectedScheduleType === "activityRoster"}
            onChange={handleOptionChange}
          />
          Print Activity Roster
        </label>
      </div>

      {selectedScheduleType === "campers" && (
        <>
          <h5 className='cabin-filter-title'>Filter By Cabin</h5>
          <div className='cabin-wrapper'>
            {cabins &&
              cabins.map((cabin) => (
                <label key={cabin}>
                  <input
                    type="checkbox"
                    value={cabin}
                    checked={activeCabins.includes(cabin)}
                    onChange={(e) => handleCabinClick(e, cabin)}
                  />
                  {cabin}
                </label>
              ))}
          </div>
        </>
      )}

      <button className="btn" onClick={handlePrintScheduleClick}>
        <h6>Print Selected Schedule</h6>
      </button>

      <button className="btn" onClick={generateCamperCSV}>
        <h6>Download Camper Data</h6>
      </button>

      <button className="btn" onClick={() => downloadCounselorData("counselor_schedule.csv")}>
        <h6>Download Counselor Data</h6>
      </button>



    </div>
  );
}
