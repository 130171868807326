import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';
import { runTransaction, writeBatch, setDoc, doc, collection } from 'firebase/firestore';

export default function CounselorSearchInfoCard({activeSchedule, counselor, index, setCounselorIndex}) {
  return (
    <div className='search-card' 
      onClick={() => {
        setCounselorIndex(index);
        console.log('test');
      }}
    >
        <div className="name-info-wrapper">
            <div className="name-wrapper">
                <h6 className="first-name">{counselor.first_name}</h6>
                <h6 className="last-name">{counselor.last_name}</h6>
            </div>
        </div>
        <p className="notes">{counselor.notes}</p>
    </div>
  )
}