import React, { useState, useEffect, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';

function ActivityPreferencesList({activeSchedule, camper, settings, activities, pullDatabase}) {


      //create array map of name & values where name is activity.name and value is activity.activity_id from activities
      const activityOptions = activities.map((activity) => {
          return {value: activity.activityId, label: activity.name}
      });

      const [activityPreferences, setActivityPreferences] = useState(camper.activity_preferences || []);


      async function setDatabase(newActivityPreferences) {
          const camperDoc = camper.camperId;
          const docRef = doc(db, 'schedules', activeSchedule, 'campers', camperDoc);   
            try {
              await runTransaction(db, async (transaction) => {
                  const doc = await transaction.get(docRef);  
                  if (!doc.exists()) {
                      throw "Document does not exist!";
                  }
                  let tempDoc = doc.data();
                  tempDoc.activity_preferences = newActivityPreferences;

                  console.log({tempDoc});

                  transaction.set(docRef, tempDoc);

              });
              console.log("Transaction successfully committed!");
          } catch (e) {
              console.log("Transaction failed: ", e);
          }

          pullDatabase();

      }


      useEffect(() => {
          setActivityPreferences(camper.activity_preferences || []);
      }, [camper]);

      // useEffect(() => {
      //   setDatabase();
      // }, [setDatabase]);

      const handleChange = async (event) => {
        const selectElement = event.target;
        const selectedOption = selectElement.selectedOptions[0].text;
        if (activityPreferences.length < 13 && !activityPreferences.find(({ activity }) => activity === selectedOption)) {
          const newActivityPreferences = [...activityPreferences, { activity: selectedOption, priority: false }];
          await setActivityPreferences(newActivityPreferences);
          setDatabase(newActivityPreferences);
        }
      }
      
      const handleMoveUp = async (index) => {
        const newPreferences = [...activityPreferences];
        if (index > 0) {
          const temp = newPreferences[index];
          newPreferences[index] = newPreferences[index - 1];
          newPreferences[index - 1] = temp;
          await setActivityPreferences(newPreferences);
          setDatabase(newPreferences);
        }
      }
      
      const handleMoveDown = async (index) => {
        const newPreferences = [...activityPreferences];
        if (index < newPreferences.length - 1) {
          const temp = newPreferences[index];
          newPreferences[index] = newPreferences[index + 1];
          newPreferences[index + 1] = temp;
          await setActivityPreferences(newPreferences);
          setDatabase(newPreferences);
        }
      }
      
      const handleRemove = async (index) => {
        const newPreferences = [...activityPreferences];
        newPreferences.splice(index, 1);
        await setActivityPreferences(newPreferences);
        setDatabase(newPreferences);
      }

  return (
    <div>
      <form>
        <label>
          Select an activity preference:
            <select onChange={handleChange}>
                <option value="">Select an activity</option>
                {activityOptions.map((activity, index) => (
                    <option key={index} value={activity.value} name={activity.label}>{activity.label}</option>
                ))}
            </select>   
        </label>
      </form>
      <ul className='activity-preferences'>
      {activityPreferences.map((preference, index) => (
        <li key={index}>
          <label>
            Preference {index + 1}: {preference.activity}
          </label>
          <div className='btn-wrapper'>
          <button onClick={() => handleMoveUp(index)}>Move Up</button>
          <button onClick={() => handleMoveDown(index)}>Move Down</button>
          <button onClick={() => handleRemove(index)}>Remove</button>
          </div>
          <input
            type="checkbox"
            checked={preference.priority}
            onChange={(event) => {
              const checked = event.target.checked;
              const newActivityPreferences = [...activityPreferences];
              newActivityPreferences[index].priority = checked;
              setActivityPreferences(newActivityPreferences);
              setDatabase(newActivityPreferences);
            }}
          />
          <label>
            Priority?
          </label>
        </li>
        
      ))}

      </ul>
    </div>
  );
}

export default ActivityPreferencesList;