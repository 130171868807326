import { NavLink } from 'react-router-dom';
//import { useAuthContext } from '../hooks/useAuthContext';
//import Avatar from './Avatar';

//Styles & Assets
import '../assets/style.css'
import Logo from '../assets/images/camp_logo.svg'
import Settings from '../assets/images/settings.svg'
import Activity from '../assets/images/activity.svg'
import Counselor from '../assets/images/counselor.svg'
import Reports from '../assets/images/reports.svg'
import Camper from '../assets/images/camper.svg'

//Components
import SignOut from './Signout';
import DuplicateSchedule from './scheduler/DuplicateSchedule';

export default function Sidebar({activeSchedule, schedules, setActiveSchedule }) {
    //const { user } = useAuthContext();
  return (
    <div className='sidebar'>
        <div className='sidebar-top'>
            <img className='logo' src={Logo} alt="camp logo" />
            <div className='user-info'>
                <p className='name'>Nick Kasle</p>
                <p className='email'>nick@marimeta.com</p>
                <SignOut />
            </div>
            <DuplicateSchedule activeSchedule={activeSchedule} schedules={schedules} setActiveSchedule={setActiveSchedule}/>
        </div>
        <div className='sidebar-links'>
            <NavLink className="link" to='/campers'>
                <img className='campers' src={Camper} alt="camp logo" />
                <h6>Campers</h6>
            </NavLink>
            <NavLink className="link" to='/counselors'>
                <img className='counselors' src={Counselor} alt="camp logo" />
                <h6>Counselors</h6>
            </NavLink>
            <NavLink className="link" to='/activities'>
                <img className='activities' src={Activity} alt="camp logo" />
                <h6>Activities</h6>
            </NavLink>
            <NavLink className="link" to='/settings'>
                <img className='scheduler' src={Settings} alt="camp logo" />
                <h6>Settings</h6>
            </NavLink>
            <NavLink className="link" to='/reports'>
                <img className='reports' src={Reports} alt="camp logo" />
                <h6>Reports</h6>
            </NavLink>
        </div>
        <div className='btn'>
            <NavLink to='/scheduler'>
                <h6>Scheduler</h6>
            </NavLink>
        </div>
    </div>
  )
}