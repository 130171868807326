import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';

export default function CamperName({activeSchedule, camper, pullDatabase, settings}) {



    const [firstNameValue, setFirstNameValue] = useState(camper.first_name || "");
    const [lastNameValue, setLastNameValue] = useState(camper.last_name || "");
    const [cabin, setCabin] = useState(camper.cabin || "");
    const [timer, setTimer] = useState(null)
    const [ageGroupData, setAgeGroupData] = useState(camper.age_group || {
        age_group: "Ranger",
        age_group_options: ["Ranger", "Dudes", "Pioneers"],
    });
    const allCabins = settings.cabins || {
        cabin_1: "Cabin 1",
        cabin_2: "Cabin 2",
        cabin_3: "Cabin 3",
    }

    const [cabValue, setCabValue] = useState(
      camper.cabin.toLowerCase().replaceAll(" ", "_")
    ); 


    //find index of settings that has settingId of 'campers'
    const campersIndex = settings.findIndex((item) => item.settingId === "campers");
    const [cabinGroups, setCabinGroups] = useState(settings[campersIndex].cabin_data || {
      unused: {
        name: "Unused",
        cabins: [
          { value: "cabin_1", label: "Cabin 1" },
          { value: "cabin_2", label: "Cabin 2" },
          { value: "cabin_3", label: "Cabin 3" },
          { value: "cabin_4", label: "Cabin 4" },
          { value: "cabin_5", label: "Cabin 5" },
          { value: "cabin_6", label: "Cabin 6" },
          { value: "cabin_7", label: "Cabin 7" },
          { value: "cabin_8", label: "Cabin 8" },
          { value: "cabin_9", label: "Cabin 9" },
          { value: "cabin_10", label: "Cabin 10" },
        ],
      },
      dudes: {
        name: "Dudes",
        cabins: [],
      },
      pioneers: {
        name: "Pioneers",
        cabins: [],
      },
      rangers: {
        name: "Rangers",
        cabins: [],
      },
    });

    let ageGroupName = "Invalid";
    for (let group in cabinGroups) {
      let cabins = cabinGroups[group].cabins;
      cabins.forEach((cab) => {
        let cabinLabel = cab.label;
        if (cab.label === cabin) {
          ageGroupName = cabinGroups[group].name;
        }
      });
    }

    console.log(camper.swim_test_data);


  useEffect(() => {
    setFirstNameValue(camper.first_name || "");
    setLastNameValue(camper.last_name || "");
    if (camper && camper.age_group_data) {
        setAgeGroupData(camper.age_group_data);
    }
    setCabin(camper.cabin || "");
    setCabValue(camper.cabin.toLowerCase().replaceAll(" ", "_"));
    
  }, [camper]);

    const handleChange = async (e) => {
      let newName = e.target.value;
      if (e.target.name === "firstName") {
        setFirstNameValue(newName);
      } else if (e.target.name === "lastName") {
        setLastNameValue(newName);
      }

      clearTimeout(timer)

      const newTimer = setTimeout( async () => {
          const camperDoc = camper.camperId;
          const docRef = doc(db, 'schedules', activeSchedule, 'campers', camperDoc);   

        console.log(docRef)

          try {
              await runTransaction(db, async (transaction) => {
                  const doc = await transaction.get(docRef);
                  if (!doc.exists()) {
                      throw "Document does not exist!";
                  }
                  let tempDoc = doc.data();
                  if (e.target.name === "firstName") {
                      tempDoc.first_name = newName;
                  } else if (e.target.name === "lastName") {
                      tempDoc.last_name = newName;
                  }

                  console.log(tempDoc);

                  transaction.set(docRef, tempDoc);

              });
              console.log("Transaction successfully committed!");
          } catch (e) {
              console.log("Transaction failed: ", e);
          }

          pullDatabase();
      }, 1000)

      setTimer(newTimer)
    }

    const handleCabinChange = async (e) => {

        let value = e.target.value;
        let label = e.target.options[e.target.selectedIndex].label;


        const camperDoc = camper.camperId;
        const docRef = doc(db, 'schedules', activeSchedule, 'campers', camperDoc);
        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                let tempDoc = doc.data();
                tempDoc.cabin = label;
                //find age group based on cabin
                let ageGroupName = "Invalid";
                for (let group in cabinGroups) {
                    let cabins = cabinGroups[group].cabins;
                    cabins.forEach((cab) => { 
                        if (cab.label === label) {
                            ageGroupName = cabinGroups[group].name;
                        }
                    });
                }
                tempDoc.age_group = ageGroupName;

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
        setCabValue(value);

    }



  return (
    <>
      <div className="name-info-wrapper ">
        <div className="name-wrapper">
          <input className="name-input" type="text" name='firstName' value={firstNameValue} onChange={handleChange} />
          <input className="name-input" type="text" name='lastName' value={lastNameValue} onChange={handleChange} />
        </div>
      </div>
      <div className='name-info-wrapper vertical'>
        <h6 className="title">Camper</h6>
      </div>
      <div className="location-wrapper">
        <select id="cabin" value={cabValue} onChange={(e) => handleCabinChange(e)}>
          {Object.values(cabinGroups)
            .flatMap((group) => group.cabins)
            .sort((a, b) => {
              const numA = parseInt(a.label.match(/\d+/)[0]);
              const numB = parseInt(b.label.match(/\d+/)[0]);
              return numA - numB;
            })
            .map((cab, index) => (
              <option key={cab.value} value={cab.value}>
                {cab.label}
              </option>
            ))}
        </select>


          <div className="age-group-wrapper">
              <h6 className="skill-name">Age Group:</h6>
              <p>{ageGroupName}</p>
              {/* <select id="age_group" value={ageGroupData.age_group} onChange={(e) => handleAgeGroupChange(e)}>
                  {ageGroupData.age_group_options.map(option => (
                      <option key={option} value={option}>
                      {option}
                      </option>
                  ))}
              </select> */}
          </div>
        </div>
    </>
  )
}
