import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';
import { runTransaction, writeBatch, setDoc, doc, collection } from 'firebase/firestore';



export default function SettingsAddCounselor({activeSchedule, settings, pullDatabase, activities, counselors}) {

    let test = counselors.length
    console.log({test});

    const [selectedPeriods, setSelectedPeriods] = useState({});

    const [checked, setChecked] = useState(false);

    const [cprChecked, setCprChecked] = useState(false);

    const [activitySpecialistChecked, setActivitySpecialistChecked] = useState(false);

    const [activityPreferences, setActivityPreferences] = useState([]);

    const dayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    const [days, setDays] = useState([]);

    const activitiesIndex = settings.findIndex((item) => item.settingId === 'activities');

    console.log(settings[activitiesIndex].days)



    const changePeriod = (e, day, period) => {
        e.stopPropagation();
        const isActive = selectedPeriods[day] && selectedPeriods[day].includes(period);
        if (isActive) {
            setSelectedPeriods({
                ...selectedPeriods,
                [day]: selectedPeriods[day].filter((p) => p !== period),
            });
        } else {
            setSelectedPeriods({
                ...selectedPeriods,
                [day]: [...(selectedPeriods[day] || []), period],
            });
        }
    };


    const handleSelectAll = (e, day, periods) => {
        e.stopPropagation();
        const allPeriodsSelected = periods.every((period) => selectedPeriods[day] && selectedPeriods[day].includes(period));
        if (allPeriodsSelected) {
            setSelectedPeriods({
                ...selectedPeriods,
                [day]: [],
            });
        } else {
            setSelectedPeriods({
                ...selectedPeriods,
                [day]: periods,
            });
        }
    };


    const [counselorSkills, setCounselorSkills] = useState(
        Array.isArray(activities)
          ? Object.fromEntries(
              activities.map((activity) => {
                if (activity && typeof activity.activityId === 'string' && Array.isArray(activity.required_counselor_skills)) {
                  return [
                    activity.activityId,
                    activity.required_counselor_skills.map((skill) => {
                      if (skill && typeof skill.skill === 'string') {
                        return {
                          skill: skill.skill,
                          priority: false,
                          capability: false,
                        };
                      } else {
                        return null;
                      }
                    }).filter(Boolean), // remove null values
                  ];
                } else {
                  return null;
                }
              }).filter(Boolean) // remove null values
            )
          : {}
      );
      

    const handleChange = (e, activityId, skill) => {
        const { name, checked } = e.target;

        setCounselorSkills((prevCounselorSkills) => {
        const activitySkills = prevCounselorSkills[activityId] || [];

        const newSkills = activitySkills.map((s) => {
            if (s.skill === skill) {
            return {
                ...s,
                [name]: checked,
            };
            }
            return s;
        });

        return {
            ...prevCounselorSkills,
            [activityId]: newSkills,
        };
        });
    };



    function resetAllValues() {
        setSelectedPeriods({});
        setChecked(false);
        setDays([]);
        setCounselorSkills(
            Object.fromEntries(
                activities.map((activity) => [
                    activity.activityId,
                    activity.required_counselor_skills.map((skill) => ({
                        skill: skill.skill,
                        priority: false,
                        capability: false,
                    })),
                ])
            )
        );
        document.getElementById('firstName').value = '';
        document.getElementById('lastName').value = '';
        document.getElementById('notes').value = '';
        document.getElementById('admin').value = false;
        document.getElementById('admin').checked = false;
        document.getElementById('cpr').value = false;
        document.getElementById('cpr').checked = false;
        document.getElementById('activitySpecialist').value = false;
        document.getElementById('activitySpecialist').checked = false;
    }

    //use this information to set up the bulk upload for the counselors
    async function addCounselor() {
        let firstName = document.getElementById('firstName').value;
        let lastName = document.getElementById('lastName').value;
        let notes = document.getElementById('notes').value;
        let isAdmin = document.getElementById('admin').value;
        let isCpr = document.getElementById('cpr').value;
        let isActivitySpecialist = document.getElementById('activitySpecialist').value;
        console.log(isAdmin);
        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "is_admin": isAdmin,
            "cpr": isCpr,
            "is_activity_specialist": isActivitySpecialist,
            "notes": notes,
            "days": selectedPeriods,
            "counselor_skills": counselorSkills
        }
        console.log(data);
        //set new doc name to be the next number in the sequence of counselors based on doc id with the highest number
        const largestDocId = (counselors.length == 0) ? '1' : counselors.reduce((prev, current) => (prev > current) ? prev : current).counselorId;
        const newDocName = "counselor_" + (parseInt(largestDocId.split('_')[1]) + 1).toString();
        const newDocNameFormatted = newDocName.replace(/\s+/g, '_').toLowerCase();
        const newDocRef = doc(db, 'schedules', activeSchedule, 'counselors', newDocNameFormatted);
        setDoc(newDocRef, data);
        pullDatabase();
        resetAllValues()
    }

    async function addRandomCounselor() {


        let listOfRandomFirstNames = ["John", "Jane", "Bob", "Sally", "Joe", "Mary", "Tom", "Sue", "Bill", "Jill", "Jim", "Sara", "Mike", "Sarah", "Steve", "Samantha", "Chris", "Jill", "Chris"];
        let listOfRandomLastNames = ["Smith", "Johnson", "Williams", "Jones", "Brown", "Davis", "Miller", "Wilson", "Moore", "Taylor", "Anderson", "Thomas", "Jackson", "White", "Harris", "Martin", "Thompson", "Garcia", "Martinez", "Robinson", "Clark", "Rodriguez", "Lewis", "Lee", "Walker", "Hall", "Allen", "Young", "Hernandez", "King", "Wright", "Lopez", "Hill", "Scott", "Green", "Adams", "Baker", "Gonzalez", "Nelson", "Carter", "Mitchell", "Perez", "Roberts", "Turner", "Phillips", "Campbell", "Parker", "Evans", "Edwards", "Collins", "Stewart", "Sanchez", "Morris", "Rogers", "Reed", "Cook", "Morgan", "Bell", "Murphy", "Bailey", "Rivera", "Cooper", "Richardson", "Cox", "Howard", "Ward", "Torres", "Peterson", "Gray", "Ramirez", "James", "Watson", "Brooks", "Kelly", "Sanders", "Price", "Bennett", "Wood", "Barnes", "Ross", "Henderson", "Coleman", "Jenkins", "Perry", "Powell", "Long", "Patterson", "Hughes", "Flores", "Washington", "Butler", "Simmons", "Foster", "Gonzales", "Bryant", "Alexander", "Russell", "Griffin", "Diaz", "Hayes"];

        let randomFirstName = listOfRandomFirstNames[Math.floor(Math.random() * listOfRandomFirstNames.length)];
        let randomLastName = listOfRandomLastNames[Math.floor(Math.random() * listOfRandomLastNames.length)];
        let randomNotes = "Randomly generated counselor";

        console.log(activities)

        let allCounselorSkills = {};

        if (activities) {
          allCounselorSkills = Object.fromEntries(
            activities.map((activity) => [
              activity.activityId,
              activity.required_counselor_skills.map((skill) => ({
                skill: skill.skill,
                priority: false,
                capability: true,
              })),
            ])
          );
        }

        //priority is always going to be false
        //Loop through activities and get compare the required activitiy with the activity that is parsed from the upload. If it matches then the capability is true. If there is no match a slot needs to be added with that required skill and the capability should be set to false

        let isAdmin = false;
        let isCpr = false;

        let data = {
            "first_name": randomFirstName,
            "last_name": randomLastName,
            "is_admin": isAdmin,
            "cpr": isCpr,
            "is_activity_specialist": false,
            "notes": randomNotes,
            "days":
            {
                "monday": ["K1", "K2", "K3", "K4", "K5"],
                "tuesday": ["G1", "G2", "G3", "G4", "G5"],
                "wednesday": ["K1", "K2", "K3", "K4", "K5"],
                "thursday": ["G1", "G2", "G3", "G4", "G5"],
                "friday": ["K1", "K2", "K3", "K4", "K5"],
                "saturday": ["G1", "G2", "G3", "G4", "G5"]
            },
            "counselor_skills": allCounselorSkills
        }
        console.log(data);
        //set new doc name to be the next number in the sequence of counselors based on doc id with the highest number
        const largestDocId = counselors.length + 1;
        const newDocName = "counselor_" + Math.random().toString(36).substr(2, 9);
        const newDocNameFormatted = newDocName.replace(/\s+/g, '_').toLowerCase();
        const newDocRef = doc(db, 'schedules', activeSchedule, 'counselors', newDocNameFormatted);
        setDoc(newDocRef, data);
        pullDatabase();
        resetAllValues()
    }

  return (
    <div className="settings-block add">
    <button onClick={addRandomCounselor} >Magically Add Random Counselor</button>
        <div className='settings-block-wrapper'>
            <input id="firstName" type="text" placeholder="First Name" />
            <input id="lastName" type="text" placeholder="Last Name" />
        </div>
        <div className='settings-block-wrapper'>
            <textarea id="notes" placeholder="Notes..." ></textarea>
        </div>
        <div className='settings-block-wrapper'>
            <label>Is Admin?</label>
            <input id='admin' value={checked} onClick={() => setChecked(!checked)} type="checkbox" />
        </div>
        <div className='settings-block-wrapper'>
            <label>CPR Certified</label>
            <input id='cpr' value={cprChecked} onClick={() => setCprChecked(!cprChecked)} type="checkbox" />
        </div>
        <div className='settings-block-wrapper'>
            <label>Activity Specialist</label>
            <input id='activitySpecialist' value={activitySpecialistChecked} onClick={() => setActivitySpecialistChecked(!activitySpecialistChecked)} type="checkbox" />
        </div>
        {
            Object.entries(settings[activitiesIndex].days)
            .sort((a, b) => dayOrder.indexOf(a[0]) - dayOrder.indexOf(b[0]))
            .map(([day, periods], dayIndex) => (
                <div key={dayIndex} className="day-wrapper">
                    <h6 className="day-header">
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                        <span
                            className={`select-all ${Object.keys(selectedPeriods).some((selectedDay) => selectedDay === day && periods.every((period) => selectedPeriods[selectedDay].includes(period))) ? 'active' : ''}`}
                            onClick={(e) => handleSelectAll(e, day, periods)}
                        >
                            {Object.keys(selectedPeriods).some((selectedDay) => selectedDay === day && periods.every((period) => selectedPeriods[selectedDay].includes(period))) ? 'De-select All' : 'Select All'}
                        </span>
                    </h6>
                    <div className='periods-wrapper'>
                        {periods.map((period, periodIndex) => (
                            <div
                                key={periodIndex}
                                className={`period-wrapper ${selectedPeriods[day] && selectedPeriods[day].includes(period) ? 'active' : ''}`}
                                onClick={(e) => changePeriod(e, day, period)}
                            >
                                <span className='period'>{period}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ))
        }

        {counselorSkills && Object.keys(counselorSkills).map((activityId) => (
            <div className="activity" key={activityId}>
                <h5 className="activity-name">{activityId}</h5>
                <div className="required-skills">
                {counselorSkills[activityId] && counselorSkills[activityId].map((skill) => (
                    <div key={skill.skill} className="required-skill">
                    <input
                        type="checkbox"
                        id={`${activityId}-${skill.skill}-capability`}
                        name="capability"
                        value={skill.skill}
                        checked={skill.capability}
                        onChange={(e) =>
                        handleChange(e, activityId, skill.skill, "capability")
                        }
                    />
                    <label htmlFor={`${activityId}-${skill.skill}-capability`}>
                        {skill.skill}
                    </label>
                    <input
                        className="attribute-value is-priority-checkbox"
                        type="checkbox"
                        id={`${activityId}-${skill.skill}-priority`}
                        name="priority"
                        value={skill.skill}
                        checked={skill.priority}
                        onChange={(e) =>
                        handleChange(e, activityId, skill.skill, "priority")
                        }
                    />
                    <label htmlFor={`${activityId}-${skill.skill}-priority`}>
                        Priority
                    </label>
                    <br />
                    </div>
                ))}
                </div>
            </div>
            ))}


        <button onClick={addCounselor} >Add Counselor</button>
    </div>
  )
}