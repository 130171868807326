import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from '../../FirebaseConfig.js';


export default function CamperSkills({activeSchedule, camper, pullDatabase}) {

    const [campingSkillsData, setCampingSkillsData] = useState(camper?.camping_skills_data || {
        camping_skills: "No",
        camping_skills_options: ["Yes", "No"],
        camping_skills_placeholder: "No"
    });
    const [tennisAcademyData, setTennisAcademyData] = useState(camper?.tennis_academy_data || {
        tennis_academy: "No",
        tennis_academy_options: ["G6", "K6", "No"],
        tennis_academy_placeholder: "No"
    });
    const [swimTestData, setSwimTestData] = useState(camper?.swim_test_data || {
        swim_test: "No",
        swim_test_options: ["Yes", "No"],
        swim_test_placeholder: "No"
    });
    const [waterskiingTestData, setWaterskiingTestData] = useState(camper?.waterskiing_test_data || {
        waterskiing_test: "No",
        waterskiing_test_options: ["Yes", "No"],
        waterskiing_test_placeholder: "No"
    });
    const [timer, setTimer] = useState(null);


    useEffect(() => {
        if (camper && camper?.camping_skills_data) {
            setCampingSkillsData(camper?.camping_skills_data);
        }
        if (camper && camper?.tennis_academy_data) {
            setTennisAcademyData(camper?.tennis_academy_data);
        }
        if (camper && camper?.swim_test_data) {
            setSwimTestData(camper?.swim_test_data);
        }
        if (camper && camper?.waterskiing_test_data) {
            setWaterskiingTestData(camper?.waterskiing_test_data);
        }
    }, [camper]);


    const handleChange = async (e) => {

        let value = e.target.value;
        let id = e.target.id;
        let optionsObject;

        console.log(value);

        if (id === 'camping_skills') {
            optionsObject = {
                camping_skills: value,
                camping_skills_options: ["Yes", "No"],
                camping_skills_placeholder: value
            }
            setCampingSkillsData(optionsObject);
        } else if (id === 'tennis_academy') {
            optionsObject = {
                tennis_academy: value,
                tennis_academy_options: ["G6", "K6", "No"],
                tennis_academy_placeholder: value
            }
            setTennisAcademyData(optionsObject);
        } else if (id === 'swim_test') {
            optionsObject = {
                swim_test: value,
                swim_test_options: ["Yes", "No"],
                swim_test_placeholder: value
            }
            setSwimTestData(optionsObject);
        } else if (id === 'waterskiing_test') {
            optionsObject = {
                waterskiing_test: value,
                waterskiing_test_options: ["Yes", "No"],
                waterskiing_test_placeholder: value
            }
            setWaterskiingTestData(optionsObject);
        }


        console.log(optionsObject);

        clearTimeout(timer)

        const camperDoc = camper.camperId;
        const docRef = doc(db, 'schedules', activeSchedule, 'campers', camperDoc);
        const newTimer = setTimeout( async () => {
            try {
                await runTransaction(db, async (transaction) => {
                    const doc = await transaction.get(docRef);
                    if (!doc.exists()) {
                        throw "Document does not exist!";
                    }
                    let tempDoc = doc.data();
                    if (id === 'camping_skills') {
                        tempDoc.camping_skills_data = optionsObject;
                    } else if (id === 'tennis_academy') {
                        tempDoc.tennis_academy_data = optionsObject;
                    } else if (id === 'swim_test') {
                        tempDoc.swim_test_data = optionsObject;
                    } else if (id === 'waterskiing_test') {
                        tempDoc.waterskiing_test_data = optionsObject;
                    }
                    console.log(tempDoc);

                    transaction.set(docRef, tempDoc);

                });
                console.log("Transaction successfully committed!");
            } catch (e) {
                console.log("Transaction failed: ", e);
            }

            pullDatabase();
        }, 1000)

        setTimer(newTimer)
    }

  return (
    <div className="detail-info-wrapper">
        <h5>Skills</h5>
        <div className="skills-wrapper">
            <div className="skill">
                <h6 className="skill-name">Camping Skills:</h6>
                <select id="camping_skills" value={campingSkillsData.camping_skills} onChange={(e) => handleChange(e)}>
                    {campingSkillsData.camping_skills_options.map(option => (
                        <option key={option} value={option}>
                        {option}
                        </option>
                    ))}
                </select>

            </div>
                <div className="skill">
                <h6 className="skill-name">Tennis Academy:</h6>
                <select id="tennis_academy" value={tennisAcademyData.tennis_academy} onChange={(e) => handleChange(e)}>
                    {tennisAcademyData.tennis_academy_options.map(option => (
                        <option key={option} value={option}>
                        {option}
                        </option>
                    ))}
                </select>

            </div>
            <div className="skill">
                <h6 className="skill-name">Passed Swim Test:</h6>
                <select id="swim_test" value={swimTestData.swim_test} onChange={(e) => handleChange(e)}>
                    {swimTestData.swim_test_options.map(option => (
                        <option key={option} value={option}>
                        {option}
                        </option>
                    ))}
                </select>

            </div>
            <div className="skill">
                <h6 className="skill-name">Passed Waterskiing Test:</h6>
                <select id="waterskiing_test" value={waterskiingTestData.waterskiing_test} onChange={(e) => handleChange(e)}>
                    {waterskiingTestData.waterskiing_test_options.map(option => (
                        <option key={option} value={option}>
                        {option}
                        </option>
                    ))}
                </select>

            </div>
        </div>
    </div>
  )
}