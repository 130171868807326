import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { getDoc, doc, deleteDoc, collection } from 'firebase/firestore';
import { db } from '../FirebaseConfig.js';

//Styles & Assets
import '../assets/style.css'
import arrow from '../assets/images/arrow.svg'
import Counselors from '../pages/Counselors.js';
import ActivityLocation from './activities/Location.js';

//Components
import DetailedInfoPeriods from './DetailedInfoPeriods.js';
import Location from './activities/Location.js';
import BatchBy from './activities/BatchBy.js';
import ActivityRequirements from './activities/Requirements.js';
import ActivityMinMaxKids from './activities/MinMaxKids.js';
import ActivitiesRequiredCounselorSkills from './activities/ActivitiesRequiredCounselorSkills.js';
import ActivityNotes from './activities/Notes.js';
import RequiredCabins from './activities/RequiredCabins.js';


//class DetailedInfo extends React.Component ({camperOrCounselor, isActivity, location}) {
export default function DetailedInfo({activeSchedule, setActivityIndex, camperOrCounselor, isActivity, activity, settings, setActivities, setSettings, activities, setActivity, pullDatabase}) {

    const activitiesIndex = settings.findIndex((item) => item.settingId === 'activities');
    const cabin_groups = ['Cabin 1', 'Cabin 2', 'Cabin 3', 'Cabin 4', 'Cabin 5', 'Cabin 6', 'Cabin 7', 'Cabin 8', 'Cabin 9', 'Cabin 10'];
    const cabin_options = [
        {value: 'cabin', label: 'Cabin'},
        {value: 'random', label: 'Random'}
    ];

    //new variables

    let activeKPeriods = activity.k_periods ? activity.k_periods : null;
    let activeGPeriods = activity.g_periods ? activity.g_periods : null;
    let location = activity.location ? activity.location : null;


    //const [settingsType, settingsData] = settings[activitiesIndex];
    const settingsData = settings[activitiesIndex];
    const kPeriods = settingsData.k_periods ? settingsData.k_periods : null;
    const gPeriods = settingsData.g_periods ? settingsData.g_periods : null;

    const test = ['1', '2', '3'];
    const [cabin, setCabin] = useState('Cabin');
    const [activityLocation, setActivityLocation] = useState('');
    const [cabinGroupList, setCabinGroupList] = useState([cabin_groups]);

    //find index of activity in activities array
    const activityIndex = activities.findIndex((act) => act.activityId === activity.activityId) + 1;
    const prevActivityIndex =  (activityIndex > 1) ? activityIndex - 2 : 0;


    //dynamically create an isActive useState object
    // Declare the isActive state variable and setIsActive state setter
    const [isActive, setIsActive] = useState(() => {
        let obj = {};
        cabinGroupList.forEach((cabinGroup, index) => {
            cabinGroup.forEach((cabin, index) => {
                obj[cabin] = false;
            })
        });
        return obj;
    });

    const handleCabinClick = (e, cabin) => {
        //console.log(cabin);

        //update the isActive object state
        setIsActive(prevState => {
            return {
                ...prevState,
                [cabin]: !prevState[cabin]
            }
        });



    }

    const addRow = () => {
        cabinGroupList.push(cabin_groups);
        //use setCabinGroupList to replace state
        setCabinGroupList([...cabinGroupList]);
    }


    async function deleteActivity() {
        const activityRef = doc(db, "schedules", activeSchedule, "activities", activity.activityId);
        await deleteDoc(activityRef);
        setActivityIndex(0)
        pullDatabase();
    }

  return (
    <div className='detailed-info'>
        <div className="nav">
            <div className="info-count">
                <div className="info-count-numbers">
                    <h6><span className="current-number">{activityIndex}</span>/<span className="total-number">{activities.length}</span></h6>
                    <div className="info-arrows">
                        <img
                            className={`arrow-left ${activityIndex > 1 ? "" : "first"}`}
                            src={arrow}
                            onClick={() => setActivityIndex(prevActivityIndex)}
                            alt="arrow"
                        />
                        <img
                            className="arrow-right"
                            src={arrow}
                            onClick={() => setActivityIndex(activityIndex)}
                            alt="arrow"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="info-wrapper">
                <div className="detail-info-wrapper">
                    <h5>{activity.name}</h5>
                    <div className="activity-periods-wrapper">
                        <p>
                            {/*print the periods that have an isActive state of true, printing commas between each one if there are more than one returned*/
                                //Object.keys(isActive).filter(period => isActive[period]).join(', ')
                            }
                        </p>
                    </div>
                </div>

            { activity && kPeriods && gPeriods && activeKPeriods && activeGPeriods &&
                <DetailedInfoPeriods
                    pullDatabase={pullDatabase}
                    setActivity={setActivity}
                    activities={activities}
                    activity={activity}
                    kPeriods={kPeriods}
                    gPeriods={gPeriods}
                    activeKPeriods={activeKPeriods}
                    activeGPeriods={activeGPeriods}
                    setActivities={setActivities}
                    setSettings={setSettings}
                    activeSchedule={activeSchedule}
                />
            }

                <RequiredCabins activeSchedule={activeSchedule} activity={activity} settings={settings} pullDatabase={pullDatabase}/>
                <div className="detail-info-wrapper">
                    <h5>Attributes</h5>
                    <div className="attributes-wrapper">
                        <ActivityLocation activeSchedule={activeSchedule} location={location} activity={activity} activities={activities} pullDatabase={pullDatabase}/>
                        <BatchBy activeSchedule={activeSchedule} activity={activity} pullDatabase={pullDatabase} settings={settings}/>
                        <ActivityMinMaxKids activeSchedule={activeSchedule} activity={activity} activities={activities} pullDatabase={pullDatabase} />
                        <ActivityRequirements activeSchedule={activeSchedule} activity={activity} activities={activities} pullDatabase={pullDatabase} />
                        <ActivitiesRequiredCounselorSkills activeSchedule={activeSchedule} activity={activity} activities={activities} pullDatabase={pullDatabase} settings={settings} />
                    </div>
                </div>
            <ActivityNotes activeSchedule={activeSchedule} activity={activity} activities={activities} pullDatabase={pullDatabase} />
        </div>
        <button className="delete" onClick={() => deleteActivity()}>Delete</button>
    </div>
  )
}