//this will need to has a pass in from a firebase collection at some point.
//for now im just going to generate random template, but the search will eventually be stored in a firebase collection and then retrieved from there.

import React from 'react'

export default function SearchCard({activeSchedule, activity, index, setActivityIndex}) {
  //console.log(activity)
  return (
    <div className='search-card' onClick={() => setActivityIndex(index)}>
        <div className="name-info-wrapper">
            <div className="name-wrapper">
                <h6 className="name">{activity.name}</h6>
                {!activity && <h6 className="title">Rancher</h6>}
            </div>
            <div className="location-wrapper">
                <h6 className="location">{activity.location}</h6>
            </div> 
        </div>
        {!activity && <p className="notes">Notes: Sam injured her shoulder and can't do any sports activities</p>}
        {!activity && <p className="special-activities">Special activities: Horseback riding, tennis academy</p>}
        {activity &&
          <div>
              <h6 className="periods-title">Available Periods</h6>
              <div className="periods-list">
                  <h6>K Periods: </h6>
                  {activity.k_periods &&
                    activity.k_periods.sort((a, b) => {
                    // Compare the periods by converting them to lowercase and using the
                    // localeCompare method to compare the strings lexicographically.
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                  }).map((period, index) => {
                    return (
                      <div key={period}>
                        <p className="period">{period}
                        {//if not last period, add a comma
                          index !== activity.k_periods.length - 1 && <span>, </span>
                        }
                        </p>
                      </div>
                    )

                  })}
              </div>
              <div className="periods-list">
              <h6>G Periods: </h6>
                {activity.g_periods &&
                    activity.g_periods.sort((a, b) => {
                    // Compare the periods by converting them to lowercase and using the
                    // localeCompare method to compare the strings lexicographically.
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                  }).map((period, index) => {
                    return (
                      <div key={period}>
                        <p className="period">{period}
                        {//if not last period, add a comma
                          index !== activity.k_periods.length - 1 && <span>, </span>
                        }
                        </p>
                      </div>
                    )

                  })}
              </div>
          </div>
        }
    </div>
  )
}
