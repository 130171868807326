import React from 'react'

//Styles
import '../assets/style.css'

//Components
import RunScheduler from '../components/scheduler/RunScheduler.js'

export default function Scheduler({activeSchedule, settings, activities, counselors, campers, pullDatabase, singleSchedule, schedules, setShowSidebar}) {
  setShowSidebar(true);
  return (
    <div className="scheduler" id="scheduler">
        {singleSchedule && <RunScheduler activeSchedule={activeSchedule} settings={settings} activities={activities} counselors={counselors} campers={campers} pullDatabase={pullDatabase} singleSchedule={singleSchedule} schedules={schedules}/> }
    </div>
  )
}
