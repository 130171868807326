import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';



export default function CounselorAvailableDays({activeSchedule, counselor, counselors, settings, pullDatabase}) {

    // let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    // let activeDays = (counselor.days || []);


    // const handleCounselorClick = async (e, day) => {
    //     console.log(day);

    //     const counselorDoc = counselor.counselorId;
    //     let tempActiveDays = [...activeDays];

    //     if (tempActiveDays.includes(day)) {
    //         tempActiveDays = tempActiveDays.filter((item) => item !== day);
    //     } else {
    //         tempActiveDays.push(day);
    //     }  

    //     const docRef = doc(db, 'schedules', activeSchedule, 'counselors', counselorDoc);
    //     try {
    //         await runTransaction(db, async (transaction) => {
    //             const doc = await transaction.get(docRef);
    //             if (!doc.exists()) {
    //                 throw "Document does not exist!";
    //             }
    //             const tempDoc = doc.data();
    //             tempDoc.days = tempActiveDays;
    //             console.log(tempDoc);

    //             transaction.set(docRef, tempDoc);

    //         });
    //         console.log("Transaction successfully committed!");
    //     } catch (e) {
    //         console.log("Transaction failed: ", e);
    //     }

    //     pullDatabase();
    // }

  return (
    <></>
    // <div className="detail-info-wrapper">
    //     <h5>Available Days</h5>
    //     <div className="available-days-wrapper">
    //         {days && days.map((day) => {
    //             const isActive = activeDays.includes(day);
    //             return (
    //                 <div
    //                     key={day}
    //                     className={`period-wrapper ${isActive ? 'active' : ''}`}
    //                     onClick={(e) => handleCounselorClick(e, day)}
    //                 >
    //                     {day}
    //                 </div>
    //             );
    //         })}
    //     </div>
    // </div>
  )
}