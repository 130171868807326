import { getAuth, signOut } from "firebase/auth";

const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      // Do something after sign out if needed
    } catch (error) {
      console.log(error.message);
    }
};


const SignOut = () => {
    return (
      <button className="signout-btn" onClick={handleSignOut}>
        <h6>Sign Out</h6>
      </button>
    );
};

export default SignOut;