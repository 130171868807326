import { auth } from '../FirebaseConfig.js';
import { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth';

import loginImg from '../assets/images/login.jpg';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Logged in');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id='login'>
      <div className='two-column'>
        <div className='left'>
          <div className='text-wrapper'>
            <h1>Login</h1>
            <input placeholder='Email' type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input placeholder='Password' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button className='login-btn' onClick={handleLogin}>
              <h6>Login</h6>
            </button>
          </div>
        </div>
        <div className='right'>
          <img src={loginImg} alt="login image" />
        </div>
      </div>
    </div>
  );
}