import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { getDoc, doc, deleteDoc, collection } from 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';

//Styles & Assets
import arrow from '../../assets/images/arrow.svg'
import Counselors from '../../pages/Counselors.js';

//Components
import DetailedInfoPeriods from '../DetailedInfoPeriods.js';
import CounselorRequiredCheckboxes from './RequiredCheckboxes.js';
import CounselorAvailablePeriods from './CounselorAvailablePeriods.js';
import CounselorNotes from './Notes.js';
import CounselorAvailableDays from './AvailableDays.js';
import CounselorName from './CounselorName.js';
import IndividualSchedule from '../IndividualSchedule.js';


//class DetailedInfo extends React.Component ({camperOrCounselor, isActivity, location}) {
export default function DetailedInfo({activeSchedule, activities, counselors, setCounselorIndex, counselor, settings, pullDatabase, singleSchedule, printScheduleLogic, setPrintScheduleLogic}) {

    const notes = counselor.notes ? counselor.notes : '';

    const counselorIndex = counselors.findIndex((counsel) => counsel.counselorId === counselor.counselorId) + 1;
    const prevCounselorIndex =  (counselorIndex > 1) ? counselorIndex - 2 : 0;

    async function deleteCounselor() {
        const counselorRef = doc(db, "schedules", activeSchedule, "counselors", counselor.counselorId);
        await deleteDoc(counselorRef);
        setCounselorIndex(0)
        pullDatabase();
    }

  return (
    <div className='detailed-info'>
        {counselor && (
            <>
                <div className="nav">
                    <div className="info-count">
                        <div className="info-count-numbers">
                            <h6><span className="current-number">{counselorIndex}</span>/<span className="total-number">{counselors.length}</span></h6>
                            <div className="info-arrows">
                                <img
                                    className={`arrow-left ${counselorIndex > 1 ? "" : "first"}`}
                                    src={arrow}
                                    onClick={() => setCounselorIndex(prevCounselorIndex)}
                                    alt="arrow"
                                />
                                <img
                                    className="arrow-right"
                                    src={arrow}
                                    onClick={() => setCounselorIndex(counselorIndex)}
                                    alt="arrow"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-wrapper">
                        <CounselorName activeSchedule={activeSchedule} counselor={counselor} pullDatabase={pullDatabase} />

                        <IndividualSchedule singleSchedule={singleSchedule} counselor={counselor} activeSchedule={activeSchedule} printScheduleLogic={printScheduleLogic} setPrintScheduleLogic={setPrintScheduleLogic}/>

                        <div className="detail-info-wrapper">
                            <CounselorAvailableDays activeSchedule={activeSchedule} counselor={counselor} counselors={counselors} pullDatabase={pullDatabase} settings={settings} />
                        </div>

                        <div className='detail-info-wrapper'>
                            <CounselorAvailablePeriods activeSchedule={activeSchedule} counselor={counselor} counselors={counselors} pullDatabase={pullDatabase} settings={settings} />
                        </div>

                        <div className="detail-info-wrapper">
                            <h5>Skills</h5>
                            <CounselorRequiredCheckboxes activeSchedule={activeSchedule} activities={activities} counselor={counselor} counselors={counselors} pullDatabase={pullDatabase} settings={settings}  />
                        </div>
                    <CounselorNotes activeSchedule={activeSchedule} notes={notes} counselor={counselor} counselors={counselors} pullDatabase={pullDatabase} settings={settings} />
                </div>
                <button className="delete" onClick={() => deleteCounselor()}>Delete</button>
            </>
        )}
    </div>
  )
}