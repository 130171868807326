import React, { useEffect } from 'react'
import { useState } from 'react'

//Styles
import '../assets/style.css'

//Components
import Search from '../components/Search'
import DetailedInfo from '../components/DetailedInfo'

export default function Activities({setShowSidebar, activeSchedule, activities, settings, setActivities, setSettings, pullDatabase}) {

  setShowSidebar(true);

  var isActivity = true;
  var location = 'Waterfront';

  const [activityIndex, setActivityIndex] = useState(0);
  const activity = activities[activityIndex];
  // useEffect(() => {
  //   console.log('activities', activities);
  //   console.log('settings', settings
  // )}, [activities, settings]);

  // console.log(activities);
  return (
    <div className='main-content'>
      <Search activeSchedule={activeSchedule} location={location} activities={activities} setActivityIndex={setActivityIndex} />
      {activities && settings && <DetailedInfo activeSchedule={activeSchedule} setActivityIndex={setActivityIndex} pullDatabase={pullDatabase} camperOrCounselor="neither" isActivity={isActivity} location={location} activity={activity} settings={settings} setActivities={setActivities} setSettings={setSettings} activities={activities}/>}
    </div>
  )
}
