import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from '../../FirebaseConfig.js';



export default function CounselorName({activeSchedule, counselor, pullDatabase}) {

    const [firstNameValue, setFirstNameValue] = useState(counselor.first_name || "");
    const [lastNameValue, setLastNameValue] = useState(counselor.last_name || "");

    console.log(counselor)

    const handleIsAdmin = async (e) => {

        const checked = e.target.checked;

        const counselorDoc = counselor.counselorId;
        const docRef = doc(db, 'schedules', activeSchedule, 'counselors', counselorDoc);

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                tempDoc.is_admin = checked;
                console.log(tempDoc.is_admin);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

    const handleIsActivitySpecialist = async (e) => {
        const checked = e.target.checked;
        const counselorDoc = counselor.counselorId;
        const docRef = doc(db, 'schedules', activeSchedule, 'counselors', counselorDoc);

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                tempDoc.is_activity_specialist = checked;
                console.log(tempDoc.is_activity_specialist);
                
                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

    const handleCpr = async (e) => {
        const checked = e.target.checked;
        const counselorDoc = counselor.counselorId;
        const docRef = doc(db, 'schedules', activeSchedule, 'counselors', counselorDoc);

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                tempDoc.cpr = checked;
                console.log(tempDoc.cpr);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

    const [timer, setTimer] = useState(null)

    useEffect(() => {
        setFirstNameValue(counselor.first_name || "");
        setLastNameValue(counselor.last_name || "");
    }, [counselor]);

    const handleChange = async (e) => {
        //e,preventDefault();
        let newName = e.target.value;
        if (e.target.name === "firstName") {
            setFirstNameValue(newName);
        } else if (e.target.name === "lastName") {
            setLastNameValue(newName);
        }

        console.log(newName);

        clearTimeout(timer)

        const counselorDoc = counselor.counselorId;
        const docRef = doc(db, 'schedules', activeSchedule, 'counselors', counselorDoc);
        const newTimer = setTimeout( async () => {
            try {
                await runTransaction(db, async (transaction) => {
                    const doc = await transaction.get(docRef);
                    if (!doc.exists()) {
                        throw "Document does not exist!";
                    }
                    let tempDoc = doc.data();
                    if (e.target.name === "firstName") {
                        tempDoc.first_name = newName;
                    } else if (e.target.name === "lastName") {
                        tempDoc.last_name = newName;
                    }
                    console.log(tempDoc);

                    transaction.set(docRef, tempDoc);

                });
                console.log("Transaction successfully committed!");
            } catch (e) {
                console.log("Transaction failed: ", e);
            }

            pullDatabase();
        }, 1000)

        setTimer(newTimer)
    }

  return (
    <>
        <div className="name-info-wrapper ">
            <div className="name-wrapper">
                <input className="name-input" type="text" name='firstName' value={firstNameValue} onChange={handleChange} />
                <input className="name-input" type="text" name='lastName' value={lastNameValue} onChange={handleChange} />
            </div>
        </div>
        <div className='name-info-wrapper vertical'>
            <h6 className="title">Counselor</h6>
            <div className='admin-wrapper'>
                <label className='is-admin'>
                <input
                    className="attribute-value is-admin-checkbox"
                    name="isAdmin"
                    type="checkbox"
                    checked={counselor?.is_admin || false}
                    onChange={handleIsAdmin}
                />
                Admin</label>
            </div>
            <div className='admin-wrapper'>
                <label className='is-admin'>
                <input
                    className="attribute-value is-admin-checkbox"
                    name="isActivitySpecialist"
                    type="checkbox"
                    checked={counselor?.is_activity_specialist || false}
                    onChange={handleIsActivitySpecialist}
                />
                Activity Specialist</label>
            </div>
            <div className='admin-wrapper'>
                <label className='is-admin'>
                <input
                    className="attribute-value is-admin-checkbox"
                    name="isAdmin"
                    type="checkbox"
                    checked={counselor?.cpr || false}
                    onChange={handleCpr}
                />
                CPR Certified</label>
            </div>
        </div>
    </>
  )
}