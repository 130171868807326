import { useEffect, useState, useRef } from "react";
import { db } from "../FirebaseConfig.js";
import { collection, onSnapshot } from "firebase/firestore";

export const useCollection = (_collection, _query, _orderBy) => {
    const [documents, setDocuments] = useState(null);
    const [error, setError] = useState(null);

    //if we dont use a ref --> infinite loop in useEffect
    //_query is an aran and is "different" on every function call
    const query = useRef(_query).current;
    const orderBy = useRef(_orderBy).current;

    useEffect(() => {
        let ref = collection(db, _collection);

        if (query) {
            ref = ref.where(...query);
        }

        if(orderBy) {
            ref = ref.orderBy(...orderBy)
        }

        const unsubscribe = onSnapshot(ref, (snapshot) => {
            let results = [];
            snapshot.docs.forEach(doc => {
                results.push({...doc.data(), id: doc.id});
            })

            //update state
            setDocuments(results);
            setError(null);
        }, (error) => {
            console.log(error);
            setError('Could not fetch data');
        });

        //unsubscribe on unmount
        return () => unsubscribe();
    }, [_collection, query, orderBy]);

    return { documents, error };
}