import React from 'react'

//Styles
import '../assets/style.css'

//Components
import SettingsScreen from '../components/SettingsScreen'

export default function Settings({setShowSidebar, activeSchedule, settings, activities, pullDatabase, campers, counselors}) {
  setShowSidebar(true);
  return (
    <div className='settings' id="settings">
      <SettingsScreen activeSchedule={activeSchedule} settings={settings} activities={activities} pullDatabase={pullDatabase} campers={campers} counselors={counselors} />
    </div>
  )
}