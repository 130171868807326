import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {
  runTransaction,
  writeBatch,
  getDoc,
  doc,
  collection,
} from "firebase/firestore";
import { db } from "../../FirebaseConfig.js";
//import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function SettingsTotalCabins({activeSchedule, settings, pullDatabase }) {

  //find settings that has settingId of 'campers'
  const campersIndex = settings.findIndex((item) => item.settingId === "campers");

  const groupOrder = ['unused', 'ranchers', 'dudes', 'pioneers'];
  const [cabinGroups, setCabinGroups] = useState(settings[campersIndex].cabin_data || {
    unused: {
      name: "Unused",
      cabins: [
        { value: "cabin_1", label: "Cabin 1" },
        { value: "cabin_2", label: "Cabin 2" },
        { value: "cabin_3", label: "Cabin 3" },
        { value: "cabin_4", label: "Cabin 4" },
        { value: "cabin_5", label: "Cabin 5" },
        { value: "cabin_6", label: "Cabin 6" },
        { value: "cabin_7", label: "Cabin 7" },
        { value: "cabin_8", label: "Cabin 8" },
        { value: "cabin_9", label: "Cabin 9" },
        { value: "cabin_10", label: "Cabin 10" },
      ],
    },
    dudes: {
      name: "Dudes",
      cabins: [],
    },
    pioneers: {
      name: "Pioneers",
      cabins: [],
    },
    ranchers: {
      name: "Ranchers",
      cabins: [],
    },
  });


  async function handleSelectClick(e) {
    //get group name from data-group
    let group = e.target.dataset.group;
    group = group.toLowerCase();

    console.log(group);

    //get selected cabin from select
    let cabin = e.target.value;
    let cabinLabel = e.target.options[e.target.selectedIndex].text;

    console.log(group, cabin);

    //update setCabinGroups to move cabin to group
    let tempCabinGroups = { ...cabinGroups };

    //find group that contains cabin
    let oldGroup = Object.keys(tempCabinGroups).find((group) => {
      return tempCabinGroups[group].cabins.find((cabinObj) => {
        return cabinObj.value === cabin;
      });
    });

    //remove cabin from old group
    tempCabinGroups[oldGroup].cabins = tempCabinGroups[oldGroup].cabins.filter(
      (cabinObj) => {
        return cabinObj.value !== cabin;
      }
    );

    //add cabin to new group
    tempCabinGroups[group].cabins.push({ value: cabin, label: cabinLabel });

    //abc123 sort tempCabinGroups[group].cabins by label
    Object.keys(tempCabinGroups).forEach((group) => {
      tempCabinGroups[group].cabins.sort((a, b) => {
        const numA = parseInt(a.label.match(/\d+/)[0]);
        const numB = parseInt(b.label.match(/\d+/)[0]);

        if (numA < numB) {
          return -1;
        } else if (numA > numB) {
          return 1;
        } else {
          return 0;
        }
      });
    });

    console.log(tempCabinGroups);

    //update state
    setCabinGroups(tempCabinGroups);

    //Set a new timeout that will run the update function after the user has finished typing
    const docRef = doc(db, 'schedules', activeSchedule, 'settings', 'campers');

    try {
        await runTransaction(db, async (transaction) => {
            const doc = await transaction.get(docRef);
            if (!doc.exists()) {
            throw "Document does not exist!";
            }
            const tempDoc = doc.data();

            tempDoc.cabin_data = tempCabinGroups;

            console.log(tempDoc);

            transaction.set(docRef, tempDoc);

        });
        console.log("Transaction successfully committed!");
    } catch (e) {
        console.log("Transaction failed: ", e);
    }

    pullDatabase();

  }


// define a function to get the label of the largest cabin
const getLargestCabinLabel = () => {
  let largestCabin = { label: "Cabin 0" };

  Object.keys(cabinGroups).forEach((group) => {
    const cabins = cabinGroups[group].cabins;
    if (cabins.length > 0) {
      const largestCabinInGroup = cabins.reduce((largest, cabin) => {
        const cabinNum = parseInt(cabin.label.replace("Cabin ", ""));
        if (cabinNum > parseInt(largest.label.replace("Cabin ", ""))) {
          return cabin;
        }
        return largest;
      }, cabins[0]);
      if (parseInt(largestCabinInGroup.label.replace("Cabin ", "")) > parseInt(largestCabin.label.replace("Cabin ", ""))) {
        largestCabin = largestCabinInGroup;
      }
    }
  });

  return largestCabin.label;
};


// define a function to add a new cabin
const handleAddCabin = async () => {
  const largestCabinLabel = getLargestCabinLabel();
  const largestCabinNum = parseInt(largestCabinLabel.replace("Cabin ", ""));
  const newCabinNum = largestCabinNum + 1;
  const newCabinLabel = `Cabin ${newCabinNum}`;

  const newCabin = {
    value: `cabin_${newCabinNum}`,
    label: newCabinLabel
  };

  const tempCabinGroups = { ...cabinGroups };
  //add cabin to new group
  tempCabinGroups.unused.cabins.push(newCabin);

  //abc123 sort tempCabinGroups[group].cabins by label
  Object.keys(tempCabinGroups).forEach((group) => {
    tempCabinGroups[group].cabins.sort((a, b) => {
      const numA = parseInt(a.label.match(/\d+/)[0]);
      const numB = parseInt(b.label.match(/\d+/)[0]);

      if (numA < numB) {
        return -1;
      } else if (numA > numB) {
        return 1;
      } else {
        return 0;
      }
    });
  });

  setCabinGroups(tempCabinGroups)

  //Set a new timeout that will run the update function after the user has finished typing
  const docRef = doc(db, 'schedules', activeSchedule, 'settings', 'campers');

  try {
      await runTransaction(db, async (transaction) => {
          const doc = await transaction.get(docRef);
          if (!doc.exists()) {
          throw "Document does not exist!";
          }
          const tempDoc = doc.data();

          tempDoc.cabin_data = tempCabinGroups;

          console.log(tempDoc);

          transaction.set(docRef, tempDoc);

      });
      console.log("Transaction successfully committed!");
  } catch (e) {
      console.log("Transaction failed: ", e);
  }

  pullDatabase();

};

const handleDeleteCabin = async () => {
  const cabins = cabinGroups.unused.cabins;
  let tempCabinGroups = { ...cabinGroups };
  if (cabins.length > 0) {
    const lastCabin = cabins[cabins.length - 1];
    setCabinGroups((cabinGroups) => {
      const tempCabinGroups = { ...cabinGroups };
      Object.keys(tempCabinGroups).forEach((group) => {
        tempCabinGroups[group].cabins = tempCabinGroups[group].cabins.filter(
          (cabinObj) => {
            return cabinObj.value !== lastCabin.value;
          }
        );
      });
      return tempCabinGroups;
    });

    //Set a new timeout that will run the update function after the user has finished typing
    const docRef = doc(db, 'schedules', activeSchedule, 'settings', 'campers');

    try {
        await runTransaction(db, async (transaction) => {
            const doc = await transaction.get(docRef);
            if (!doc.exists()) {
            throw "Document does not exist!";
            }
            const tempDoc = doc.data();

            tempDoc.cabin_data = tempCabinGroups;

            console.log(tempDoc);

            transaction.set(docRef, tempDoc);

        });
        console.log("Transaction successfully committed!");
    } catch (e) {
        console.log("Transaction failed: ", e);
    }

    pullDatabase();
  }
};


  return (
    <div>
      <div>
        <h4>All Cabins</h4>
        <div className="btn-wrapper">
        <button className="add-btn" onClick={handleAddCabin}>Add Cabin</button>
        <button className="delete-btn" onClick={handleDeleteCabin}>Delete Cabin</button>
        </div>
        <ul>
          {Object.values(cabinGroups).flatMap((group) => group.cabins)
            .sort((a, b) => {
              const numA = parseInt(a.label.match(/\d+/)[0]);
              const numB = parseInt(b.label.match(/\d+/)[0]);
              return numA - numB;
            })
            .map((cabin, index) => (
              <li key={cabin.value}>{cabin.label}</li>
            ))
          }
        </ul>
      </div>

      {Object.keys(cabinGroups)
        .sort((a, b) => groupOrder.indexOf(a) - groupOrder.indexOf(b))
        .map((group, index) => (
        <div className="cabin-groups" key={group}>
          <h4>{cabinGroups[group].name}</h4>
          <select
            data-group={cabinGroups[group].name}
            onChange={(e) => handleSelectClick(e)}
          >
            <option value="">Select A Cabin</option>
            {Object.keys(cabinGroups)
              .reduce((acc, group) => acc.concat(cabinGroups[group].cabins), [])
              .sort((a, b) => {
                const numA = parseInt(a.label.match(/\d+/)[0]);
                const numB = parseInt(b.label.match(/\d+/)[0]);
                return numA - numB;
              })
              .map((cabin, index) => {
                return <option key={cabin.value} value={cabin.value}>{cabin.label}</option>;
              })}
          </select>

          <div className="cabin-wrapper">
            {cabinGroups[group].cabins.map((cabin, index) => (
              <button>{cabin.label}</button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}