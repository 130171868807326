import React, { useState, useEffect, useRef, useMemo } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from '../../FirebaseConfig.js';
import userEvent from '@testing-library/user-event';
import { set } from 'lodash';

export default function BatchBy({activeSchedule, activity, pullDatabase, settings}) {
    const campersIndex = settings.findIndex((item) => item.settingId === 'campers');
    const cabinData = settings[campersIndex].cabin_data;

    //cabins
    const getAllCabinsNotInUnused = (cabinData) => {
        const cabinsNotInUnused = [];
        Object.keys(cabinData).forEach((group) => {
        if (group !== "unused") {
            cabinData[group].cabins.forEach((cabin) =>
                cabinsNotInUnused.push(cabin)  //could we end up with duplicates here?
            );
        }
        });

        cabinsNotInUnused.sort((a, b) => {
            const cabinNumberA = parseInt(a.label.match(/\d+/)[0], 100);
            const cabinNumberB = parseInt(b.label.match(/\d+/)[0], 100);

            return cabinNumberA - cabinNumberB;
        });

        return cabinsNotInUnused;
    };

    const cleanCabinsData = getAllCabinsNotInUnused(cabinData);
    const cleanCabinsDataRef = useRef(cleanCabinsData);

    //set state of hideCabins to true
    const activityDoc = activity.activityId;
    const docRef = doc(db, 'schedules', activeSchedule, 'activities', activityDoc);

    const [hideCabins, setHideCabins] = useState(true);
    const [groups, setGroups] = useState('cabin_groups' in activity && activity.cabin_groups?.length !== 0 ? activity.cabin_groups : {'group_1': cleanCabinsDataRef.current});
    const [batchBy, setBatchBy] = useState(activity.batch_by || 'random');
    const [placeholder, setPlaceholder] = useState(batchBy.charAt(0).toUpperCase() + batchBy.slice(1));

    //loop over batchBy and create an array of options and push to batchByOptions if key id is not equal to selected
    const batchByOptions = [
        {value: 'cabin', label: 'Cabin'},
        {value: 'random', label: 'Random'}
    ];

    useEffect(() => {
        setBatchBy(activity.batch_by || 'random');
        setPlaceholder(batchBy.charAt(0).toUpperCase() + batchBy.slice(1));
        setGroups('cabin_groups' in activity && activity.cabin_groups?.length !== 0 ? activity.cabin_groups : {'group_1': cleanCabinsDataRef.current});

        if (batchBy === "cabin") {
            setHideCabins(false);
        } else {
            setHideCabins(true);
        }
    }, [activity, batchBy]);

    const handleSelectChange = (e) => {

        const reactSelectComponentValue = e.value;
        if(reactSelectComponentValue) {
            if (e.value === 'cabin') {
                setHideCabins(false)
                updateCabinGroups();
            } else {
                setHideCabins(true)
            }
            updateBatchBy(e.value);
        }
        else {
            changeCabinGroup(e);
        }
    }

    const addGroup = async () => {
        let groupKeys = Object.keys(groups);

        groupKeys.sort((a, b) => {
            const groupNumberA = parseInt(a.match(/\d+/)[0], 10);
            const groupNumberB = parseInt(b.match(/\d+/)[0], 10);
            return groupNumberA - groupNumberB;
        });

        let newGroupNumber = parseInt(groupKeys[groupKeys.length - 1].split('_')[1], 10) + 1;
        let newGroup = 'group_' + newGroupNumber;

        console.log(newGroup);

        setGroups({
            ...groups,
            [newGroup]: []
        });

        console.log(groups)
    }

    const removeGroup = (e) => {
        let groupKeys = Object.keys(groups);
        //sort the groupKeys array
        groupKeys.sort((a, b) => {
            const groupNumberA = parseInt(a.match(/\d+/)[0], 10);
            const groupNumberB = parseInt(b.match(/\d+/)[0], 10);
            return groupNumberA - groupNumberB;
        });
        let lastGroup = groupKeys[groupKeys.length - 1];
        let firstGroup = groupKeys[0];

        console.log({groupKeys, firstGroup, lastGroup})

        //remove lastGroup from groups
        let newGroups = {...groups};

        console.log(newGroups);
        if(groupKeys.length > 1) {
            //remove the objects at lastGroup and move them to the first group
            newGroups[firstGroup] = [...newGroups[firstGroup], ...newGroups[lastGroup]];
            delete newGroups[lastGroup];

            //organize the cabins in the first group
            newGroups[firstGroup].sort((a, b) => {
                const numA = parseInt(a.label.match(/\d+/)[0]);
                const numB = parseInt(b.label.match(/\d+/)[0]);

                if (numA < numB) {
                    return -1;
                } else if (numA > numB) {
                    return 1;
                } else {
                    return 0;
                }
            });

            setGroups(newGroups);
        }
    }

    const updateBatchBy = async (value) => {

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();

                tempDoc.batch_by = value;

                // console.log(tempDoc);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

    const updateCabinGroups = async () => {

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();

                tempDoc.cabin_groups = groups;

                // console.log(tempDoc);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

    const changeCabinGroup = async (e) => {
        //get group name from data-group
        let group = e.target.getAttribute('data-group');

        //get selected cabin from select
        let cabin = e.target.value;
        let cabinLabel = e.target.options[e.target.selectedIndex].text;

        //update setCabinGroups to move cabin to group
        let tempCabinGroups = { ...groups };

        //find group that contains cabin
        let oldGroup = Object.keys(tempCabinGroups).find((group) => {
            return tempCabinGroups[group].find((cabinObj) => {
            return cabinObj.value === cabin;
            });
        });

        //remove cabin from old group
        tempCabinGroups[oldGroup] = tempCabinGroups[oldGroup].filter(
            (cabinObj) => {
                return cabinObj.value !== cabin;
            }
        );

        //add cabin to new group
        tempCabinGroups[group].push({ value: cabin, label: cabinLabel });

        //abc123 sort tempCabinGroups[group].cabins by label
        Object.keys(tempCabinGroups).forEach((group) => {
            tempCabinGroups[group].sort((a, b) => {
            const numA = parseInt(a.label.match(/\d+/)[0]);
            const numB = parseInt(b.label.match(/\d+/)[0]);

            if (numA < numB) {
                return -1;
            } else if (numA > numB) {
                return 1;
            } else {
                return 0;
            }
            });
        });

        //update state, this triggers the database update as well because groups is being watched by useEffect
        setGroups(tempCabinGroups);

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();

                tempDoc.cabin_groups = tempCabinGroups;

                // console.log(tempDoc);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

    console.log(groups);

  return (
    <>
        <div className="attribute-wrapper batch-by">
            <h6 className="attribute-type">Batch by:</h6>
            <Select
                options={batchByOptions}
                onChange={(e) => handleSelectChange(e)}
                placeholder={placeholder}
            />

        </div>
        {!hideCabins && groups &&
            <div className="attribute-wrapper">
                <>
                    <h6 className="attribute-type">Batched Cabins:</h6>
                    <div className='groups-change'>
                        <div className="add-another-group" onClick={addGroup}>
                            +
                        </div>
                        <div className="remove-group" onClick={removeGroup}>
                            -
                        </div>
                    </div>
                    {Object.keys(groups)
                        .sort((a, b) =>  {
                            const groupNumberA = parseInt(a.match(/\d+/)[0], 10);
                            const groupNumberB = parseInt(b.match(/\d+/)[0], 10);
                            return groupNumberA - groupNumberB;
                        })
                        .map((key) => {
                            //convert the key to an uppercase string with the underscore removed
                            let groupName = (key.charAt(0).toUpperCase() + key.slice(1)).replace(/_/g, ' ');
                            return (
                                <>
                                    <h5>{groupName}</h5>
                                    <select
                                        data-group={key}
                                        onChange={(e) => handleSelectChange(e)}
                                    >
                                        <option value="">Select A Cabin</option>
                                        {cleanCabinsDataRef.current
                                        .sort((a, b) => {
                                            const numA = parseInt(a.label.match(/\d+/)[0]);
                                            const numB = parseInt(b.label.match(/\d+/)[0]);
                                            return numA - numB;
                                        })
                                        .map((cabin) => {
                                            return cabin ? <option key={cabin.value} value={cabin.value}>{cabin.label}</option> : null;
                                        })}
                                    </select>
                                    <div className="attribute-values" key={key}>
                                        {groups[key] && groups[key].map((cabin) => (
                                            <div
                                                className={`attribute-value-btn`}
                                                key={`key${Math.random()}`}
                                            >
                                                {cabin.label}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            );
                        })
                    }
                </>
            </div>
        }
    </>
  )
}