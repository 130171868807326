import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyBHAiDG0wHvzzRUYc72D6Z19hn23FkH4no",
    authDomain: "camp-scheduler-7784e.firebaseapp.com",
    databaseURL: "https://camp-scheduler-7784e-default-rtdb.firebaseio.com",
    projectId: "camp-scheduler-7784e",
    storageBucket: "camp-scheduler-7784e.appspot.com",
    messagingSenderId: "948103638068",
    appId: "1:948103638068:web:c15249fcd74f7dae2fae61"

    // apiKey: "AIzaSyCTqy4jlbEY2QmfK5JC8HcKZgE5tOxneFA",
    // authDomain: "camp-planner-f116c.firebaseapp.com",
    // databaseURL: "https://camp-planner-f116c-default-rtdb.firebaseio.com",
    // projectId: "camp-planner-f116c",
    // storageBucket: "camp-planner-f116c.appspot.com",
    // messagingSenderId: "795146752118",
    // appId: "1:795146752118:web:fe25ba31b73ef0af278f57"
  };

//init firebase
const firebaseApp = initializeApp(firebaseConfig);

//initialize services
const db = getFirestore(firebaseApp);

//Initialize firebase authentication
const auth = getAuth(firebaseApp);

export { db, auth };