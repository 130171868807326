import React, { useState } from 'react'

//Components
import Notes from '../components/Notes'
import Search from '../components/Search'
import DetailedInfo from '../components/DetailedInfo'
import CamperDetailedInfo from '../components/campers/CamperDetailedInfo'
import CamperSearch from '../components/campers/CamperSearch'

export default function Campers({setShowSidebar, activeSchedule, campers, settings, setActivities, setSettings, activities, pullDatabase, singleSchedule, printScheduleLogic, setPrintScheduleLogic}) {

  setShowSidebar(true);

  console.log('campers', campers);
  console.log(activeSchedule)

  const [camperIndex, setCamperIndex] = useState(0);

  //sort campers alphabetically by first_name values
  campers.sort((a, b) => {
    if (a.first_name < b.first_name) {
      return -1;
    }
    if (a.first_name > b.first_name) {
      return 1;
    }
    return 0;
  });

  const camper = campers[camperIndex];

  console.log('camper', camper);
  return (
    <div className='main-content'>
      {campers && activeSchedule ? <CamperSearch activeSchedule={activeSchedule} settings={settings} campers={campers} setCamperIndex={setCamperIndex} camper={camper} activities={activities} pullDatabase={pullDatabase} />: <div>Loading...</div>}
      {campers && activeSchedule && camper && singleSchedule &&<CamperDetailedInfo activeSchedule={activeSchedule} activities={activities} campers={campers} setCamperIndex={setCamperIndex} camper={camper} settings={settings} pullDatabase={pullDatabase} singleSchedule={singleSchedule} printScheduleLogic={printScheduleLogic} setPrintScheduleLogic={setPrintScheduleLogic}/> }
    </div>
  )
}
