import React, { useState, useCallback, useEffect } from 'react';
import filter from '../../assets/images/filter.svg';
import sort from '../../assets/images/sort.svg'
import CounselorSearchInfoCard from './SearchInfoCard';
import { useDropzone } from "react-dropzone";
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../../FirebaseConfig.js';
import { runTransaction, writeBatch, setDoc, doc, collection, getDocs, deleteDoc } from 'firebase/firestore';

export default function CounselorSearch({activeSchedule, counselors, setCounselorIndex, activities, pullDatabase}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("A-Z");
  const [locationFilter, setLocationFilter] = useState(null);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [bulkUploadActive, setBulkUploadActive] = useState(false);
  const [warningsSuccess, setWarningsSuccess] = useState(false);
  const [counselorsUploadedCount, setCounselorsUploadedCount] = useState(0);
  const [warnings, setWarnings] = useState([]);
  const [bulkClearData, setBulkClearData] = useState(false);
  const [progress, setProgress] = useState(0);
  const [sortedCounselors, setSortedCounselors] = useState([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const weekdays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];

  const handleSortChange = () => {
    if (sortOrder === "A-Z") {
      setSortOrder("Z-A");
    } else {
      setSortOrder("A-Z");
    }
  };

  const handleLocationFilterChange = (location) => {
    setLocationFilter(location);
    setFilterMenuOpen(false);
  };

 // Helper function to check if a string pair matches any known activity-role pair
const isActivityAndRole = (fullTerm, doc) => {
  // Replace space with underscore to align with the object keys
  const terms = fullTerm.split(' ').map(term => term.replace(' ', '_'));

  // Start with the longest possible match
  for (let i = terms.length; i >= 1; i--) {
    const activity = terms.slice(0, i).join(' ').toLowerCase();
    const role = terms.slice(i).join(' ').toLowerCase();

    // Convert activity to underscore format to match with object keys
    const activityKey = activity.replace(/ /g, '_');

    if (doc.counselor_skills.hasOwnProperty(activityKey)) {
      if (!role) {
        return true;  // if there is no role specified, and we found an activity, it's a match
      }

      const skillCheck = doc.counselor_skills[activityKey].some(skillObj =>
        skillObj.skill.toLowerCase() === role &&
        skillObj.capability  // make sure the counselor is capable
      );

      if (skillCheck) {
        return true;
      }
    }
  }

  return false;
};

  

  useEffect(() => {
    const filteredCounselors = counselors.filter((doc) => {
      const searchTerms = searchTerm.toLowerCase().split(" ");
      if (isActivityAndRole(searchTerm, doc)) {
        return true;
      }
      for (let i = 0; i < searchTerms.length; i++) {
        const term = searchTerms[i];

        if (
          (doc.first_name && doc.first_name.toLowerCase().includes(term)) ||
          (doc.last_name && doc.last_name.toLowerCase().includes(term)) ||
          (doc.notes && doc.notes.toLowerCase().includes(term)) ||
          (doc.location && doc.location.toLowerCase().includes(term))
        ) {
          continue;
        }
  
        // Additional search functionality
  
        // Check for admin
        if (term.includes("admin") && term.length >= 3 && doc.is_admin) {
          continue;
        }
  
        // Check for CPR
        if (term.includes("cpr") && doc.cpr) {
          continue;
        }

        if (
          Object.keys(doc.counselor_skills || {}).some((skillGroup) =>
            doc.counselor_skills[skillGroup].some((skillObj) => skillObj.skill.toLowerCase().includes(term) && skillObj.capability)
          )
        ) {
          continue;
        }

        return false;
      }
  
      // All filters passed, include this counselor
      return true;
    });
  
    const sorted = filteredCounselors.sort((a, b) => {
      if (!a.first_name || !b.first_name) {
        return 0;
      }
      const firstNameA = a.first_name ? a.first_name.toLowerCase() : "";
      const firstNameB = b.first_name ? b.first_name.toLowerCase() : "";
      const lastNameA = a.last_name ? a.last_name.toLowerCase() : "";
      const lastNameB = b.last_name ? b.last_name.toLowerCase() : "";
  
      if (sortOrder === "A-Z") {
        const firstNameComparison = firstNameA.localeCompare(firstNameB);
        // If first names are equal, sort by last name
        return firstNameComparison === 0 ? lastNameA.localeCompare(lastNameB) : firstNameComparison;
      } else if (sortOrder === "Z-A") {
        const firstNameComparison = firstNameB.localeCompare(firstNameA);
        // If first names are equal, sort by last name but in reverse order
        return firstNameComparison === 0 ? lastNameB.localeCompare(lastNameA) : firstNameComparison;
      }
      return 0; // return 0 if sortOrder is not "A-Z" or "Z-A"
    });
    setSortedCounselors(sorted);
  }, [counselors, sortOrder, searchTerm]); // updated dependency array
  

  const resetBulkStates = () => {
    setFile(null);
    setBulkUploadActive(false);
    setWarningsSuccess(false);
    window.location.reload();
  }

  const handleClearCheck = (event) => {
    setBulkClearData(event.target.checked);
  }

  const clearCampers = async () => {
    const camperRef = collection(db, 'schedules', activeSchedule, 'counselors');
    const batch = writeBatch(db);

    const querySnapshot = await getDocs(camperRef);
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();

    let scheduleByActivityRef = doc(db, 'schedules', activeSchedule, 'schedule', 'schedule_by_activity');
    let counselorListDataRef = doc(db, 'schedules', activeSchedule, 'schedule', 'counselor_list_data');
  
    await deleteDoc(scheduleByActivityRef);
    await deleteDoc(counselorListDataRef);

    setBulkClearData(false);
  };

  const downloadTemplate = () => {
    const data = [
      {
        first_name:"",
        last_name:"",
        is_admin:"",
        cpr:"",
        is_activity_specialist:"",
        notes:""
      }
    ];

    data.forEach((counselor) => {
      weekdays.forEach((day) => {
        counselor[day] = "";
      });
    });

    data.forEach((counselor) => {
      activities.forEach((activity) => {
        counselor[activity.activityId] = "";
      });
    });

    const csv = Papa.unparse(data);

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.download = 'counselor_template.csv';
    link.href = url;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const parseCsvToCounselorData = async (csvFile) => {

    let counselorsArray = [];
    let allWarnings = [];
    let allCampActivities = [];
    const collectionRef = collection(db, 'schedules', activeSchedule, 'campers');
    setWarningsSuccess(true);

    if (bulkClearData) {
      await clearCampers();
    }

    Papa.parse(csvFile, {
      header: true,
      complete: function (results) {
        const headers = results.meta.fields;
        const expectedHeaders = [
          'first_name',
          'last_name',
          'is_admin',
          'cpr',
          'is_activity_specialist',
          'notes',
          ...weekdays,
          ...activities.map(activity => activity.activityId)
        ];
    
        const unexpectedHeaders = headers.filter(header => !expectedHeaders.includes(header));
    
        if (unexpectedHeaders.length > 0) {
          // Find the closest matching expected header for each unexpected header
          const suggestions = unexpectedHeaders.map(unexpectedHeader => {
            const suggestion = expectedHeaders.find(expectedHeader =>
              expectedHeader.startsWith(unexpectedHeader.substring(0, 3))); // adjust this as needed
            return suggestion ? `${unexpectedHeader} (did you mean "${suggestion}"?)` : unexpectedHeader;
          });
    
          // Display an error message and stop execution
          alert('The following CSV headers are unexpected: ' + suggestions.join(', ') + '. Please ensure all headers match the provided template.');
          return;
        }

        const missingHeaders = expectedHeaders.filter(header => !headers.includes(header));

        if (missingHeaders.length > 0) {
          // Display an error message and stop execution
          alert('The following CSV headers are missing: ' + missingHeaders.join(', ') + '. Please ensure all headers match the provided template.');
          return;
        }


        let processedCount = 0;
        const totalCount = results.data.length;

        results.data
        .sort((a, b) => {
          const firstNameA = a.first_name ? a.first_name.toLowerCase() : "";
          const firstNameB = b.first_name ? b.first_name.toLowerCase() : "";

          const lastNameA = a.last_name ? a.last_name.toLowerCase() : "";
          const lastNameB = b.last_name ? b.last_name.toLowerCase() : "";

          const firstNameComparison = firstNameA.localeCompare(firstNameB);
          // If first names are equal, sort by last name
          return firstNameComparison === 0 ? lastNameA.localeCompare(lastNameB) : firstNameComparison;
        })
        .map((row) => {
          let warnings = [];
          let period_warnings = [];
          let required_skill_warnings = [];
          let invalidPeriods = [];

          const kPeriods = ['K1', 'K2', 'K3', 'K4', 'K5'];
          const gPeriods = ['G1', 'G2', 'G3', 'G4', 'G5'];

          const monday = row.monday ? row.monday.split(',').map(item => item.toUpperCase().trim()) : [];
          const tuesday = row.tuesday ? row.tuesday.split(',').map(item => item.toUpperCase().trim()) : [];
          const wednesday = row.wednesday ? row.wednesday.split(',').map(item => item.toUpperCase().trim()) : [];
          const thursday = row.thursday ? row.thursday.split(',').map(item => item.toUpperCase().trim()) : [];
          const friday = row.friday ? row.friday.split(',').map(item => item.toUpperCase().trim()) : [];
          const saturday = row.saturday ? row.saturday.split(',').map(item => item.toUpperCase().trim()) : [];

          const counselor_skills = {};

          //k period error checks
          //loop over monday and remove the periods that are not valid, adding them to the invalidPeriods array and splicing them from the monday array
          monday.forEach((period) => {
            if (!kPeriods.includes(period)) {
              invalidPeriods.push(period);
              const index = monday.indexOf(period);
              if (index > -1) {
                monday.splice(index, 1);
              }
            }
          });
          //loop over monday and remove the periods that are not valid, adding them to the invalidPeriods array and splicing them from the monday array
          wednesday.forEach((period) => {
            if (!kPeriods.includes(period)) {
              invalidPeriods.push(period);
              const index = wednesday.indexOf(period);
              if (index > -1) {
                wednesday.splice(index, 1);
              }
            }
          });
          //loop over monday and remove the periods that are not valid, adding them to the invalidPeriods array and splicing them from the monday array
          friday.forEach((period) => {
            if (!kPeriods.includes(period)) {
              invalidPeriods.push(period);
              const index = friday.indexOf(period);
              if (index > -1) {
                friday.splice(index, 1);
              }
            }
          });

          //g period error checks
          //loop over monday and remove the periods that are not valid, adding them to the invalidPeriods array and splicing them from the monday array
          tuesday.forEach((period) => {
            if (!gPeriods.includes(period)) {
              invalidPeriods.push(period);
              const index = tuesday.indexOf(period);
              if (index > -1) {
                tuesday.splice(index, 1);
              }
            }
          });
          //loop over monday and remove the periods that are not valid, adding them to the invalidPeriods array and splicing them from the monday array
          thursday.forEach((period) => {
            if (!gPeriods.includes(period)) {
              invalidPeriods.push(period);
              const index = thursday.indexOf(period);
              if (index > -1) {
                thursday.splice(index, 1);
              }
            }
          });
          //loop over monday and remove the periods that are not valid, adding them to the invalidPeriods array and splicing them from the monday array
          saturday.forEach((period) => {
            if (!gPeriods.includes(period)) {
              invalidPeriods.push(period);
              const index = saturday.indexOf(period);
              if (index > -1) {
                saturday.splice(index, 1);
              }
            }
          });

          const days = {
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday
          }


          const capitalizeFirstLetters = (string) => {
            //capitlaize the first letter of every word in the string
            return string.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          }

          function convertToBoolean(value, fieldName) {
            const lowercaseValue = value.trim().toLowerCase();
            if (lowercaseValue === 'true') {
              return true;
            } else if (lowercaseValue === 'false') {
              return false;
            } else {
              warnings.push(`input for ${fieldName} is invalid`);
              return undefined;  // Return undefined or any default value you prefer
            }
          }

          activities.forEach((activity) => {
            let activityId = activity.activityId;
            let requiredCounselorSkills = activity.required_counselor_skills;
            const counselorSkills = row[activityId] ? row[activityId].split(',').map((item) => item.trim().toLowerCase()) : []; // Get counselor skills or an empty array if undefined

            counselorSkills.forEach((counselor_skill) => {
              // Check if the skill has a priority prefix
              const isPriority = counselor_skill.charAt(0) === '*';
              // Remove the prefix to get the actual skill
              const skillWithoutPrefix = isPriority ? counselor_skill.slice(1) : counselor_skill;
            
              if (requiredCounselorSkills.some(s => s.skill.toLowerCase() === skillWithoutPrefix)) {
                if(counselor_skills[activityId] && Object.values(counselor_skills[activityId]).length > 0) {
                  counselor_skills[activityId].push({
                    skill: capitalizeFirstLetters(skillWithoutPrefix),
                    capability: true,
                    priority: isPriority
                  });
                } else {
                  counselor_skills[activityId] = [{
                    skill: capitalizeFirstLetters(skillWithoutPrefix),
                    capability: true,
                    priority: isPriority
                  }];
                }
              }
              else {
                required_skill_warnings.push(`${skillWithoutPrefix} is not a required skill for ${activity.name}`);
              }
            });
            

            requiredCounselorSkills.forEach((skill, index) => {
              if(counselor_skills[activityId]) {
                console.log(counselor_skills[activityId]);
              }

              if(counselor_skills[activityId] && !counselor_skills[activityId].some(s => s.skill.toLowerCase() === skill.skill.toLowerCase())) {
                if(counselor_skills[activityId] && Object.values(counselor_skills[activityId]).length > 0) {
                  counselor_skills[activityId].push({
                    skill: skill.skill,
                    capability: false,
                    priority: false
                  });
                } else {
                  counselor_skills[activityId] = [{
                    skill: skill.skill,
                    capability: false,
                    priority: false
                  }];
                }
              }
            });


          });

          // console.log(counselor_skills);
          let counselor = {
            first_name: row.first_name,
            last_name: row.last_name,
            is_admin: convertToBoolean(row.is_admin, 'is_admin'),
            cpr: convertToBoolean(row.cpr, 'cpr'),
            is_activity_specialist: convertToBoolean(row.is_activity_specialist, 'is_activity_specialist'),
            days: days,
            counselor_skills: counselor_skills,
            notes: row.notes,
            counselorId: "counselor_" + uuidv4()
          };

          if (warnings.length === 0) {
            counselorsArray.push(counselor);
          }

          let warningObject = {
            first_name: row.first_name,
            last_name: row.last_name,
            warnings: warnings,
            period_warnings: invalidPeriods,
            required_skill_warnings: required_skill_warnings
          }

          if (warnings.length > 0 || invalidPeriods.length > 0 || required_skill_warnings.length > 0) {
            allWarnings.push(warningObject);
          }

          processedCount += 1;

          if (processedCount % 5 === 0) {
            const progressPercentage = (processedCount / totalCount) * 100;
            setProgress(progressPercentage);
          }

        });

        console.log(counselorsArray);
        console.log(allWarnings);

        if (counselorsArray.length > 0) {
          counselorsArray.forEach((counselor) => {
            let newDocName = counselor.counselorId;
            const newDocNameFormatted = newDocName.replace(/\s+/g, '_').toLowerCase();
            const newDocRef = doc(db, 'schedules', activeSchedule, 'counselors', newDocNameFormatted);
            console.log(newDocNameFormatted);
            console.log(newDocName, counselor);
            setDoc(newDocRef, counselor);
            pullDatabase();
          });
        }

        setWarnings(allWarnings);
        setCounselorsUploadedCount(counselorsArray.length);

        if (allWarnings.length === 0) {
          setUploadSuccess(true);
        }

      }
    });
  };



  return (
    <div className="search-wrapper">
      <input type="text" placeholder="Search" onChange={(e) => setSearchTerm(e.target.value)} />
      <div className="filter-wrapper">
      <div id="bulk-upload" onClick={() => setBulkUploadActive(!bulkUploadActive)}>Bulk Upload</div>
        {bulkUploadActive &&
          <>
            <div onClick={() => resetBulkStates()} id="bulk-upload-background"></div>
            <div id="bulk-upload-window">
              {!warningsSuccess &&
                <>
                  <div className='top-bar'>
                    <h6 className='title'>Bulk Upload</h6>
                    <button onClick={downloadTemplate} className='template'>Download Template</button>
                  </div>
                  <div id="file" {...getRootProps()}>
                    <input {...getInputProps({ multiple: false })} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                    {file && <p id="file-name">Selected file: {file.path}</p>}
                  </div>
                  <div className='bottom-bar'>
                    <input type='checkbox' id='bulk-upload-checkbox' checked={bulkClearData} onChange={handleClearCheck} />
                    <label htmlFor='bulk-upload-checkbox'>Clear data and fresh upload</label>
                  </div>
                  <button className={!file ? 'upload disabled': 'upload'} onClick={() => parseCsvToCounselorData(file)}>Upload Template</button>
                </>
              }
              {warningsSuccess && warnings &&
                <>
                  {!uploadSuccess && <progress value={progress} max="100" />}
                  <h6>{counselorsUploadedCount} Counselors Uploaded</h6>
                  {uploadSuccess && <h6>Upload Successful No Errors or Warnings</h6>}
                  {warnings.map((warning) => (
                    <div key={warning.first_name} className="warning">
                      <h6>{warning.first_name} {warning.last_name}</h6>
                      <ul>
                        {warning.warnings.map((warning, index) => (
                          <li key={index}>{warning}</li>
                        ))}
                        {warning.period_warnings.map((warning, index) => (
                          <li key = {`${warning}_${index}`}>{warning} is not a valid period</li>
                        ))}
                        {warning.required_skill_warnings.map((warning, index) => (
                          <li key = {`${warning}_${index}`}>{warning}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </>
              }
            </div>
          </>
        }
        <div className="filter-icons">
          <div id="sort-by" onClick={handleSortChange}>
            <img src={sort} />
          </div>
          {/* <div id="filter-by">
            <img src={filter} onClick={() => setFilterMenuOpen(!filterMenuOpen)} />
            {filterMenuOpen && (
              <div className="filter-menu">

              </div>
            )}
          </div> */}
        </div>
      </div>
      <div className="search-results">
        {sortedCounselors.map((doc) => {
          const index = counselors.findIndex((counselor) => counselor.counselorId === doc.counselorId);
          return <CounselorSearchInfoCard key={doc.counselorId} index={index} counselor={doc} setCounselorIndex={setCounselorIndex} />;
        })}
      </div>
    </div>
  );
}

