import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';
import { runTransaction, writeBatch, setDoc, doc, collection } from 'firebase/firestore';



export default function SettingsAddActivity({activeSchedule, settings, pullDatabase, activities, campers}) {
    const campersIndex = settings?.findIndex(
        (item) => item.settingId === "campers"
    );

    const [activeKPeriods, setActiveKPeriods] = useState([]);
    const [activeGPeriods, setActiveGPeriods] = useState([]);
    const [timer, setTimer] = useState(null)
    const [newInputValue, setNewInputValue] = useState({});
    const [skills, setSkills] = useState([]);
    const [shareableSkill, setShareableSkill] = useState(false);
    const [activityName, setActivityName] = useState('');
    const [cabinGroups, setCabinGroups] = useState(settings[campersIndex]?.cabin_data || []);

    const activitiesIndex = settings.findIndex((item) => item.settingId === 'activities');
    const settingsData = settings[activitiesIndex];
    const kPeriods = settingsData.k_periods ? settingsData.k_periods : null;
    const gPeriods = settingsData.g_periods ? settingsData.g_periods : null;

    //cabins
    const getAllCabinsNotInUnused = (cabinData) => {
        const cabinsNotInUnused = [];
        Object.keys(cabinData).forEach((group) => {
        if (group !== "unused") {
            cabinData[group].cabins.forEach((cabin) =>
                cabinsNotInUnused.push(cabin)
            );
        }
        });

        cabinsNotInUnused.sort((a, b) => {
        const cabinNumberA = parseInt(a.label.match(/\d+/)[0], 10);
        const cabinNumberB = parseInt(b.label.match(/\d+/)[0], 10);

        return cabinNumberA - cabinNumberB;
        });

        return cabinsNotInUnused;
    };

    const cabinsNotInUnused = getAllCabinsNotInUnused(cabinGroups);

    console.log(cabinsNotInUnused)

    function resetAllValues() {
        document.getElementById('activityName').value = 'enter activity name';
        document.getElementById('notes').value = '';
    }


    const handleActivityClick = async (e, period) => {
        console.log(period);
        let tempKArray = [...activeKPeriods];
        let tempGArray = [...activeGPeriods];

        if (period.startsWith('K')) {
            if (tempKArray.includes(period)) {
                tempKArray = tempKArray.filter((item) => item !== period);
            } else {
                tempKArray.push(period);
            }
        }
        if (period.startsWith('G')){
            if (tempGArray.includes(period)) {
                tempGArray = tempGArray.filter((item) => item !== period);
            } else {
                tempGArray.push(period);
            }
        }

        setActiveKPeriods(tempKArray);
        setActiveGPeriods(tempGArray);
    }

  const handleSkillChange = async (e, activityName) => {
      clearTimeout(timer)

      const newTimer = setTimeout( async () => {
        //   const updatedValue = e.target.value;
        //   const formattedName = activityName.replace(/ /g, "_").toLowerCase();

        //           const tempDoc = doc.data();

        //           const newSkills = {...tempDoc.required_counselor_skills};
        //           newSkills[formattedName] = updatedValue;
        //           tempDoc.required_counselor_skills = newSkills;
        //           console.log(tempDoc)


      }, 1000)

      setTimer(newTimer)
  }

  const handleNewSkill = async (e, shareable) => {
    clearTimeout(timer)
    setNewInputValue({...newInputValue, skill: e.target.value})


    const newTimer = setTimeout(async () => {
      const newSkill = e.target.value;
      const newSkillFormatted = newSkill.replace(/ /g, "_").toLowerCase();
      const updatedSkills = [      ...skills,      {        skill: newSkill,        shareable: shareable      }    ];
      setSkills(updatedSkills);
    }, 1000)

    setTimer(newTimer);
  };




  const removeCounselorSkill = async (e) => {
    const element = e.currentTarget;
    const siblingInputValue = element.previousSibling.value;
    const siblingInputValueFormatted = siblingInputValue.replace(/ /g, "_").toLowerCase();

    //remove the skill from the array from the one that was clicked
    const filteredSkills = skills.filter((skill) => skill.skill !== siblingInputValueFormatted);

    setSkills(filteredSkills); // Update state with the new array


    console.log(filteredSkills);
    console.log(skills);

    // Update state with the new array
    setSkills(filteredSkills);
  };



    async function addActivity() {
        let name = document.getElementById('activityName').value;
        let location = document.getElementById('location').value;
        let maxKids = document.getElementById('maxKids').value;
        let minKids = document.getElementById('minKids').value;
        let notes = document.getElementById('notes').value;
        let data = {
            "activityId": name.replace(/\s+/g, '_').toLowerCase(),
            "name": name,
            "location": location,
            "is_two_periods": false,
            "maxKids": maxKids,
            "minKids": minKids,
            "required_counselor_skills": skills,
            "notes": notes,
            "batch_by": "random",
            "cabin_groups": {
                'group_1': cabinsNotInUnused
            },
            "k_periods": activeKPeriods,
            "g_periods": activeGPeriods,
            "requirements": {
                "swim_test": {
                    "active": false,
                    "name": "Swim Test",
                },
                "waterskiing_test": {
                    "active": false,
                    "name": "Waterskiing Test",
                }
            }
        }
        console.log(data);
        const newDocName = document.getElementById('activityName').value;
        const newDocNameFormatted = newDocName.replace(/\s+/g, '_').toLowerCase();
        const newDocRef = doc(db, 'schedules', activeSchedule, 'activities', newDocNameFormatted);
        console.log(newDocName);
        setDoc(newDocRef, data);
        pullDatabase();
        //resetAllValues();
    }

  return (
    <div className="settings-block add">
        <div className='settings-block-wrapper'>
            <label>Activity Name</label>
            <input
                id="activityName"
                type="text"
                value={activityName || ''}
                onChange={(e) => setActivityName(e.target.value)}
                placeholder="Activity Name"
            />

        </div>
        <div className="detail-info-wrapper">
            <h5>Available Periods</h5>
            <div className="available-periods-wrapper">
                {kPeriods && kPeriods.map((period) => {
                    const isActive = activeKPeriods.includes(period);
                    return (
                        <div
                            key={period}
                            className={`period-wrapper ${isActive ? 'active' : ''}`}
                            onClick={(e) => handleActivityClick(e, period)}
                        >
                            {period}
                        </div>
                    );
                })}

                {gPeriods && gPeriods.map((period, index) => {
                    const isActive = activeGPeriods.includes(period);
                    return (
                        <div
                            key={period}
                            className={`period-wrapper ${isActive ? 'active' : ''}`}
                            onClick={(e) => handleActivityClick(e, period)}
                        >
                            {period}
                        </div>
                    );
                })}
            </div>
        </div>
        <h5>Attributes</h5>
        <div className='settings-block-wrapper'>
            <label>Location</label>
            <input id='location' type="text" placeholder="Location" />
        </div>
        <div className='settings-block-wrapper'>
            <label>Max Campers</label>
            <input id='maxKids' type="number" placeholder="Max Kids" />
        </div>
        <div className='settings-block-wrapper'>
            <label>Min Campers</label>
            <input id='minKids' type="number" placeholder="Min Kids" />
        </div>

        <div className='settings-block-wrapper'>
            <textarea id="notes" placeholder="Notes..." ></textarea>
        </div>

        <div className='activity'>
          <div className="required-skills" >

              {Object.entries(skills).map(([key, value]) => (
                  <div
                      key={`key${Math.random()}`}
                      className={`required-skill `}
                  >
                  {value.shareable && <span className='shareable-skill'>Shareable</span>}
                      <input
                          type="text"
                          className="required-skill-input"
                          //value={(skills[activityName] && skills[activityName][i] && skills[activityName][i].skill) || ""}
                          value={value.skill}
                          onChange={(e) => handleSkillChange(e, activityName)}
                      />
                      <div className="delete-required-skill" onClick={(e) => removeCounselorSkill(e)}>x</div>
                  </div>
              ))}
          </div>
          <div className='add-required-skill-wrapper' >
              <input
                  type='text'
                  className='add-required-skill-input'
                  value={newInputValue[activityName]}
                  placeholder="+ Add Counselor Skill"
                  onChange={(e) => handleNewSkill(e, shareableSkill)}
              />
              <div>
                <label className='is-shared-label'>Role Can Be Split</label>
                <input
                    type='checkbox'
                    className='is-shared-checkbox'
                    checked={shareableSkill}
                    onClick={() => setShareableSkill(!shareableSkill)}
                />
              </div>
          </div>
      </div>

        <button onClick={addActivity} >Add Activity</button>
    </div>
  )
}