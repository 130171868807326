import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection, setDoc } from 'firebase/firestore';
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from '../FirebaseConfig.js';

//import components
import SettingsNumberActivityPreferences from './settings/SettingsNumberActivityPreferences.js';
import SettingsNumberPeriodsOffPerDay from './settings/SettingsNumberPeriodsOffPerDay.js';
import SettingsAvailablePeriods from './settings/SettingsAvailablePeriods.js';
import SettingsTotalCabins from './settings/SettingsTotalCabins.js';
import SettingsCounselorSkills from './settings/SettingsCounselorSkills.js';
import SettingsCampersPerCabin from './settings/SettingsCampersPerCabin.js';
import SettingsAddCamper from './settings/SettingsAddCamper.js';
import SettingsAddCounselor from './settings/SettingsAddCounselor.js';
import SettingsAddActivity from './settings/SettingsAddActivity.js';
import Signup from './Signup.js';



export default function SettingsScreen({activeSchedule, settings, activities, pullDatabase, campers, counselors}) {

    const test = settings;
    const activitiesIndex = settings.findIndex((item) => item.settingId === 'activities');
    const daysIndex = settings.findIndex((item) => item.settingId === 'days');
    const campersIndex = settings.findIndex((item) => item.settingId === 'campers');
    //console.log({campersIndex, activitiesIndex, test});
    //find index of array that h


    const requiredCounselors = settings[activitiesIndex].required_counselors;
    const [timer, setTimer] = useState(null)
    const [newDocName, setNewDocName] = useState("");
    const [activeTab, setActiveTab] = useState("General");
    const [activeAddTab, setActiveAddTab] = useState("Camper");

    const handleAddCounselor = async () => {
        //e,preventDefault();
    }

    const setActivity = async (e) => {
        const newDocName = e.target.value;

        setNewDocName(e.target.value);

        clearTimeout(timer)


        const newTimer = setTimeout( async () => {
            //assign newDocName to a new variable that makes spaces underscores and all lowercase
            const newDocNameFormatted = newDocName.replace(/\s+/g, '_').toLowerCase();

            //console.log(newDocNameFormatted);

            const newDocRef = doc(db, 'schedules', activeSchedule, 'activities', newDocNameFormatted);

            let newDocValues =
            {
                "requirement_checkboxes": {
                },
                "batch_by": [
                    {
                        "random": "Random",
                        "selected": "random",
                        "cabin": "Cabin"
                    }
                ],
                "k_periods": [
                ],
                "notes": "",
                "cabin_groups": {
                },
                "location": "Temp Location",
                "min_kids": "4",
                "max_kids": "12",
                "requirements": {
                    "swim_test": {
                        "name": "Swim Test",
                        "active": false
                    },
                    "waterskiing_test": {
                        "active": false,
                        "name": "Waterskiing Test"
                    }
                },
                "g_periods": [
                ],
                "name": newDocName,
                "activityId": newDocNameFormatted
            }
            setDoc(newDocRef, newDocValues);

            //pullDatabase();
        }, 500)

        setTimer(newTimer)
    }


    const handleDeleteCounselor = async (e) => {
        //e,preventDefault();
    }

  return (

    <div className='settings-wrapper'>
        <h1>General Settings</h1>
        <div id="settings-tabs">
            <div
                className={`tab ${activeTab === "General" ? "active" : ""}`}
                onClick={() => setActiveTab("General")}
            >
                General
            </div>
            <div
                className={`tab ${activeTab === "Activities" ? "active" : ""}`}
                onClick={() => setActiveTab("Activities")}
            >
                Activities
            </div>
            <div
                className={`tab ${activeTab === "Counselors" ? "active" : ""}`}
                onClick={() => setActiveTab("Counselors")}
            >
                Counselors
            </div>
            <div
                className={`tab ${activeTab === "Campers" ? "active" : ""}`}
                onClick={() => setActiveTab("Campers")}
            >
                Campers
            </div>
            <div
                className={`tab ${activeTab === "Add" ? "active" : ""}`}
                onClick={() => setActiveTab("Add")}
            >
                Add
            </div>
            <div
                className={`tab ${activeTab === "Register" ? "active" : ""}`}
                onClick={() => setActiveTab("Register")}
            >
                Register
            </div>
        </div>


        {activeTab === "General" &&
            <SettingsAvailablePeriods activeSchedule={activeSchedule} settings={settings} pullDatabase={pullDatabase} />
        }
        {activeTab === "Activities" && <SettingsCounselorSkills activeSchedule={activeSchedule} settings={settings} activities={activities} pullDatabase={pullDatabase} />}
        {activeTab === "Campers" &&
            <>
                <SettingsNumberActivityPreferences activeSchedule={activeSchedule} settings={settings} pullDatabase={pullDatabase} />
                <SettingsTotalCabins activeSchedule={activeSchedule} settings={settings} pullDatabase={pullDatabase} />
            </>
        }
        {activeTab === "Counselors" && <SettingsNumberPeriodsOffPerDay activeSchedule={activeSchedule} settings={settings} pullDatabase={pullDatabase} />}
        {activeTab === "General" &&
            <SettingsCampersPerCabin activeSchedule={activeSchedule} settings={settings} pullDatabase={pullDatabase} />
        }
        {activeTab === "Add" &&
            <div id="settings-tabs">
                <div
                    className={`tab ${activeAddTab === "Camper" ? "active" : ""}`}
                    onClick={() => setActiveAddTab("Camper")}
                >
                    Camper
                </div>
                <div
                    className={`tab ${activeAddTab === "Counselor" ? "active" : ""}`}
                    onClick={() => setActiveAddTab("Counselor")}
                >
                    Counselor
                </div>
                <div
                    className={`tab ${activeAddTab === "Activity" ? "active" : ""}`}
                    onClick={() => setActiveAddTab("Activity")}
                >
                    Activity
                </div>
            </div>
        }
        {activeTab === "Register" &&
            <div id="settings-block">
                <Signup />
            </div>
        }
        <div className="settings-add">
            {activeTab === "Add" && activeAddTab === "Camper" &&
                <SettingsAddCamper activeSchedule={activeSchedule} activities={activities} settings={settings} pullDatabase={pullDatabase} campers={campers} />
            }
            {activeTab === "Add" && activeAddTab === "Counselor" &&
                <SettingsAddCounselor activeSchedule={activeSchedule} activities={activities} settings={settings} pullDatabase={pullDatabase} counselors={counselors} />
            }
            {activeTab === "Add" && activeAddTab === "Activity" &&
                <SettingsAddActivity activeSchedule={activeSchedule} activities={activities} settings={settings} pullDatabase={pullDatabase} counselors={counselors} />
            }
        </div>
    </div>
  )
}
