import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from '../../FirebaseConfig.js';


//pass activities down from app.js
export default function SettingsCounselorSkills({activeSchedule, settings, activities, pullDatabase}) {

    const [timer, setTimer] = useState(null)
    const [newInputValue, setNewInputValue] = useState({});
    //const [skills, setSkills] = useState({});
    const [shareableSkill, setShareableSkill] = useState(
        activities.reduce((acc, activity) => ({
            ...acc,
            [activity.name]: false
        }), {})
    );

    // useEffect(() => {
    //     const initialSkills = {};
    //     activities.forEach(activity => {
    //       initialSkills[activity.name] = Object.values(activity.required_counselor_skills) || [];
    //     });
    //     setSkills(initialSkills);
    //   }, []);      
      


    const handleSkillChange = async (e, activityName) => {
        clearTimeout(timer)

        // const updatedSkills = { ...skills };
        // updatedSkills[activityName] = updatedSkills[activityName].map(skill =>
        //   skill.skill === e.target.value ? { ...skill, skill: e.target.value } : skill
        // );
        // setSkills(updatedSkills);


        const newTimer = setTimeout( async () => {
            const updatedValue = e.target.value;
            const formattedName = activityName.replace(/ /g, "_").toLowerCase();
            const docRef = doc(db, 'schedules', activeSchedule, 'activities', formattedName);

            try {
                await runTransaction(db, async (transaction) => {
                    const doc = await transaction.get(docRef);
                    if (!doc.exists()) {
                        throw "Document does not exist!";
                    }
                    const tempDoc = doc.data();

                    const newSkills = {...tempDoc.required_counselor_skills};
                    newSkills[formattedName] = updatedValue;
                    tempDoc.required_counselor_skills = newSkills;
                    console.log(tempDoc)
                    //transaction.update(docRef, tempDoc);
                });
                console.log("Transaction successfully committed!");
            } catch (e) {
                console.log("Transaction failed: ", e);
            }

            //pullDatabase();
        }, 1000)

        setTimer(newTimer)
    }

    const handleNewSkill = async (e, activityName, checked) => {

        clearTimeout(timer)
        setNewInputValue({...newInputValue, [activityName]: e.target.value});

        const newTimer = setTimeout( async () => {

            const newSkill = e.target.value;
            const formattedName = activityName.replace(/ /g, "_").toLowerCase();
            const docRef = doc(db, 'schedules', activeSchedule, 'activities', formattedName);



            try {
                await runTransaction(db, async (transaction) => {
                    const doc = await transaction.get(docRef);
                    if (!doc.exists()) {
                        throw "Document does not exist!";
                    }
                    const tempDoc = doc.data();
                    let newCounselorSkill = {
                        skill: newSkill,
                        shareable: checked
                    }
                    tempDoc.required_counselor_skills.push(newCounselorSkill);
                    console.log(tempDoc);

                    transaction.set(docRef, tempDoc);

                });
                console.log("Transaction successfully committed!");
                setNewInputValue({...newInputValue, [activityName]: ""});
            } catch (e) {
                console.log("Transaction failed: ", e);
            }

            pullDatabase();
        }, 1000)

        setTimer(newTimer);
    }

    const removeCounselorSkill = async (e, activityName) => {

        const formattedName = activityName.replace(/ /g, "_").toLowerCase();
        const docRef = doc(db, 'schedules', activeSchedule, 'activities', formattedName);
        const element = e.currentTarget;
        const siblingInputValue = element.previousSibling.value;


            try {
                await runTransaction(db, async (transaction) => {
                    const doc = await transaction.get(docRef);
                    if (!doc.exists()) {
                        throw "Document does not exist!";
                    }
                    const tempDoc = doc.data();
                    let filteredArray = tempDoc.required_counselor_skills.filter(
                        obj => obj.skill !== siblingInputValue
                    );                      
                    tempDoc.required_counselor_skills = filteredArray;
                    console.log(siblingInputValue)
                    console.log(filteredArray);
                    console.log(tempDoc.required_counselor_skills)
                    transaction.update(docRef, tempDoc);
                });
                console.log("Transaction successfully committed!");
            } catch (e) {
                console.log("Transaction failed: ", e);
            }

            pullDatabase();
    }

  return (
    // <div className="settings-block">
    //         <h5>Required Counselor Skills</h5>
    //         <div className="required-skills" >
    //              {counselorElements }
    //             <div className='add-required-skill' onClick={handleAddCounselor}>+ Add Counselor Skill</div>
    //         </div>
    //     </div>

    <div className='settings-block skills'>
            {activities.map((activity, i) => (
                <div className='activity'>
                    <h5 className='activity-name'>{activity.name}</h5>
                    <div className="required-skills" >

                        {activity.required_counselor_skills && Object.entries(activity.required_counselor_skills).map(([key, value]) => (
                            <div
                                key={`key${Math.random()}`}
                                className={`required-skill `}
                            >
                                {value.shareable && <span className='shareable-skill'>Shareable</span>}
                                <input
                                    type="text"
                                    className="required-skill-input"
                                    //value={(skills[activity.name] && skills[activity.name][i] && skills[activity.name][i].skill) || ""}
                                    value={value.skill}
                                    onChange={(e) => handleSkillChange(e, activity.name)}
                                />
                                <div className="delete-required-skill" onClick={(e) => removeCounselorSkill(e, activity.name)}>x</div>
                            </div>
                        ))}
                    </div>
                    <div className='add-required-skill-wrapper' >
                        <input
                            type='text'
                            className='add-required-skill-input'
                            value={newInputValue[activity.name]}
                            placeholder="+ Add Counselor Skill"
                            onChange={(e) => handleNewSkill(e, activity.name, shareableSkill[activity.name])}
                        />
                        <input
                            type='checkbox'
                            className='is-shared-checkbox'
                            checked={shareableSkill[activity.name]}
                            onClick={() => setShareableSkill({...shareableSkill, [activity.name]: !shareableSkill[activity.name]})}
                        />
                        <label className='is-shared-label'>Split Role</label>
                    </div>
                </div>
            ))}
    </div>

  )
}