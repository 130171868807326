import { useState, useEffect, useRef } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from '../FirebaseConfig';
import { set } from "lodash";

//

export default function PrintSchedule({setShowSidebar, printScheduleLogic, campers, counselors, singleSchedule, activities}) {
  setShowSidebar(false);

  console.log({singleSchedule});


  const [camperList, setCamperList] = useState([]);

  const [onlyCounselors, setOnlyCounselors] = useState(printScheduleLogic.onlyCounselors || false);
  const [onlyCampers, setOnlyCampers] = useState(printScheduleLogic.onlyCampers || false);
  const [counselorsByDay, setCounselorsByDay] = useState(printScheduleLogic.counselorsByDay || false);
  const [showAll, setShowAll] = useState(printScheduleLogic.showAll || true);
  const [individualCamperId, setIndividualCamperId] = useState(printScheduleLogic.individualCamperId || '');
  const [individualCounselorId, setIndividualCounselorId] = useState(printScheduleLogic.individualCounselorId || '');
  const [activeCabins, setActiveCabins] = useState(printScheduleLogic.activeCabins || []);
  const [elementCount, setElementCount] = useState(0);
  const [totalCounselorCount, setTotalCounselorCount] = useState(0);
  const [totalCamperCount, setTotalCamperCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [activityRoster, setActivityRoster] = useState(printScheduleLogic.activityRoster || false);
  let totalCount = 0;
  let totalScheduleCount = 0;
  let activitiesIdObjRef = useRef({});
  let currentActivityIdObjRef = activitiesIdObjRef.current;
  let counselorCount = 0;
  let camperCount = 0;
  let scheduleByDay = {};
  let weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  const requiredPeriodsByDay = {
    'monday': ['K1', 'K2', 'K3', 'K4', 'K5'],
    'tuesday': ['G1', 'G2', 'G3', 'G4', 'G5'],
    'wednesday': ['K1', 'K2', 'K3', 'K4', 'K5'],
    'thursday': ['G1', 'G2', 'G3', 'G4', 'G5'],
    'friday': ['K1', 'K2', 'K3', 'K4', 'K5'],
    'saturday': ['G1', 'G2', 'G3', 'G4', 'G5']
  };
  const masterKPeriods = ['K1', 'K2', 'K3', 'K4', 'K5'];
  const masterGPeriods = ['G1', 'G2', 'G3', 'G4', 'G5'];
  const masterNumbers = ['1', '2', '3', '4', '5', '6'];

  const [scheduleByActivity, setScheduleByActivity] = useState(singleSchedule.schedule_by_activity || {});
  const [counselorList, setCounselorList] = useState(singleSchedule.counselor_list_data.counselorListData || []);

  let activityCounter = 0;  // Initialize the counter outside of the main loop
  const totalActivities = Object.entries(scheduleByActivity).length;  // Calculate total activities

  let counter = 1; 
  let nextEvenHeight = null;


  useEffect(() => {
    if(activities) {

      activitiesIdObjRef.current = activities.reduce((acc, activity) => {
        acc[activity.activityId] = activity;
        return acc;
      }, {});

      currentActivityIdObjRef = activitiesIdObjRef.current;
      setIsLoaded(true);
    }

  }, [activities]);

  useEffect(() => {
    if (showAll) {
      setOnlyCounselors(true);
      setOnlyCampers(true);
    }

  }, [showAll]);

  useEffect(() => {
    console.log({onlyCounselors, onlyCampers, showAll});
  }, [onlyCounselors, onlyCampers, showAll]);

  useEffect(() => {
    setOnlyCampers(printScheduleLogic.onlyCampers || false);
    setOnlyCounselors(printScheduleLogic.onlyCounselors || false);
    setShowAll(printScheduleLogic.showAll || true);
    setActivityRoster(printScheduleLogic.activityRoster || false);
  }, [printScheduleLogic]);

  useEffect(() => {
    if (onlyCounselors) {
      console.log(onlyCounselors);
    }
    if (onlyCampers) {
      console.log(onlyCampers);
    }
  }, [onlyCounselors, onlyCampers]);

  useEffect(() => {

    document.querySelectorAll('.print-element').forEach((element) => {
      console.log(element);
      if (element.classList.contains('counselor-wrapper')) {
        totalCounselorCount++;
      } 
      if (element.classList.contains('camper-wrapper')) {
        totalCamperCount++;
      }
    });

    setTotalCamperCount(totalCamperCount);
    setTotalCounselorCount(totalCounselorCount);

    console.log({totalCounselorCount, totalCamperCount, counselorCount, camperCount});

  }, [counselorCount, camperCount]);

  const renderDay = (day, schedule) => {
      // Otherwise, render the day and periods
  
      const masterPeriods = ['monday', 'wednesday', 'friday'].includes(day.toLowerCase()) ? masterKPeriods : masterGPeriods;

      const allPeriodsUnavailable = masterPeriods.every(period => schedule[period] && schedule[period].not_available !== undefined);

      return (
        <div className="day" key={day}>
          <h2>{day}</h2>
          {Object.entries(schedule)
          .sort((a, b) => {
            //sort alphanumerically
            return a[0].localeCompare(b[0], undefined, {numeric: true, sensitivity: 'base'});
          })
          .map(([period, value]) => {
            if(value.period_off !== undefined) {
              let period_off = value.period_off;

              return (
                <h6 test={period} key={period}>
                  <span className="period-off">{period_off}</span>
                </h6>
              );
            }
            else if(value.not_available !== undefined) {
              let period_off = value.not_available;

              return (
                <h6 test={period} key={period}>
                  {allPeriodsUnavailable ? <span className="unavailable">{period} - Day Off</span> : <span className="unavailable">{period} - {period_off}</span>}
                </h6>
              );
            }
            else {
              let activityName = value.activityId;
              let roleName = value.role;

              // Format the activityName and roleName strings
              activityName = formatString(activityName);
              roleName = formatString(roleName);

              return (
                <h6 test={period} key={period}>
                  <span className="activity-name">{activityName}</span> |{" "}
                  <span className="role">{roleName}</span>
                </h6>
              );
            }
          })}
        </div>
      );
  };

  const formatString = (str) => {
      return str
      .replace(/_/g, " ")
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
      })
      .replace(/\s+/g, " ");
  };

  function getDayForPrefix(prefix) {
    if (prefix === 'K') return 'monday';
    if (prefix === 'G') return 'tuesday';
    return null; // Default case, you can handle any other prefixes here if needed
  }


  return (
    <div id="print-schedule">
      <div className="detail-info-wrapper">
        {!counselorsByDay && isLoaded && onlyCounselors && counselorList && (
          <>
            <div className="counselors-wrapper">
              {counselorList &&
                counselorList.map((individualCounselor, index) => {
                  const counselor = counselors.find(
                    (c) => c.counselorId === individualCounselor.counselorId
                  );

                  let renderCounselor = true;

                  if (individualCounselorId) {
                    renderCounselor =
                      counselor.counselorId === individualCounselorId;
                  }

                  if (renderCounselor) {
                    return (
                      counselorCount++,
                      (
                        <div
                          className={`counselor-wrapper print-element ${
                            counselorCount === totalCounselorCount &&
                            totalCounselorCount !== 0
                              ? "last-schedule"
                              : ""
                          } ${(index + 1) % 2 === 0 ? "schedule" : ""}`}
                          key={index}
                        >
                          <h2 className="name">
                            {counselor?.first_name} {counselor?.last_name}
                          </h2>
                          <div className="counselor">
                            <>
                              {renderDay(
                                "Monday",
                                individualCounselor.schedule.monday
                              )}
                              {renderDay(
                                "Tuesday",
                                individualCounselor.schedule.tuesday
                              )}
                              {renderDay(
                                "Wednesday",
                                individualCounselor.schedule.wednesday
                              )}
                              {renderDay(
                                "Thursday",
                                individualCounselor.schedule.thursday
                              )}
                              {renderDay(
                                "Friday",
                                individualCounselor.schedule.friday
                              )}
                              {renderDay(
                                "Saturday",
                                individualCounselor.schedule.saturday
                              )}
                            </>
                          </div>
                        </div>
                      )
                    );
                  }
                })}
            </div>
          </>
        )}

        {!counselorsByDay && isLoaded && onlyCampers && camperList && (
          <>
            <div className="campers-wrapper">
              {campers &&
                campers
                  .sort((a, b) => {
                    const aFirstName = a.first_name.toLowerCase();
                    const bFirstName = b.first_name.toLowerCase();
                    const aLastName = a.last_name.toLowerCase();
                    const bLastName = b.last_name.toLowerCase();

                    const firstNameComparison =
                      aFirstName.localeCompare(bFirstName);
                    return firstNameComparison !== 0
                      ? firstNameComparison
                      : aLastName.localeCompare(bLastName);
                  })
                  .map((camper, index) => {
                    let uniquePeriods = new Set();

                    let gDayActivities = {};
                    let kDayActivities = {};

                    console.log(activeCabins)

                    if (activeCabins.length > 0) {
                      if (!activeCabins.includes(camper.cabin)) {
                        return;
                      }
                    }

                    Object.entries(scheduleByActivity).forEach(
                      ([activity, activities]) => {
                        Object.entries(activities).forEach(
                          ([day, activityData]) => {
                            Object.entries(activityData).forEach(
                              ([period, activityDetails]) => {
                                if (
                                  activityDetails.campers.includes(
                                    camper.camperId
                                  ) &&
                                  !uniquePeriods.has(period)
                                ) {
                                  uniquePeriods.add(period);
                                  const activityInfo = {
                                    id: activity,
                                    period: period,
                                  };

                                  if (period.startsWith("G")) {
                                    gDayActivities[period] = activityInfo;
                                  } else if (period.startsWith("K")) {
                                    kDayActivities[period] = activityInfo;
                                  }
                                }
                              }
                            );
                          }
                        );
                      }
                    );

                    const sortedGDayActivities = Object.entries(
                      gDayActivities
                    ).sort(([period1], [period2]) =>
                      period1.localeCompare(period2)
                    );
                    const sortedKDayActivities = Object.entries(
                      kDayActivities
                    ).sort(([period1], [period2]) =>
                      period1.localeCompare(period2)
                    );

                    let renderCamper = true;

                    if (individualCamperId) {
                      renderCamper = camper.camperId === individualCamperId;
                    }

                    if (renderCamper) {
                      return (
                        camperCount++,
                        (
                          <div
                            className={`camper-wrapper print-element ${
                              camperCount === totalCamperCount &&
                              totalCamperCount !== 0
                                ? "last-schedule"
                                : ""
                            } ${(index + 1) % 3 === 0 ? "schedule" : ""}`}
                            key={index}
                          >
                            <div className="camper">
                              <h2>
                                {camper.first_name} {camper.last_name} -{" "}
                                {camper.cabin}
                              </h2>
                              <h2>K Day</h2>
                              <h2>G Day</h2>
                              <div className="activity-block"></div>
                              <div className="activity-block">
                                <div className="activity-wrapper">
                                  {sortedKDayActivities.map(
                                    ([period, activity]) => (
                                      <h6 key={period}>{period}</h6>
                                    )
                                  )}
                                </div>
                                <div className="activity-wrapper days">
                                  {sortedKDayActivities.map(
                                    ([period, activity]) => (
                                      <h6
                                        data-period={period || "random"}
                                        key={period}
                                      >
                                        {
                                          currentActivityIdObjRef[activity.id]
                                            .name
                                        }
                                        {activity.randomlyAssigned
                                          ? "(random)"
                                          : ""}
                                      </h6>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="activity-block">
                                <div className="activity-wrapper">
                                  {sortedGDayActivities.map(
                                    ([period, activity]) => (
                                      <h6 key={period}>{period}</h6>
                                    )
                                  )}
                                </div>
                                <div className="activity-wrapper days">
                                  {sortedGDayActivities.map(
                                    ([period, activity]) => (
                                      <h6
                                        data-period={period || "random"}
                                        key={period}
                                      >
                                        {
                                          currentActivityIdObjRef[activity.id]
                                            .name
                                        }
                                        {activity.randomlyAssigned
                                          ? "(random)"
                                          : ""}
                                      </h6>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      );
                    }
                  })}
            </div>
          </>
        )}

        {counselorsByDay && (
          <>
            {counselorList &&
              counselorList.map((individualCounselor, index) => {
                Object.entries(individualCounselor.schedule).map(
                  ([day, period]) => {
                    Object.entries(period).map(([p, data]) => {
                      if (!scheduleByDay[day]) {
                        scheduleByDay[day] = {};
                      }
                      if (
                        !scheduleByDay[day][individualCounselor.counselorId]
                      ) {
                        scheduleByDay[day][individualCounselor.counselorId] =
                          {};
                      }
                      if (data.not_available) {
                        scheduleByDay[day][individualCounselor.counselorId][p] =
                          {
                            not_available: data.not_available,
                          };
                      } else if (data.period_off) {
                        scheduleByDay[day][individualCounselor.counselorId][p] =
                          {
                            period_off: data.period_off,
                          };
                      } else {
                        scheduleByDay[day][individualCounselor.counselorId][p] =
                          {
                            activity: data.activityId,
                            role: data.role,
                          };
                      }
                    });
                  }
                );
            })}
            {
              <div className="counselors-by-day-wrapper">
                {scheduleByDay &&
                  Object.entries(scheduleByDay)
                    .sort(([dayA], [dayB]) => {
                      return weekdays.indexOf(dayA) - weekdays.indexOf(dayB);
                    })
                    .map(([day, queriedCounselors], index, array) => {
                      let counselorsByPeriod = { ...queriedCounselors };
                      let isLastSchedule = index === array.length - 1; // check if this is the last schedule
                      let scheduleClass = isLastSchedule ? "schedule last-schedule" : "schedule"; // add 'last-schedule' class if it's the last schedule
                      return (
                        <div className={scheduleClass}>
                          <div className="day">{day}</div>
                          {Object.entries(queriedCounselors)
                            .sort(([counselorIdA], [counselorIdB]) => {
                              const counselorA = counselors.find(
                                (counselor) =>
                                  counselor.counselorId === counselorIdA
                              );
                              const counselorB = counselors.find(
                                (counselor) =>
                                  counselor.counselorId === counselorIdB
                              );
                              const aFirstName =
                                counselorA.first_name.toLowerCase();
                              const bFirstName =
                                counselorB.first_name.toLowerCase();
                              const aLastName =
                                counselorA.last_name.toLowerCase();
                              const bLastName =
                                counselorB.last_name.toLowerCase();

                              const firstNameComparison =
                                aFirstName.localeCompare(bFirstName);
                              return firstNameComparison !== 0
                                ? firstNameComparison
                                : aLastName.localeCompare(bLastName);
                            })
                            .map(([individualCounselor, period]) => {
                              requiredPeriodsByDay[day].forEach((period) => {
                                if (
                                  !scheduleByDay[day][individualCounselor][period]
                                ) {
                                  scheduleByDay[day][individualCounselor][
                                    period
                                  ] = { missing: "missing role" };
                                }
                              });

                              const counselor = counselors.find(
                                (c) => c.counselorId === individualCounselor
                              );
                              return (
                                <div className="counselor-row">
                                  <h6 className="name">
                                    {counselor?.first_name} {counselor?.last_name}
                                  </h6>
                                  {Object.entries(period)
                                    .sort(([periodA], [periodB]) => {
                                      const prefixA = periodA
                                        .charAt(0)
                                        .toUpperCase();
                                      const prefixB = periodB
                                        .charAt(0)
                                        .toUpperCase();
                                      const numA = parseInt(periodA.slice(1), 10);
                                      const numB = parseInt(periodB.slice(1), 10);

                                      if (prefixA !== prefixB) {
                                        return prefixA === "K" ? -1 : 1;
                                      } else {
                                        return numA - numB;
                                      }
                                    })
                                    .map(([p, data]) => {
                                      if (data.not_available) {
                                        return (
                                          <h6 className="period" key={p}>
                                            <span className="unavailable">
                                              {p} - {data.not_available}
                                            </span>
                                          </h6>
                                        );
                                      } else if (data.period_off) {
                                        return (
                                          <h6 className="period" key={p}>
                                            <span className="period-off">
                                              {p} - {data.period_off}
                                            </span>
                                          </h6>
                                        );
                                      } else if (data.missing) {
                                        return (
                                          <h6 className="period" key={p}>
                                            <span className="missing">
                                              {p} - {data.missing}
                                            </span>
                                          </h6>
                                        );
                                      } else {
                                        let activityName = data.activity;
                                        let roleName = data.role;

                                        // Format the activityName and roleName strings
                                        activityName = formatString(activityName);
                                        roleName = formatString(roleName);

                                        return (
                                          <h6 className="period" key={p}>
                                            <span className="activity-name">
                                              {p} - {activityName}
                                            </span>{" "}
                                            |{" "}
                                            <span className="role">
                                              {roleName}
                                            </span>
                                          </h6>
                                        );
                                      }
                                    })}
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
              </div>
            }
          </>
        )}

        {activityRoster &&
          scheduleByActivity &&
          isLoaded &&
          Object.entries(scheduleByActivity)
          .sort (([activityIdA], [activityIdB]) => {
            const activityA = activities.find((activity) => activity.activityId === activityIdA);
            const activityB = activities.find((activity) => activity.activityId === activityIdB);
            const aName = activityA.name.toLowerCase();
            const bName = activityB.name.toLowerCase();

            return aName.localeCompare(bName);
          })
          .map(([activityId, activity]) => {
            let renderedPeriods = new Set(); // Create a set to store periods we've rendered
            let totalCampers = 0; // Initialize total campers count for each activity-roster-wrapper
            activityCounter++; // Increment the counter
            console.log({activity});

            const kDays = ['monday', 'wednesday', 'friday'];
            const gDays = ['tuesday', 'thursday', 'saturday'];


            return Object.entries(activity)
              .sort(([dayA], [dayB]) => {
                const dayAIsK = kDays.includes(dayA);
                const dayBIsK = kDays.includes(dayB);

                // If both days are 'K' days or both are 'G' days, sort by the day order.
                if (dayAIsK === dayBIsK) {
                    const allDays = dayAIsK ? kDays : gDays;
                    return allDays.indexOf(dayA) - allDays.indexOf(dayB);
                }
                
                // If dayA is a 'K' day and dayB is a 'G' day, dayA should come first.
                return dayAIsK ? -1 : 1;
              })
              .flatMap(([day, period]) => {
                let schedulePeriod = null;

                let sortedPeriods = Object.entries(period)
                  .sort(([periodA], [periodB]) => {
                    const prefixA = periodA.charAt(0).toUpperCase();
                    const prefixB = periodB.charAt(0).toUpperCase();
                    const numA = parseInt(periodA.slice(1), 10);
                    const numB = parseInt(periodB.slice(1), 10);

                    if (prefixA !== prefixB) {
                      return prefixA === "K" ? -1 : 1;
                    } else {
                      return numA - numB;
                    }
                  });

                let totalPeriods = sortedPeriods.length;
                let mapped = [];
                let clearfix = false;
                let previousShouldSchedule = false;
                for(let i = 0; i < sortedPeriods.length; i++) {
                  let [p, data] = sortedPeriods[i];
                  if (!renderedPeriods.has(p)) {
                    if (data.campers.length === 0) {  
                      continue;
                    }
                    
                    let numberOfRows = data.campers.length; 
                    let prefix = Object.keys(period)[0].charAt(0).toUpperCase();

                    if (getDayForPrefix(prefix) !== day) return [];

                    clearfix = false;
                    if (previousShouldSchedule) {
                      clearfix = true;
                    }
                    let isLastPeriod = i === totalPeriods - 1;
                    let nextCampersLength = i < sortedPeriods.length - 1 ? sortedPeriods[i+1][1].campers.length : 0;
                    let shouldSchedule = totalCampers + data.campers.length + nextCampersLength >= 25 || isLastPeriod;
                    totalCampers += data.campers.length + nextCampersLength;
                    previousShouldSchedule = shouldSchedule;
                    if (shouldSchedule) {
                      totalCampers = 0;
                    }

                    let classNames = ['activity-roster-wrapper'];
                    let resetCounter = false;

                    if (shouldSchedule) {
                      classNames.push('schedule');
                      if (counter % 2 === 0) { // If the counter indicates 'even'
                        resetCounter = true;
                      }
                    }

                    if (counter % 2 === 1) {
                      classNames.push('odd');
                      nextEvenHeight = numberOfRows * 30;
                    } else {
                      classNames.push('even');
                    }

                    // Only reset the counter if the flag is set (which means the last one was 'even')
                    if (resetCounter) {
                      counter = 2;
                    } else {
                      counter++; // Increment the counter for the next iteration
                    }

                    console.log({activityId, shouldSchedule, totalCampers, activityCounter, totalActivities});


                    mapped.push(
                      <div
                        className={classNames.join(' ')}
                        style={(counter % 2 === 1 && nextEvenHeight) ? { minHeight: `${nextEvenHeight}px` } : {}}
                        key={`${activityId}-${day}-${p}`}
                        data-test={totalCampers}
                      >
                        <div className="top-row">
                          <h6 className="activity">
                            {currentActivityIdObjRef[activityId].name}
                          </h6>
                          <h6 className="period">{p}</h6>
                        </div>
                        <div className="period-wrapper">
                          <h6></h6>
                          {masterNumbers.map((period) => (
                            <h6 key={period}>{period}</h6>
                          ))}
                        </div>
                        <div className="activity-roster">
                          {data.campers
                          .sort((a, b) => {
                            const camperA = campers.find((camper) => camper.camperId === a);
                            const camperB = campers.find((camper) => camper.camperId === b);
                            const aFirstName = camperA.first_name.toLowerCase();
                            const bFirstName = camperB.first_name.toLowerCase();
                            const aLastName = camperA.last_name.toLowerCase();
                            const bLastName = camperB.last_name.toLowerCase();

                            const firstNameComparison = aFirstName.localeCompare(bFirstName);
                            return firstNameComparison !== 0 ? firstNameComparison : aLastName.localeCompare(bLastName);
                          })
                          .map((camperId) => {
                            const camper = campers.find(
                              (c) => c.camperId === camperId
                            );
                            return (
                              <>
                                <div className="camper-row" key={camperId}>
                                  <div className="camper-column">
                                    <h6>
                                      {camper.first_name} {camper.last_name}
                                    </h6>
                                  </div>
                                  {[...Array(6)].map((_, index) => (
                                    <div className="empty-column" key={index}></div>
                                  ))}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                }
                return mapped;
              });
          })}




      </div>
    </div>
  );
}
