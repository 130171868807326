import React from 'react'
import { useCollection } from '../hooks/useCollection'

export default function Periods() {
    const {error, documents } = useCollection('periods');
    const dynamic_period = 2;
    const period_collections = ['k_periods', 'g_periods'];
  return (
    <div>
        {error && <div>{error}</div>}
        {documents && documents.map(period => (
            <div key={period.id}>
                {
                    //for each period_collections, print out the period with that period_collection as the key
                    period_collections.map(period_collection => (
                        <div key={period_collection}>
                            {period[period_collection]}
                        </div>
                    ))
                }
            </div>
        ))}
    </div>
  )
}
