import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { getDoc, doc, deleteDoc, collection } from 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';

//Styles & Assets
import arrow from '../../assets/images/arrow.svg'
import Counselors from '../../pages/Counselors.js';

//Components
import DetailedInfoPeriods from '../DetailedInfoPeriods.js';
import CamperNotes from './CamperNotes.js';
import CamperActivityPreferences from './CamperActivityPreferences.js';
import CamperName from './CamperName.js';
import CamperSkills from './CamperSkills.js';
import IndividualSchedule from '../IndividualSchedule.js';

export default function CamperDetailedInfo({activeSchedule, camper, settings, campers, pullDatabase, setCamperIndex, activities, singleSchedule, printScheduleLogic, setPrintScheduleLogic}) {

const camperIndex = campers.findIndex((camp) => camp.camperId === camper.camperId) + 1;
const prevCamperIndex =  (camperIndex > 1) ? camperIndex - 2 : 0;

    async function deleteCamper() {
        const camperRef = doc(db, "schedules", activeSchedule, "campers", camper.camperId);
        await deleteDoc(camperRef);
        setCamperIndex(0)
        pullDatabase();
    }

  return (
    <div className='detailed-info'>
        {camper &&
            (
            <>
                <div className="nav">
                    <div className="info-count">
                        <div className="info-count-numbers">
                            <h6><span className="current-number">{camperIndex}</span>/<span className="total-number">{campers.length}</span></h6>
                            <div className="info-arrows">
                                <img
                                    className={`arrow-left ${camperIndex > 1 ? "" : "first"}`}
                                    src={arrow}
                                    onClick={() => setCamperIndex(prevCamperIndex)}
                                    alt="arrow"
                                />
                                <img
                                    className="arrow-right"
                                    src={arrow}
                                    onClick={() => setCamperIndex(camperIndex)}
                                    alt="arrow"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-wrapper">
                        <CamperName activeSchedule={activeSchedule} settings={settings} camper={camper} pullDatabase={pullDatabase} />
                        <IndividualSchedule activities={activities} activeSchedule={activeSchedule} camper={camper} singleSchedule={singleSchedule} printScheduleLogic={printScheduleLogic} setPrintScheduleLogic={setPrintScheduleLogic}/>
                        <CamperSkills activeSchedule={activeSchedule} camper={camper} pullDatabase={pullDatabase} />
                        <div className="detail-info-wrapper">
                            <h5>Preferences</h5>
                            <CamperActivityPreferences activeSchedule={activeSchedule} pullDatabase={pullDatabase} camper={camper} settings={settings} activities={activities} />
                        </div>
                    <CamperNotes activeSchedule={activeSchedule} camper={camper} settings={settings} campers={campers} pullDatabase={pullDatabase}/>
                </div>
                <button className="delete" onClick={() => deleteCamper()}>Delete</button>
                </>
            )
        }
    </div>
  )
}