import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';
import { runTransaction, deleteDoc, setDoc, doc, collection } from 'firebase/firestore';

export default function CamperSearchInfoCard({camper, index, setCamperIndex}) {

  async function deleteCard() {
    const camperRef = doc(db, 'campers', camper.camperId);
    await deleteDoc(camperRef);
  }

  return (
    <div className='search-card' onClick={() => setCamperIndex(index)}>
        <div className="name-info-wrapper">
            <div className="name-wrapper">
                <h6 className="name">{camper.first_name} {camper.last_name}</h6>
            </div>
            <div className="location-wrapper">
                <h6 className="location">{camper.cabin}</h6>
            </div> 
        </div>
        <p className="notes">{camper.notes}</p>
    </div>
  )
}