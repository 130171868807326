import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from '../../FirebaseConfig.js';



export default function CamperNotes({activeSchedule, camper, pullDatabase}) {

    const [notes, setNotes] = useState(camper.notes || "");
    const [timer, setTimer] = useState(null)


    useEffect(() => {
      setNotes(camper.notes || "");
    }, [camper]);

    const handleChange = async (e) => {
        //e,preventDefault();
        let newNote = e.target.value;
        setNotes(newNote);

        clearTimeout(timer)

        const camperDoc = camper.camperId;
        const docRef = doc(db, 'schedules', activeSchedule, 'campers', camperDoc);
        const newTimer = setTimeout( async () => {
            try {
                await runTransaction(db, async (transaction) => {
                    const doc = await transaction.get(docRef);
                    if (!doc.exists()) {
                        throw "Document does not exist!";
                    }
                    const tempDoc = doc.data();
                    tempDoc.notes = newNote;
                    console.log(tempDoc);

                    transaction.set(docRef, tempDoc);

                });
                console.log("Transaction successfully committed!");
            } catch (e) {
                console.log("Transaction failed: ", e);
            }

            pullDatabase();
        }, 1000)

        setTimer(newTimer)
    }

  return (
    <textarea className="notes" placeholder="Notes..." onChange={handleChange} value={notes} ></textarea>
  )
}