import React, { useState, useEffect } from "react";

//Styles & Assets
import "../assets/style.css";
import filter from "../assets/images/filter.svg";
import sort from "../assets/images/sort.svg";
import SearchInfoCard from "./SearchInfoCard";

export default function Search({activeSchedule, activities, setActivityIndex }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [filteredSortedActivities, setFileredSortedActivities] = useState(activities);

  useEffect(() => {
    // Split the search term into words
    if (activities !== null || activities !== undefined) {
      const searchTerms = searchTerm.toLowerCase().split(" ");

      const filteredAndSortedActivities = activities
      .filter((activity) => {
        // Check if each word in the search terms is included in the name or notes
        return searchTerms.every((term) => {
          if (typeof term === 'string') {
            const name = activity.name;
            const notes = activity.notes;
            if (typeof name === 'string' && typeof notes === 'string') {
              const lowerTerm = term.toLowerCase();
              return (
                name.toLowerCase().includes(lowerTerm) ||
                notes.toLowerCase().includes(lowerTerm)
              );
            }
          }
          return false;
        });
      })
      
        .sort((a, b) => {
          if (!a.name || !b.name) return false;

          // Sort by name
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (sortType === "asc") {
            return nameA.localeCompare(nameB);
          } else {
            return nameB.localeCompare(nameA);
          }
        });

      setFileredSortedActivities(filteredAndSortedActivities);
    }
  }, [searchTerm, sortType, activities]);

  return (
    <div className="search-wrapper">
      <input
        type="text"
        placeholder="Search"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="filter-wrapper">
        <div id="bulk-upload">Bulk Upload</div>
        <div className="filter-icons">
          <div id="sort-by" onClick={() => setSortType(sortType === "asc" ? "desc" : "asc")}>
            <img src={sort} />
          </div>
        </div>
      </div>
      <div className="search-results">
        {filteredSortedActivities.map((doc) => {
          const index = activities.findIndex((a) => a.activityId === doc.activityId);
          return (
            <SearchInfoCard
              key={doc.activityId}
              index={index}
              activity={doc}
              setActivityIndex={setActivityIndex}
            />
          )
        })}
      </div>
    </div>
  );
}
{/* <div id="filter-by">
            <img
              src={filter}
              onClick={() => setFilterMenuOpen(!filterMenuOpen)}
            />
            {filterMenuOpen && (
              <div className="filter-menu">
                <div className="batch-by">
                  <div>Batch by:</div>
                  <div>
                    <input
                      type="checkbox"
                      id="random"
                      name="batch"
                      value="random"
                    />
                    <label htmlFor="random">Random</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="cabin"
                      name="batch"
                      value="cabin"
                    />
                    <label htmlFor="cabin">Cabin</label>
                  </div>
                </div>
                <div className="periods">
                  <div>Periods:</div>
                  <div>
                    <input type="checkbox" id="k1" name="periods" value="k1" />
                    <label htmlFor="k1">K1</label>
                  </div>
                  <div>
                    <input type="checkbox" id="k2" name="periods" value="k2" />
                    <label htmlFor="k2">K2</label>
                  </div>
                  <div>
                    <input type="checkbox" id="k3" name="periods" value="k3" />
                    <label htmlFor="k3">K3</label>
                  </div>
                  <div>
                    <input type="checkbox" id="k4" name="periods" value="k4" />
                    <label htmlFor="k4">K4</label>
                  </div>
                  <div>
                    <input type="checkbox" id="k5" name="periods" value="k5" />
                    <label htmlFor="k5">K5</label>
                  </div>
                  <div>
                    <input type="checkbox" id="g1" name="periods" value="g1" />
                    <label htmlFor="g1">G1</label>
                  </div>
                  <div>
                    <input type="checkbox" id="g2" name="periods" value="g2" />
                    <label htmlFor="g2">G2</label>
                  </div>
                  <div>
                    <input type="checkbox" id="g3" name="periods" value="g3" />
                    <label htmlFor="g3">G3</label>
                  </div>
                  <div>
                    <input type="checkbox" id="g4" name="periods" value="g4" />
                    <label htmlFor="g4">G4</label>
                  </div>
                  <div>
                    <input type="checkbox" id="g5" name="periods" value="g5" />
                    <label htmlFor="g5">G5</label>
                  </div>
                </div>
              </div>
            )}
          </div> */}