import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from '../../FirebaseConfig.js';



export default function ActivityRequirements({activeSchedule, activity, pullDatabase, location}) {

    const requirements = activity.requirements;
    // console.log(requirements);

    const handleClick = async (requirement, key) => {

        let activeState = !requirements[key].active;
        let name = requirements[key].name;

        requirements[key].active = activeState;
        requirements[key].name = name;

        const activityDoc = activity.activityId;
        const docRef = doc(db, 'schedules', activeSchedule, 'activities', activityDoc);

        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                tempDoc.requirements[key] = requirements[key];
                console.log(tempDoc.requirements[key]);
                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

  return (
    <div className="attribute-wrapper requirements">
        <h6 className="attribute-type">Requirements:</h6>
        {
            <div className='requirement-buttons-wrapper'>
                {Object.keys(requirements).map((key) => {
                    const requirement = requirements[key];
                    return (
                    <h6 className="attribute-values" key={key}>
                        <div
                            key={requirement.id}
                            className={`attribute-value-btn ${requirement.active ? 'active' : ''}`}
                            onClick={() => handleClick(requirement, key)}
                            >
                            {requirement.name}
                        </div>
                    </h6>
                    )
                })}
            </div>


        }
    </div>
  )
}