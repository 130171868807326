import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';



export default function CounselorAvailablePeriods({activeSchedule, counselor, counselors, settings, pullDatabase}) {


    let counselorDays = counselor.days;

    console.log(counselorDays);

    const dayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    
    const activitiesIndex = settings.findIndex((item) => item.settingId === 'activities');

    console.log(settings[activitiesIndex].days)

    const changePeriod = async (e, day) => {
        console.log(day);

        const element = e.currentTarget;
        const period = e.currentTarget.querySelector('.period').textContent;

        console.log(period);

        const counselorDoc = counselor.counselorId;

        const docRef = doc(db, 'schedules', activeSchedule, 'counselors', counselorDoc);
        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                let tempArray = [...tempDoc.days[day]];
                const dayIndex = tempArray.indexOf(period);
                if (dayIndex > -1) {
                    tempArray.splice(dayIndex, 1);
                    element.classList.remove('active');
                } else {
                    tempArray.push(period);
                    element.classList.add('active');
                }

                console.log(tempArray);

                tempDoc.days[day] = tempArray;

                console.log(tempDoc);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

    const handleSelectAll = async (e, day, periods) => {
        console.log(day);
    
        console.log(periods);
    
        const counselorDoc = counselor.counselorId;
    
        const element = e.currentTarget;
        let removeAll = false;
        if (e.currentTarget.classList.contains('active')) {
            removeAll = true;
        }
    
        const docRef = doc(db, 'schedules', activeSchedule, 'counselors', counselorDoc);
        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                if (removeAll) {
                    tempDoc.days[day] = [];
                } else {
                    tempDoc.days[day] = periods;
                }
                console.log(tempDoc);
    
                transaction.set(docRef, tempDoc);
    
            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    
        pullDatabase();
    }

  return (
    <div className="settings-block days">
        <h5>Available Periods</h5>
        { counselors.length > 0 &&
            Object.entries(settings[activitiesIndex].days)
                .sort((a, b) => dayOrder.indexOf(a[0]) - dayOrder.indexOf(b[0]))
                .map(([day, periods], dayIndex) => (
                    <div key={dayIndex} className="day-wrapper">
                        <h6 className="day-header">
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                            <span
                                className={`select-all ${counselorDays[day]?.length > 0 && counselorDays[day].sort().toString() === periods.sort().toString() ? 'active' : ''}`}
                                onClick={(e) => handleSelectAll(e, day, periods)}
                            >
                                {counselorDays[day]?.length > 0 && counselorDays[day].sort().toString() === periods.sort().toString() ? 'De-select All' : 'Select All'}
                            </span>
        
                        </h6>
                        <div className='periods-wrapper'>
                            {periods.map((period, periodIndex) => (
                                <div key={periodIndex} className={`period-wrapper ${counselorDays[day] && counselorDays[day].includes(period) ? 'active' : ''}`}
                                    onClick={(e) => changePeriod(e, day)}>
                                    <span className='period'>{period}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            }

    </div>
  )
}