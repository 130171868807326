import React, { useState, useEffect, useCallback } from 'react';
import filter from '../../assets/images/filter.svg';
import sort from '../../assets/images/sort.svg'
import CamperSearchInfoCard from './CamperSearchInfoCard';
import { useDropzone } from "react-dropzone";
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';
import 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';
import { runTransaction, writeBatch, setDoc, doc, collection, getDocs, deleteDoc } from 'firebase/firestore';


export default function CamperSearch({activeSchedule, campers, setCamperIndex, settings, activities, pullDatabase}) {

  console.log('campers', campers);

  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("A-Z");
  const [ageGroupFilter, setAgeGroupFilter] = useState(null);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [filterType, setFilterType] = useState("");
  const [file, setFile] = useState(null);
  const [bulkUploadActive, setBulkUploadActive] = useState(false);
  const [warningsSuccess, setWarningsSuccess] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [campersUploadedCount, setCampersUploadedCount] = useState(0);
  const [bulkClearData, setBulkClearData] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const [filteredCampers, setFilteredCampers] = useState(campers);
  const [sortedCampers, setSortedCampers] = useState(campers);

  const campersIndex = settings.findIndex((item) => item.settingId === "campers");

  const [cabinGroups, setCabinGroups] = useState(settings[campersIndex].cabin_data || {
    unused: {
      name: "Unused",
      cabins: [
        { value: "cabin_1", label: "Cabin 1" },
        { value: "cabin_2", label: "Cabin 2" },
        { value: "cabin_3", label: "Cabin 3" },
        { value: "cabin_4", label: "Cabin 4" },
        { value: "cabin_5", label: "Cabin 5" },
        { value: "cabin_6", label: "Cabin 6" },
        { value: "cabin_7", label: "Cabin 7" },
        { value: "cabin_8", label: "Cabin 8" },
        { value: "cabin_9", label: "Cabin 9" },
        { value: "cabin_10", label: "Cabin 10" },
      ],
    },
    dudes: {
      name: "Dudes",
      cabins: [],
    },
    pioneers: {
      name: "Pioneers",
      cabins: [],
    },
    ranchers: {
      name: "Ranchers",
      cabins: [],
    },
  });

  const [cabinFilters, setCabinFilters] = useState(() => {
    const filters = {};
    Object.entries(cabinGroups).forEach(([groupName, group]) => {
      if (group.cabins) {
        filters[groupName] = group.cabins.map((cabin) => ({
          value: cabin.value,
          label: cabin.label,
          isSelected: false,
        }));
      }
    });
    console.log(filters);
    return filters;
  });

  const handleAgeGroupFilterChange = (group) => {
    setAgeGroupFilter(group);
    setFilterMenuOpen(false);
  };


  const handleSortChange = () => {
    if (sortOrder === "A-Z") {
      setSortOrder("Z-A");
    } else {
      setSortOrder("A-Z");
    }
  };

  const resetBulkStates = () => {
    setFile(null);
    setBulkUploadActive(false);
    setWarningsSuccess(false);
    setWarnings([]);
    setCampersUploadedCount(0);
    window.location.reload();
  };

  const handleCabinFilterChange = (groupName, cabinValue, isSelected) => {

    console.log(cabinFilters);

    setCabinFilters((prevFilters) => {
      const filters = { ...prevFilters };
      const cabinFilter = filters[groupName].find(f => f.value === cabinValue);
      if (cabinFilter) {
        cabinFilter.isSelected = isSelected;
      }
      return filters;
    });

    console.log(cabinFilters);



    // Call the camperFilter function to filter the campers
    const filtered = camperFilter(campers);

    // Call the sortCampers function to sort the filtered campers
    const sorted = sortCampers(filtered);

    // Set the state with the sorted campers
    setSortedCampers(sorted);
  };

  const handleClearCheck = (event) => {
    setBulkClearData(event.target.checked);
  }

  const clearCampers = async () => {
    const camperRef = collection(db, 'schedules', activeSchedule, 'campers');
    const batch = writeBatch(db);

    const querySnapshot = await getDocs(camperRef);
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();

    let scheduleByActivityRef = doc(db, 'schedules', activeSchedule, 'schedule', 'schedule_by_activity');
    let counselorListDataRef = doc(db, 'schedules', activeSchedule, 'schedule', 'counselor_list_data');
  
    await deleteDoc(scheduleByActivityRef);
    await deleteDoc(counselorListDataRef);

    setBulkClearData(false);
  };


  function camperFilter(campers) {
    const filtered = campers.filter((doc) => {
      // Filter by cabin
      const cabinFiltersSelected = Object.entries(cabinFilters)
        .flatMap(([groupName, filters]) => filters)
        .filter((filter) => filter.isSelected);
      if (
        cabinFiltersSelected.length > 0 &&
        !cabinFiltersSelected.some((filter) => filter.label === doc.cabin)
      ) {
        return false;
      }

      // Filter by age group
      if (ageGroupFilter && doc.age_group && doc.age_group.toLowerCase() !== ageGroupFilter) {
        return false;
      }

      // Filter by search term
      const searchTerms = searchTerm.toLowerCase().split(" ");
      for (const term of searchTerms) {
        if (
          !(
            (doc.first_name && doc.first_name.toLowerCase().includes(term)) ||
            (doc.last_name && doc.last_name.toLowerCase().includes(term)) ||
            (doc.notes && doc.notes.toLowerCase().includes(term)) ||
            (doc.age_group && doc.age_group.toLowerCase().includes(term)) ||
            (doc.cabin && doc.cabin.toLowerCase().includes(term))
          )
        ) {
          return false;
        }
      }

      return true;
    });

    setFilteredCampers(filtered);
    return filtered;
  }

  function sortCampers(filteredCampers) {
    const sorted = [...filteredCampers].sort((a, b) => {
      const nameA = a.name ? a.name.toLowerCase() : "";
      const nameB = b.name ? b.name.toLowerCase() : "";
      if (sortOrder === "A-Z") {
        return nameA.localeCompare(nameB);
      } else if (sortOrder === "Z-A") {
        return nameB.localeCompare(nameA);
      }
    });

    return sorted;
  }

  useEffect(() => {
    if (campers) {
      const filtered = camperFilter(campers);
      if (filtered) {
        const sorted = sortCampers(filtered);
        setSortedCampers(sorted);
      }
    }
    console.log({ sortedCampers, filteredCampers });
  }, [cabinFilters, ageGroupFilter, searchTerm, sortOrder, campers]);

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const downloadTemplate = () => {
    const data = [
      {
        first_name: "",
        last_name: "",
        cabin: "",
        age_group: "",
        camping_skills_data: "",
        swim_test_data: "",
        waterskiing_test_data: "",
        tennis_academy_data: "",
        activity_preferences: "",
        notes: ""
      },
    ];

    const csv = Papa.unparse(data);

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.download = 'camper_template.csv';
    link.href = url;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const parseCsvToCamperData = async (csvFile) => {
    let campersArray = [];
    let allWarnings = [];
    let allCampActivities = [];
    const collectionRef = collection(db, 'schedules', activeSchedule, 'campers');

    setWarningsSuccess(true);

    activities.forEach((camp_activities) => {
      allCampActivities.push(camp_activities.name);
    });

    if (bulkClearData) {
      await clearCampers();
    }

    Papa.parse(csvFile, {
      header: true,
      complete: async function (results) {

        const headers = results.meta.fields;
        const expectedHeaders = [
          'first_name',
          'last_name',
          'cabin',
          'age_group',
          'camping_skills_data',
          'swim_test_data',
          'waterskiing_test_data',
          'tennis_academy_data',
          'activity_preferences',
          'notes'
        ];
    
        const unexpectedHeaders = headers.filter(header => !expectedHeaders.includes(header));
    
        if (unexpectedHeaders.length > 0) {
          // Find the closest matching expected header for each unexpected header
          const suggestions = unexpectedHeaders.map(unexpectedHeader => {
            const suggestion = expectedHeaders.find(expectedHeader =>
              expectedHeader.startsWith(unexpectedHeader.substring(0, 3))); // adjust this as needed
            return suggestion ? `${unexpectedHeader} (did you mean "${suggestion}"?)` : unexpectedHeader;
          });
    
          // Display an error message and stop execution
          alert('The following CSV headers are unexpected: ' + suggestions.join(', ') + '. Please ensure all headers match the provided template.');
          return;
        }

        const missingHeaders = expectedHeaders.filter(header => !headers.includes(header));

        if (missingHeaders.length > 0) {
          // Display an error message and stop execution
          alert('The following CSV headers are missing: ' + missingHeaders.join(', ') + '. Please ensure all headers match the provided template.');
          return;
        }

        let processedCount = 0;
        const totalCount = results.data.length;

        const camperData = results.data.map((row) => {
          let warnings = [];
          let activity_warnings = [];
          let indecesToSplice = [];

          const activity_preferences = row.activity_preferences.split(',').filter(activity => activity.trim() !== "").map((activity) => {
            const trimmedActivity = activity.trim();
            const isPriority = trimmedActivity.startsWith("*");
            const cleanedActivity = isPriority ? trimmedActivity.slice(1).trim() : trimmedActivity;
          
            return {
              priority: isPriority,
              activity: cleanedActivity,
            };
          });
          
          activity_preferences.forEach((preferred_activity) => {
            if (!allCampActivities.includes(preferred_activity.activity)) {
              indecesToSplice.push(activity_preferences.indexOf(preferred_activity));
              activity_warnings.push(`${preferred_activity.activity} is not a camp activity`);
            }
          });
          
          indecesToSplice.sort((a, b) => b - a);
          
          // Iterate over the indicesToRemove array
          for (const index of indecesToSplice) {
            if (index >= 0 && index < activity_preferences.length) {
              activity_preferences.splice(index, 1); // Remove the element at the specified index
            }
          }
          

          const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
          }

          let camper = {
            first_name: row.first_name,
            last_name: row.last_name,
            cabin: typeof parseInt(row.cabin) === 'number' && !isNaN(parseInt(row.cabin)) ? 'Cabin ' + row.cabin : warnings.push("input for cabin is invalid"),
            age_group: ["pioneer", "dude", "rancher"].includes(row.age_group.toLowerCase()) ? capitalizeFirstLetter(row.age_group) : warnings.push("age group does not match"),
            camping_skills_data: {
              camping_skills_placeholder: row?.camping_skills_data.toLowerCase() === "yes" || row?.camping_skills_data?.toLowerCase() === "no" ? capitalizeFirstLetter(row.camping_skills_data) : warnings.push("input for camping_skills_data is invalid"),
              camping_skills_options: ["Yes", "No"],
              camping_skills: capitalizeFirstLetter(row.camping_skills_data)
            },
            swim_test_data: {
              swim_test_placeholder: row.swim_test_data.toLowerCase() === "yes" || row.swim_test_data.toLowerCase() === "no" ? capitalizeFirstLetter(row.swim_test_data) : warnings.push("input for swim_test_data is invalid"),
              swim_test_options: ["Yes", "No"],
              swim_test: capitalizeFirstLetter(row.swim_test_data)
            },
            waterskiing_test_data: {
              waterskiing_test: row.waterskiing_test_data?.toLowerCase() === "yes" || row.waterskiing_test_data?.toLowerCase() === "no" ? capitalizeFirstLetter(row.waterskiing_test_data) : warnings.push("input for waterskiing_test_data is invalid"),
              waterskiing_test_placeholder: capitalizeFirstLetter(row.waterskiing_test_data),
              waterskiing_test_options: ["Yes", "No"]
            },
            tennis_academy_data: {
              tennis_academy_options: ["Yes", "No"],
              tennis_academy: row.tennis_academy_data?.toLowerCase() === "yes" || row.tennis_academy_data?.toLowerCase() === "no" ? capitalizeFirstLetter(row.tennis_academy_data) : warnings.push("input for tennis_academy_data is invalid"),
              tennis_academy_placeholder: capitalizeFirstLetter(row.tennis_academy_data)
            },
            activity_preferences: activity_preferences,
            notes: row.notes,
            camperId: "camper_" + uuidv4()
          };

          if (warnings.length === 0) {
            campersArray.push(camper);
          }

          let warningObject = {
            first_name: row.first_name,
            last_name: row.last_name,
            warnings: warnings,
            activity_warnings: activity_warnings
          }

          if (warnings.length > 0 || activity_warnings.length > 0) {
            allWarnings.push(warningObject);
          }

          activity_warnings = [];

        });

        console.log(campersArray);
        console.log(allWarnings);

        if (campersArray.length > 0) {
          for (const camper of campersArray) {
            const newDocRef = doc(collectionRef, camper.camperId);
            await setDoc(newDocRef, camper);
            console.log('Added document with ID: ', newDocRef.id);

            processedCount += 1;

            if (processedCount % 5 === 0) {
              const progressPercentage = (processedCount / totalCount) * 100;
              setProgress(progressPercentage);
            }
          }
        }

        setWarnings(allWarnings);
        setCampersUploadedCount(campersArray.length);

        if (allWarnings.length === 0) {
          setUploadSuccess(true);
        }

      },
    });
  };


  return (
    <div className="search-wrapper">
      <input type="text" placeholder="Search" onChange={(e) => setSearchTerm(e.target.value)} />
      <div className="filter-wrapper">
        <div className='bulk-functions'>
          <div id="bulk-upload" onClick={() => setBulkUploadActive(!bulkUploadActive)}>Bulk Upload</div>
        </div>
        {bulkUploadActive &&
          <>

                <div onClick={() => resetBulkStates()} id="bulk-upload-background"></div>
                <div id="bulk-upload-window">
                  {!warningsSuccess &&
                    <>
                      <div className='top-bar'>
                        <h6 className='title'>Bulk Upload</h6>
                        <button onClick={downloadTemplate} className='template'>Download Template</button>
                      </div>
                      <div id="file" {...getRootProps()}>
                        <input {...getInputProps({ multiple: false })} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                        {file && <p id="file-name">Selected file: {file.path}</p>}
                      </div>
                      <div className='bottom-bar'>
                        <input type='checkbox' id='bulk-upload-checkbox' checked={bulkClearData} onChange={handleClearCheck} />
                        <label htmlFor='bulk-upload-checkbox'>Clear data and fresh upload</label>
                      </div>
                      <button className={!file ? 'upload disabled': 'upload'} onClick={() => parseCsvToCamperData(file)}>Upload Template</button>
                    </>
                  }
                  {warningsSuccess && warnings &&
                    <>
                      {!uploadSuccess && <progress value={progress} max="100" />}
                      <h6>{campersUploadedCount} Campers Uploaded</h6>
                      {uploadSuccess && <h6>Upload Successful No Errors or Warnings</h6>}
                      {warnings.map((warning) => (
                        <div key={warning.first_name} className="warning">
                          <h6>{warning.first_name} {warning.last_name}</h6>
                          <ul>
                            {warning.warnings.map((warning, index) => (
                              <li key={index}>{warning}</li>
                            ))}
                            {warning.activity_warnings.map((warning, index) => (
                              <li key = {`${warning}_${index}`}>{warning}</li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </>
                  }
                </div>
          </>
        }

      </div>
      <div className="search-results">
      {sortedCampers && sortedCampers
        .filter((doc) => {
            // Filter by search term
            const searchTerms = searchTerm.toLowerCase().split(" ");
            for (const term of searchTerms) {
              if (
                !(
                  (doc.first_name && doc.first_name.toLowerCase().includes(term)) ||
                  (doc.last_name && doc.last_name.toLowerCase().includes(term)) ||
                  (doc.notes && doc.notes.toLowerCase().includes(term)) ||
                  (doc.cabin && doc.cabin.toLowerCase().includes(term))
                )
              ) {
                console.log('failed')
                return false;
              }
            }

            // All filters passed, include this camper
            return true;
        })


            .sort((a, b) => {
              if (!a.first_name || !b.first_name) {
                return 0;
              }
              const firstNameA = a.first_name.toLowerCase();
              const lastNameA = a.last_name.toLowerCase();
              const firstNameB = b.first_name.toLowerCase();
              const lastNameB = b.last_name.toLowerCase();

              if (sortType === "asc") {
                if (firstNameA < firstNameB) {
                  return -1;
                }
                if (firstNameA > firstNameB) {
                  return 1;
                }
                // First names are equal, compare last names
                if (lastNameA < lastNameB) {
                  return -1;
                }
                if (lastNameA > lastNameB) {
                  return 1;
                }
                // Last names are also equal
                return 0;
              } else {
                if (firstNameA < firstNameB) {
                  return 1;
                }
                if (firstNameA > firstNameB) {
                  return -1;
                }
                // First names are equal, compare last names
                if (lastNameA < lastNameB) {
                  return 1;
                }
                if (lastNameA > lastNameB) {
                  return -1;
                }
                // Last names are also equal
                return 0;
              }
            })
          .map((doc) => {
            const index = campers.findIndex((camper) => camper.camperId === doc.camperId);
            return <CamperSearchInfoCard key={index} index={index} camper={doc} setCamperIndex={setCamperIndex} />;
          })}
      </div>
    </div>
  );

}