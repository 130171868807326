import React, { useEffect } from 'react'
import { useState } from 'react';
import { runTransaction, doc } from 'firebase/firestore';
import { db } from '../../FirebaseConfig.js';

export default function RequiredCabins({activeSchedule, activity, pullDatabase, settings}) {
    const campersDataIndex = settings.findIndex((item) => item.settingId === 'campers');
    const cabinsInSettings = settings[campersDataIndex].cabin_data;
    const cabins = [];
    const [activeCabins, setActiveCabins] = useState(activity.required_cabins || []);

    Object.values(cabinsInSettings).forEach((cabin) => {
        (cabin.cabins).forEach((c) => {
            cabins.push(c.label);
        });
    });

    cabins.sort((a, b) => {
        const numA = parseInt(a.match(/\d+/)[0]);
        const numB = parseInt(b.match(/\d+/)[0]);

        if (numA < numB) {
            return -1;
        } else if (numA > numB) {
            return 1;
        } else {
            return 0;
        }
    });

    useEffect(() => {
        setActiveCabins(activity.required_cabins || []);
    }, [activity]);

    const handleActivityClick = async (e, cabin) => {
        const activityDoc = activity.activityId;
        let tempActiveCabins = activeCabins;

        console.log(tempActiveCabins);

        if (tempActiveCabins.includes(cabin)) {
            tempActiveCabins = tempActiveCabins.filter((item) => item !== cabin);
        } else {
            tempActiveCabins.push(cabin);
        }

        setActiveCabins(tempActiveCabins);

        const docRef = doc(db, 'schedules', activeSchedule, 'activities', activityDoc);
        try {
            await runTransaction(db, async (transaction) => {
                const doc = await transaction.get(docRef);
                if (!doc.exists()) {
                    throw "Document does not exist!";
                }
                const tempDoc = doc.data();
                tempDoc.required_cabins = tempActiveCabins;
                console.log(tempDoc);

                transaction.set(docRef, tempDoc);

            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }

        pullDatabase();
    }

    return (
        <div className="detail-info-wrapper">
            <h5>Required Cabins</h5>
            <div className="required-cabins-wrapper">
                {cabins && activeCabins && cabins.map((cabin) => {
                    const isActive = activeCabins.includes(cabin);
                    return (
                        <div
                            key={cabin}
                            className={`cabin-wrapper ${isActive ? 'active' : ''}`}
                            onClick={(e) => handleActivityClick(e, cabin)}
                        >
                            {cabin}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
