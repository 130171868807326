import React, { useState, useEffect, useRef } from "react";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { set } from "lodash";
import { setDoc, getDocs, collection, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../FirebaseConfig.js";

// import Select from "react-select";
// import firebase from "firebase/compat/app";
// import {firebaseConfig} from '../FirebaseConfig.js';
// import {
//   runTransaction,
//   writeBatch,
//   getDoc,
//   doc,
//   collection,
// } from "firebase/firestore";
// import db from this path: camp-scheduler/src/FirebaseConfig.js 


const _ = require('lodash');

export default function RunScheduler({
  settings,
  activities,
  counselors,
  campers,
  pullDatabase,
  activeSchedule,
  singleSchedule,
  schedules
}) {

  const originalCampersRef = useRef(null);  

  useEffect(() => {
    // Initialize originalCampersRef.current only if it's null (i.e., not yet initialized)
    if (originalCampersRef.current === null) {
      originalCampersRef.current = _.cloneDeep(campers);
    }
  }, []);

  console.log({activities, campers, counselors})

  //Comment out any and all references to loading from the database. Only have the scratch/default data load.
  //Do each function in order, going down the line. Traversing more deeply into the functions will need to comment out activityList becaue it will end up not being defined.
  //processCampers - define keys, days, periods, roles initially. Create a new json object based on the comment struture thats currently in Slack
  //Next inside processCampers - comment out a kPeriods, gPeriods, matchingCabinActivity

  //steps to take to move forward piece by piece
  //Track all changes through scheduleByActivity, making sure that each new portion should be verified before moving to the next one.
  //preferences through scheduleByActivity and counselor to make sure that the counselors exist within the scheduleByActivity

  //States
  const [missingRoles, setMissingRoles] = useState([]);
  const [campersListState, setCampersListState] = useState([]);
  const [kPeriodsCountState, setKPeriodsCountState] = useState({});
  const [gPeriodsCountState, setGPeriodsCountState] = useState({});
  const [cabinActivityPeriods, setCabinActivityPeriods] = useState({});
  const [activityListState, setActivityListState] = useState({});
  const [matchingCounselors, setMatchingCounselors] = useState([]);
  const [scheduleByActivity, setScheduleByActivity] = useState(Object.keys(singleSchedule)?.length &&  singleSchedule?.schedule_by_activity || {});
  const [counselorListData, setCounselorListData] = useState(Object.keys(singleSchedule)?.length && singleSchedule?.counselor_list_data?.counselorListData || []);
  const [innerCounselorTab, setInnerCounselorTab] = useState('byCounselor');
  const [missingCamperPeriods, setmissingCamperPeriods] = useState([]);
  const [missingCounselorRoles, setMissingCounselorRoles] = useState([]);
  const [nonSupportMissingCount, setNonSupportMissingCount] = useState(0);
  const [filteredCamperData, setFilteredCamperData] = useState([]);
  const [filteredCounselorData, setFilteredCounselorData] = useState([]);

  const [scheduleExists, setScheduleExists] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedCabins, setSelectedCabins] = useState({});
  const [cabinGroups, setCabinGroups] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDays, setSelectedDays] = useState({});
  const [activeTab, setActiveTab] = useState("counselors");
  const [schedulerProcess, setSchedulerProcess] = useState(1);
  const [scheduleReady, setScheduleReady] = useState(false);
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firebaseUploaded, setFirebaseUploaded] = useState(true);

  //Variables
  const today = new Date();
  const currentDay = today.getDay();
  const startOfWeek = new Date(today);
  const endOfWeek = new Date(today);
  let scheduleByDay = {};

  startOfWeek.setDate(today.getDate() - currentDay + 1);
  endOfWeek.setDate(today.getDate() - currentDay + 7);

  const startDate = startOfWeek.toDateString();
  const endDate = endOfWeek.toDateString();
  const activitiesObjRef = useRef({});
  let currentActivityObjRef = activitiesObjRef.current;
  let activitiesIdObjRef = useRef({});
  let currentActivityIdObjRef = activitiesIdObjRef.current;
  let previousScheduleByActivity;
  let previousCounselorListData;
  let counselorList;
  const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  //settings
  const daysData = settings?.find((item) => item.hasOwnProperty("days")); // find object with "days" property
  // define weekdays
  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const requiredPeriodsByDay = {
    'monday': ['K1', 'K2', 'K3', 'K4', 'K5'],
    'tuesday': ['G1', 'G2', 'G3', 'G4', 'G5'],
    'wednesday': ['K1', 'K2', 'K3', 'K4', 'K5'],
    'thursday': ['G1', 'G2', 'G3', 'G4', 'G5'],
    'friday': ['K1', 'K2', 'K3', 'K4', 'K5'],
    'saturday': ['G1', 'G2', 'G3', 'G4', 'G5']
  };


  useEffect(() => {
    const filteredCamperDataSetter = filterData(
      campers,
      searchTerm,
      selectedValues,
      selectedCabins
    );

    setFilteredCamperData(filteredCamperDataSetter);

  }, [searchTerm, selectedValues, selectedCabins, scheduleByActivity]);

  useEffect(() => {
    const filteredCounselorDataSetter = filterData(
      counselorListData,
      searchTerm,
      selectedValues,
      selectedCabins
    );

    setFilteredCounselorData(filteredCounselorDataSetter);

  }, [searchTerm, selectedValues, scheduleByActivity, counselorListData]);

  useEffect(() => {
    if(activities) {
      activitiesObjRef.current = activities.reduce((acc, activity) => {
        acc[activity.name] = activity;
        return acc;
      }, {});

      currentActivityObjRef = activitiesObjRef.current;

      activitiesIdObjRef.current = activities.reduce((acc, activity) => {
        acc[activity.activityId] = activity;
        return acc;
      }, {});

      currentActivityIdObjRef = activitiesIdObjRef.current;
    }

  }, [activities]);

  useEffect(() => {

    if (campers && activities && counselors && campersListState && kPeriodsCountState && gPeriodsCountState && cabinActivityPeriods) {
      setScheduleReady(true);
    }

  }, [campers, activities, counselors, campersListState, kPeriodsCountState, gPeriodsCountState, cabinActivityPeriods]);

  useEffect(() => {
    if (scheduleByActivity) {

      let missingPeriodsTemp = [];
      let masterPeriods = ["K1", "K2", "K3", "K4", "K5", "G1", "G2", "G3", "G4", "G5"];

      let sortedCampers = [...campers].sort((a, b) => {
        if (a.first_name === b.first_name) {
          return a.last_name.localeCompare(b.last_name); // sorts by last_name if first names are the same
        } else {
          return a.first_name.localeCompare(b.first_name); // sorts by first_name
        }
      });

      sortedCampers.forEach(camper => {
          masterPeriods.forEach(period => {
              let found = false;
              for (const loopActivity in scheduleByActivity) {
                  for (const day in scheduleByActivity[loopActivity]) {
                      if (scheduleByActivity[loopActivity][day][period]) {
                          if (scheduleByActivity[loopActivity][day][period]["campers"].includes(camper.camperId)) {
                              found = true;
                              break;
                          }
                      }
                  }
                  if (found) break;
              }
              if (!found) {
                  let camperObj = missingPeriodsTemp.find(obj => obj.camperId === camper.camperId);
                  if (camperObj) {
                      // The camper object already exists, so we just push the new period to its periods array if it's not already there
                      if (!camperObj.periods.includes(period)) {
                          camperObj.periods.push(period);
                      }
                  } else {
                      // The camper object doesn't exist, so we create a new one
                      missingPeriodsTemp.push({
                          periods: [period],
                          camperId: camper.camperId
                      });
                  }
              }
          });
      });

      setmissingCamperPeriods(missingPeriodsTemp);

      
      let tempMissingCounselorRoles = {};

      activities.forEach(activity => {
          const requiredRoles = activity.required_counselor_skills.map(skillObj => skillObj.skill);
      
          for (const loopActivity in scheduleByActivity) {
              if (loopActivity === activity.activityId) { // assuming activityName is the name of the activity
                  for (const day in scheduleByActivity[loopActivity]) {
                      for (const period in scheduleByActivity[loopActivity][day]) {
                          if (scheduleByActivity[loopActivity][day][period]) {
                              const availableRoles = Object.keys(scheduleByActivity[loopActivity][day][period]['roles']);
                              requiredRoles.forEach(role => {
                                  if (!availableRoles.includes(role)) {
                                      if (!tempMissingCounselorRoles[activity.name]) {
                                        tempMissingCounselorRoles[activity.name] = {};
                                      }
                                      if (!tempMissingCounselorRoles[activity.name][day]) {
                                        tempMissingCounselorRoles[activity.name][day] = {};
                                      }
                                      if (!tempMissingCounselorRoles[activity.name][day][period]) {
                                        tempMissingCounselorRoles[activity.name][day][period] = [];
                                      }
                                      tempMissingCounselorRoles[activity.name][day][period].push(role);
                                  }
                              });
                          }
                      }
                  }
              }
          }
      });

      let nonSupportRoleCount = 0;
      if (tempMissingCounselorRoles) {
        Object.values(tempMissingCounselorRoles).forEach(days => {
          Object.values(days).forEach(periods => {
            Object.values(periods).forEach(roles => {
              roles.forEach(role => {
                if (role !== "Support") {
                  nonSupportRoleCount++;
                }
              });
            });
          });
        });
      }

      setNonSupportMissingCount(nonSupportRoleCount);
      setMissingCounselorRoles(tempMissingCounselorRoles);

    }

  }, [scheduleByActivity]);

  useEffect(() => {
    if(schedulerProcess === 2 && scheduleByActivity !== previousScheduleByActivity)  {
      campersListsRequiredAndLocationBased(scheduleByActivity);
    }
    else if(schedulerProcess === 3 && scheduleByActivity !== previousScheduleByActivity)  {
      campersListWithCabins(scheduleByActivity);
    }
    else if(schedulerProcess === 4 && scheduleByActivity !== previousScheduleByActivity)  {
      campersListByPreference(scheduleByActivity);
    }
    else if(schedulerProcess === 5 && scheduleByActivity !== previousScheduleByActivity)  {
      campersListFillRandoms();
    }
    else if (schedulerProcess === 6 && scheduleByActivity !== previousScheduleByActivity) {
      fillMissingPeriods();
    }
    else if(schedulerProcess === 7 && scheduleByActivity !== previousScheduleByActivity)  {
      createCounselorsList(counselors);
    }
    else if(schedulerProcess === 8 && counselorListData !== previousCounselorListData)  {
      updateCounselorSchedule();
    }
    else if (schedulerProcess === 9) {
      setTimeout(() => {
        updateFirebase();
      }, 3000);
    }

    previousScheduleByActivity = {...scheduleByActivity};
    previousCounselorListData = {...counselorListData};

  }, [scheduleByActivity, counselorListData, schedulerProcess]);

  useEffect(() => {
    if (settings) {
      const campersIndex = settings?.findIndex(
        (item) => item.settingId === "campers"
      );
      if (campersIndex >= 0) {
        setCabinGroups(settings[campersIndex]?.cabin_data);
      }
    }
    if (missingRoles) {
      console.log({ missingRoles });
    }
    if(matchingCounselors) {
      console.log({ matchingCounselors });
    }

  }, [settings, missingRoles, matchingCounselors]);

  useEffect(() => {
    if (counselorListData.length > 0 && Object.entries(scheduleByActivity).length > 0 && firebaseUploaded) {
      setSchedulerProcess(8);
      setScheduleExists(true);
      setFirebaseUploaded(false);
      console.log({counselorListData, scheduleByActivity})
    }
  }, [counselorListData, scheduleByActivity]);

  // useEffect(() => {
  //   if (missingRoles) {
  //     console.log({missingRoles});
  //   }
  // }, [missingRoles]);

  const uniqueValuesByDay = weekdays.reduce((result, day) => {
    const dayPeriods = daysData?.days[day] // get periods for current day
    dayPeriods?.forEach((period) => {
      if (!result.includes(period)) {
        result.push(period);
      }
    });
    return result;
  }, []);

  //Event handling functions
  const handleCheckboxChange = (value) => {
    setSelectedValues((prevValues) => {
      const updatedSelectedValues = { ...prevValues };
      if (updatedSelectedValues[value]) {
        delete updatedSelectedValues[value];
      } else {
        updatedSelectedValues[value] = true;
      }
      return updatedSelectedValues;
    });
  };

  function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
  }

  const handleDaysChange = (value) => {
    setSelectedDays((prevSelectedDays) => {
      const updatedSelectedDays = { ...prevSelectedDays };
      if (updatedSelectedDays[value.toLowerCase()]) {
        delete updatedSelectedDays[value.toLowerCase()];
      } else {
        updatedSelectedDays[value.toLowerCase()] = true;
      }
      return updatedSelectedDays;
    });
  };

  //cabins
  const getAllCabinsNotInUnused = (cabinData) => {
    const cabinsNotInUnused = [];
    Object.keys(cabinData).forEach((group) => {
      if (group !== "unused") {
        cabinData[group].cabins.forEach((cabin) =>
          cabinsNotInUnused.push(cabin)
        );
      }
    });

    cabinsNotInUnused.sort((a, b) => {
      const cabinNumberA = parseInt(a.label.match(/\d+/)[0], 10);
      const cabinNumberB = parseInt(b.label.match(/\d+/)[0], 10);

      return cabinNumberA - cabinNumberB;
    });

    return cabinsNotInUnused;
  };

  const handleCabinCheckboxChange = (cabin) => {
    setSelectedCabins((prevCabins) => {
      //if cabin is already in selectedCabins, remove it else add it as a key and true
      const updatedSelectedCabins = { ...prevCabins };
      if (updatedSelectedCabins[cabin.value]) {
        delete updatedSelectedCabins[cabin.value];
      } else {
        updatedSelectedCabins[cabin.value] = true;
      }
      return updatedSelectedCabins;
    });
  };

  const cabinsNotInUnused = getAllCabinsNotInUnused(cabinGroups);

  //Adjust the structure of this to match scheduleByActivity and follows the structure of that, this should be ran first before process campers
  const runActivityData = (activities) => {
    let activityDataObject = {};
    const weekdays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday'
    ];

    activities.forEach((activity) => {
      activityDataObject[activity.activityId] = {};
        weekdays.map((day) => {
          activityDataObject[activity.activityId][day] = {}
        })
    });

    setScheduleByActivity(activityDataObject)
    setSchedulerProcess(2);
    setLoadingPercent(5);
  }

  const campersListWithCabins = async (scheduleByActivity) => { //scheduler set to 3
    let scheduleByActivityClone = _.cloneDeep(scheduleByActivity);

    processCampers(scheduleByActivityClone, 'cabin', false, false);

    setSchedulerProcess(3);
    setLoadingPercent(40);
  }

  //define periods as K/G to make sure that the correct period gets slotted into the correct day(s)
  async function processCampers(scheduleByActivity, batchBy, fillRandom, onlyFillExisting) {
    //do try catch new promise resolve
    new Promise((resolve, reject) => {
      let scheduleByActivityClone = _.cloneDeep(scheduleByActivity);
      let campersClone = _.cloneDeep(campers);

      let requiredLocationsShareable = false;
      if (batchBy === "requiredAndLocations") {
        requiredLocationsShareable = true;
      }

      let camperPreferences = campers.map(camper => {
        let activies = [];
        camper.activity_preferences.forEach(activity => {
          activies.push(activity.activity);
        });
        return {
          camperId: camper.camperId,
          preferences: activies
        };
      });

      let activityFrequencyCount = camperPreferences.reduce((acc, camper) => {
        camper.preferences.forEach(activity => {
          if (!acc[activity]) {
            acc[activity] = 0;
          }
          acc[activity]++;
        });
        return acc;
      }, {});

      console.log({activityFrequencyCount});

      // Convert the object to an array of [activity, count] pairs
      let activityArray = Object.entries(activityFrequencyCount);

      // Sort the array by count, in descending order
      activityArray.sort((a, b) => b[1] - a[1]);

      let activityShifted = true;
      let loopCount = 0;

      //randomize campersClone
      campersClone = shuffle(campersClone);


      while ((activityShifted || loopCount < 6) && loopCount < 14) {
        loopCount++;
        console.log("loopCount: ", loopCount);
        activityShifted = false;
        let maxPreferenceLevel = 20;

        // Loop through each preference level
        for(let preferenceLevel = 0; preferenceLevel < maxPreferenceLevel; preferenceLevel++) {
          // Loop through each camper
          for(let camperIndex = 0; camperIndex < campersClone.length; camperIndex++) {

            const camper = campersClone[camperIndex];
            let preferences = camper.activity_preferences;
            const top4Preferences = preferences.slice(0, 4);

            const camperID = camper.camperId;
            const cabin = camper.cabin;

            // let sortingOrderDict = {};
            // activityArray.forEach(([activity, _], index) => {
            //     sortingOrderDict[activity] = index;
            // });

            let waterskiingTestPassed = camper?.waterskiing_test_data?.waterskiing_test === 'Yes' ? true : false;
            let campingSkillsPassed = camper?.camping_skills_data?.camping_skills === 'Yes' ? true : false;
            let swimTestPassed = camper?.swim_test_data?.swim_test === 'Yes' ? true : false;
            const requiredActivity = [];
            let activityExists = false;
            let reqActivity;  

            if (fillRandom) {
              preferences = activities;
            }

            if (!campingSkillsPassed) {
              if (fillRandom) {
                let campingSkillsActivity = currentActivityObjRef['Camping Skills'];
                campingSkillsActivity.priority = true;
                //find the index of activity.name === 'Camping Skills'
                let index = preferences.findIndex(a => a.name === 'Camping Skills');
                //remove it from the array
                if (index !== -1) {
                  preferences.splice(index, 1);
                }
                preferences.unshift(campingSkillsActivity);
              } else {
                let campingSkillsActivity = {
                  activity: 'Camping Skills',
                  priority: true
                }
                //find the index of activity.name === 'Camping Skills'
                let index = preferences.findIndex(a => a.activity === 'Camping Skills');
                //remove it from the array
                if (index !== -1) {
                  preferences.splice(index, 1);
                }
                preferences.unshift(campingSkillsActivity);
              }
            }

            //need to do something similar to the above for required activity to ensure that if it is required but not within their preferences that it is still scheduled
            //preferences through activities, see if their cabin group is inside an activity that has a required cabin group
            if ((requiredLocationsShareable === true && loopCount === 1) || (requiredLocationsShareable === true && loopCount === 2)) {
              activities.forEach(loopActivity => {
                const isRequired = loopActivity.required_cabins && loopActivity.required_cabins.length > 0 && loopActivity.required_cabins.includes(cabin);
                const isBatchedByCabin = loopActivity.batch_by === 'cabin';
                if (isRequired || isBatchedByCabin) {
                  const isAlreadyInPreferences = preferences.some(prefActivity => prefActivity.activity === loopActivity.name);
                  if (!isAlreadyInPreferences) {
                    const newPreference = {
                      activity: loopActivity.name,
                      priority: true
                    };
                    preferences.unshift(newPreference);
                  }
                }
              });
            
              preferences.forEach(preference => {
                const matchingActivity = activities.find(activity => activity.name === preference.activity);
                if (matchingActivity) {
                  const isRequired = matchingActivity.required_cabins && matchingActivity.required_cabins.length > 0 && matchingActivity.required_cabins.includes(cabin);
                  const isBatchedByCabin = matchingActivity.batch_by === 'cabin';
                  if (isRequired || isBatchedByCabin) {
                    preference.priority = true;
                  }
                }
              });

              preferences.forEach(preference => {
                const top4Preferences = preferences.slice(0, 4);
                const matchingActivity = activities.find(activity => activity.name === preference.activity);
                if (matchingActivity) {
                  preference.priority = true;
                }
              });
            
              // Sorting function
              preferences.sort((a, b) => {
                const activityA = activities.find(activity => activity.name === a.activity);
                const activityB = activities.find(activity => activity.name === b.activity);
                const isRequiredA = activityA.required_cabins && activityA.required_cabins.length > 0 && activityA.required_cabins.includes(cabin);
                const isRequiredB = activityB.required_cabins && activityB.required_cabins.length > 0 && activityB.required_cabins.includes(cabin);
                const isBatchedByCabinA = activityA.batch_by === 'cabin';
                const isBatchedByCabinB = activityB.batch_by === 'cabin';
                const totalAvailabilityA = activityA.g_periods.length + activityA.k_periods.length;
                const totalAvailabilityB = activityB.g_periods.length + activityB.k_periods.length;


                if (a.activity === "Camping Skills") {
                  return -1;
                }

                if (b.activity === "Camping Skills") {
                  return 1;
                }

                if (activityA.only_required_cabins && !activityB.only_required_cabins) {
                  return -1;
                }

                if (!activityA.only_required_cabins && activityB.only_required_cabins) {
                  return 1;
                }
            
                // First, get the top 4 preferences
                const topFourPreferences = camper.activity_preferences.slice(0, 4);

                // Now, use the 'some' method on the sliced array
                if (topFourPreferences.some(pref => pref.activity === a.activity)) {
                  return -1;
                }

                if (topFourPreferences.some(pref => pref.activity === b.activity)) {
                  return 1;
                }


                // 1. Priority
                if (a.priority && !b.priority) {
                    return -1;
                }
                if (!a.priority && b.priority) {
                    return 1;
                }
            
                // 2. required & batch by cabin
                if ((isRequiredA && isBatchedByCabinA) && !(isRequiredB && isBatchedByCabinB)) {
                    return -1;
                }
                if (!(isRequiredA && isBatchedByCabinA) && (isRequiredB && isBatchedByCabinB)) {
                    return 1;
                }
                if ((isRequiredA && isBatchedByCabinA) && (isRequiredB && isBatchedByCabinB)) {
                    return activityA.name < activityB.name ? -1 : 1;
                }
            
                // 3. batch by cabin
                if (isBatchedByCabinA && !isBatchedByCabinB) {
                    return -1;
                }
                if (!isBatchedByCabinA && isBatchedByCabinB) {
                    return 1;
                }
                if (isBatchedByCabinA && isBatchedByCabinB) {
                    return activityA.name < activityB.name ? -1 : 1;
                }
            
                // 4. required
                if (isRequiredA && !isRequiredB) {
                    return -1;
                }
                if (!isRequiredA && isRequiredB) {
                    return 1;
                }
                if (isRequiredA && isRequiredB) {
                    return activityA.name < activityB.name ? -1 : 1;
                }
            
                // 5. Availability
                if (totalAvailabilityA < totalAvailabilityB) {
                    return -1;
                }
                if (totalAvailabilityA > totalAvailabilityB) {
                    return 1;
                }
            
                // 6. everything else
                return 0;
              });          
                    
            }


            if(preferenceLevel >= preferences.length) {
                continue;
            }

            if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
              console.log(preferences);
            }

            let camperDoesntHaveRequiredSkill = false;

            const currentActivity = fillRandom ? activities[preferenceLevel].name : preferences[preferenceLevel].activity;

            const activity = fillRandom ? activities[preferenceLevel].name : preferences[preferenceLevel].activity;
            const priority = fillRandom ?  activities[preferenceLevel].priority : preferences[preferenceLevel].priority;
            const activityData = currentActivityObjRef[currentActivity];
            const activityAvailableKPeriod = activityData.k_periods;
            const activityAvailableGPeriod = activityData.g_periods;

            if (currentActivity === "Flag Football" && fillRandom)  {
              continue;
            }

            // sort kPeriods
            activityAvailableKPeriod.sort((a, b) => {
              const periodNumberA = parseInt(a.match(/\d+/)[0], 10);
              const periodNumberB = parseInt(b.match(/\d+/)[0], 10);

              return periodNumberA - periodNumberB;
            });

            //sort gPeriods
            activityAvailableGPeriod.sort((a, b) => {
              const periodNumberA = parseInt(a.match(/\d+/)[0], 10);
              const periodNumberB = parseInt(b.match(/\d+/)[0], 10);

              return periodNumberA - periodNumberB;
            });

            let locationIsShareableCount = 0;
            let locationIsNotShareableCount = 0;
            let skipPreference = false;

            let activityHasSharedLocation = false;
            activities.forEach(preferencesedActivity => {
              if (preferencesedActivity.location === activityData.location && preferencesedActivity.name !== activityData.name) {
                activityHasSharedLocation = true;
              }
            });

            // if (activityHasSharedLocation) {
            //   for(const loopActivity in scheduleByActivityClone) {
            //     if(loopActivity === activityData.activityId) {
            //       for(const day in scheduleByActivityClone[loopActivity]) {
            //         for(const preferencesPeriod in scheduleByActivityClone[loopActivity][day]) {
            //           const periodData = scheduleByActivityClone[loopActivity][day][preferencesPeriod];
            //           if (activityData.locationIsShareable) {
            //             locationIsShareableCount++;
            //           }
            //           if (!activityData.locationIsShareable) {
            //             locationIsNotShareableCount++;
            //           }
            //           if (locationIsShareableCount > 9 || locationIsNotShareableCount > 9) {
            //             skipPreference = true;
            //           }
            //         }
            //       }
            //     }
            //   }
            // }

            // if (skipPreference) {
            //   continue;
            // }

            // if (preferencesedActivity[0] !== currentActivity) {
            //   //next
            //   return;
            // }



            if (activityData.only_required_cabins && !activityData?.required_cabins.includes(cabin)) {
              continue;
            }


            if (loopCount === 1) {
              if (!top4Preferences.includes(currentActivity)) {
                console.log("top4Preferences.includes(currentActivity)");
                continue;
              }
              batchBy = activityData.batch_by;
            }

            if (requiredLocationsShareable === true && loopCount === 2) {
              if (!priority) {
                continue;
              }
              if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                console.log(preferences)
              }
            }

            if (requiredLocationsShareable === true && loopCount === 3) {
              //if activity is not required return

              if (activityData?.is_two_periods === false) {
                continue;
              }
              // return; //remove these after required logic is set up
              batchBy = activityData?.batch_by;
            }

            if (requiredLocationsShareable === true && loopCount === 4) {
              //if activity is not required return

              if (!activityData?.required_cabins || !activityData?.required_cabins.includes(cabin)) {
                continue;
              }
              // return; //remove these after required logic is set up
              batchBy = activityData.batch_by;
            }

            if (requiredLocationsShareable === true && loopCount === 5) {
              if ((activityAvailableKPeriod.length + activityAvailableGPeriod.length) < 10) {
                batchBy = activityData.batch_by;
              } else {
                continue;
              }
            }

            if (requiredLocationsShareable === true && loopCount === 6) {
              let hasSharedLocation = false;
              activities.forEach(preferencesedActivity => {
                if (preferencesedActivity.location === activityData.location && preferencesedActivity.name !== activityData.name) {
                  hasSharedLocation = true;
                }
              });

              if (hasSharedLocation && activityData.locationIsShareable || !hasSharedLocation) {
                continue;
              }
              batchBy = activityData.batch_by;
            }

            if (requiredLocationsShareable === true && loopCount === 7) {
              let hasSharedLocation = false;
              activities.forEach(preferencesedActivity => {
                if (preferencesedActivity.location === activityData.location && preferencesedActivity.name !== activityData.name) {
                  hasSharedLocation = true;
                }
              });

              if (hasSharedLocation && !activityData.locationIsShareable || !hasSharedLocation) {
                continue;
              }
              batchBy = activityData.batch_by;
            }

            Object.entries(activityData.requirements).forEach(([skillRequired, isRequired]) => {
              if(isRequired.active && skillRequired === 'waterskiing_test' && !waterskiingTestPassed) {
                camperDoesntHaveRequiredSkill = true;
                return;
              }

              if(isRequired.active && skillRequired === 'swim_test' && !swimTestPassed) {
                camperDoesntHaveRequiredSkill = true;
                return;
              }
            });

            if(camperDoesntHaveRequiredSkill) {
              continue;
            }

            let cabinGroup = "none";
            const activityDataMap = new Map(Object.entries(activityData));

            for (const [key, value] of Object.entries(activityData.cabin_groups)) {
              const actualCabin = value.find(cabinValue => cabinValue.label === cabin);
              if (actualCabin && (key !== undefined && key !== null)) {
                cabinGroup = key;
                activityDataMap.set('cabinGroup', key);
                break;
              }
            }

            const updatedActivityData = Object.fromEntries(activityDataMap);


            let hasActivity = false;

            for(const loopActivity in scheduleByActivityClone) {
              if(loopActivity === activityData.activityId) {
                for(const day in scheduleByActivityClone[loopActivity]) {
                  for(const preferencesPeriod in scheduleByActivityClone[loopActivity][day]) {
                    const periodData = scheduleByActivityClone[loopActivity][day][preferencesPeriod];
                    if(periodData?.campers?.includes(camperID)) {
                      hasActivity = true;
                    }
                  }
                }
              }
            }

            if (!hasActivity) {
              if(activityData.batch_by === batchBy) {

                const { max_kids } = activityData;
                const maxKidsInt = parseInt(max_kids);

                let kPeriodMatchFound = false;
                let gPeriodMatchFound = false;
                let kPeriodActivitySet = false;
                let gPeriodActivitySet = false;
                let matchingActivityData;
                let prevActivity;


                const checkForMatchingKGPeriods = () => {
                  const availableKPeriods = new Set(activityAvailableKPeriod);
                  const availableGPeriods = new Set(activityAvailableGPeriod);

                  const activityKeys = Object.keys(scheduleByActivityClone);

                  for (let i = 0; i < activityAvailableKPeriod.length; i++) {
                    let period = activityAvailableKPeriod[i];

                    if(!availableKPeriods.has(period)) {
                      continue;
                    }

                    let matchingActivtyPeriodExists = false;
                    for(const preferencesedActivity in scheduleByActivityClone) {
                      if (preferencesedActivity === activityData.activityId) {
                        for(const day in scheduleByActivityClone[preferencesedActivity]) {
                          if(scheduleByActivityClone[preferencesedActivity][day][period] && (day === 'monday' || day === 'wednesday' || day === 'friday')) {
                            matchingActivtyPeriodExists = true;
                            kPeriodMatchFound = true;
                            prevActivity = scheduleByActivityClone[preferencesedActivity][day][period];
                            break;
                          }
                        }
                        if(matchingActivtyPeriodExists) {
                          break;
                        }
                      }
                      if(matchingActivtyPeriodExists) {
                        break;
                      }
                    }

                    if (!matchingActivtyPeriodExists) {
                      continue;
                    }

                    matchingActivityData = {
                      'currentActivity'  : updatedActivityData,
                      'previousActivity'  : prevActivity,
                      'period'    : period
                    };

                    let conflictFound = false;
                    let nextPeriod = period.charAt(0) + (parseInt(period.charAt(1)) + 1).toString();
                    let camperCount = 0;
                    let cabinGroups = [];

                    for(const preferencesedActivity in scheduleByActivityClone) {
                      for(const day in scheduleByActivityClone[preferencesedActivity]) {
                        if (day !== "monday") {
                          continue;
                        }
                        for(const preferencesPeriod in scheduleByActivityClone[preferencesedActivity][day]) {
                          if (preferencesPeriod !== period) {
                            continue;
                          }

                          const periodData = scheduleByActivityClone[preferencesedActivity][day][preferencesPeriod];

                          if (preferencesedActivity === activityData.activityId) {
                            cabinGroups = periodData.cabinGroups;
                            if (scheduleByActivityClone[preferencesedActivity][day][preferencesPeriod].campers.length >= maxKidsInt) {
                              conflictFound = true;
                            }
                          }

                          if(periodData?.campers?.includes(camperID)) {
                            conflictFound = true;
                            if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                              console.log("conflict found match K", currentActivity, period);
                            }
                          }
                        }
                      }
                    }

                    if (batchBy === 'cabin' && !cabinGroups?.includes(cabinGroup) ) {
                      conflictFound = true;
                    }

                    if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                      console.log("Matching K Period", currentActivity, period, conflictFound);
                    }

                    if(conflictFound) {
                      continue;
                    }

                    ['monday', 'wednesday', 'friday'].forEach((day) => {

                      scheduleByActivityClone[activityData.activityId][day][period].campers.push(camperID);

                      if(!scheduleByActivityClone[activityData.activityId][day][period].cabins.includes(cabin)) {
                        scheduleByActivityClone[activityData.activityId][day][period].cabins.push(cabin);
                      }

                      if(!scheduleByActivityClone[activityData.activityId][day][period].cabinGroups.includes(cabinGroup)) {
                        scheduleByActivityClone[activityData.activityId][day][period].cabinGroups.push(cabinGroup);
                      }
                    });

                    kPeriodActivitySet = true;

                    if(activityData.is_two_periods && activityAvailableKPeriod[i] !== 'K5') {
                      ['monday', 'wednesday', 'friday'].forEach((day) => {
                        scheduleByActivityClone[activityData.activityId][day][nextPeriod].campers.push(camperID);

                        if(!scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabins.includes(cabin)) {
                          scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabins.push(cabin);
                        }

                        if(!scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabinGroups.includes(cabinGroup)) {
                          scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabinGroups.push(cabinGroup);
                        }
                      });
                    }

                    break;
                  }


                  if(!kPeriodActivitySet) {
                    for (let i = 0; i < activityAvailableGPeriod.length; i++) {
                      const period = activityAvailableGPeriod[i];

                      if(!availableGPeriods.has(period)) {
                        continue;
                      }

                      let matchingActivtyPeriodExists = false;
                      for(const preferencesedActivity in scheduleByActivityClone) {
                        if (preferencesedActivity === activityData.activityId) {
                          for(const day in scheduleByActivityClone[preferencesedActivity]) {
                            if(scheduleByActivityClone[preferencesedActivity][day][period] && (day === 'tuesday' || day === 'thursday' || day === 'saturday')) {
                              matchingActivtyPeriodExists = true;
                              gPeriodMatchFound = true;
                              prevActivity = scheduleByActivityClone[preferencesedActivity][day][period];
                              break;
                            }
                          }
                          if(matchingActivtyPeriodExists) {
                            break;
                          }
                        }
                        if(matchingActivtyPeriodExists) {
                          break;
                        }
                      }
                    
                      if (!matchingActivtyPeriodExists) {
                        continue;
                      }

                      matchingActivityData = {
                        'currentActivity'  : updatedActivityData,
                        'previousActivity'  : prevActivity,
                        'period'    : period
                      };
                    
                      let conflictFound = false;
                      let nextPeriod = period.charAt(0) + (parseInt(period.charAt(1)) + 1).toString();
                      let cabinGroups = [];
                    
                      for(const preferencesedActivity in scheduleByActivityClone) {
                        for(const day in scheduleByActivityClone[preferencesedActivity]) {
                          if (day !== "tuesday") {
                            continue;
                          }
                          for(const preferencesPeriod in scheduleByActivityClone[preferencesedActivity][day]) {
                            if (preferencesPeriod !== period) {
                              continue;
                            }

                            const periodData = scheduleByActivityClone[preferencesedActivity][day][preferencesPeriod];

                            if (preferencesedActivity === activityData.activityId) {
                              cabinGroups = periodData.cabinGroups;
                              if (!onlyFillExisting) {
                                if (scheduleByActivityClone[preferencesedActivity][day][preferencesPeriod].campers.length >= maxKidsInt) {
                                  conflictFound = true;
                                }
                              } else {
                                if (scheduleByActivityClone[preferencesedActivity][day][preferencesPeriod].campers.length >= (maxKidsInt)) {
                                  conflictFound = true;
                                }
                              }
                            }
    
                            if(periodData?.campers?.includes(camperID)) {
                              conflictFound = true;
                              if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                                console.log("conflict found match g", currentActivity, period);
                              }
                            }
                          }
                        }
                      }

                      if (batchBy === 'cabin' && !cabinGroups?.includes(cabinGroup) && period.cabinGroups.length > 0) {
                        conflictFound = true;
                      }


                      if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                        console.log("Matching G Period", currentActivity, period, conflictFound);
                      }

                      if(conflictFound) {
                        continue;
                      }

                      ['tuesday', 'thursday', 'saturday'].forEach((day) => {
                        scheduleByActivityClone[activityData.activityId][day][period].campers.push(camperID);

                        if(!scheduleByActivityClone[activityData.activityId][day][period].cabins.includes(cabin)) {
                          scheduleByActivityClone[activityData.activityId][day][period].cabins.push(cabin);
                        }

                        if(!scheduleByActivityClone[activityData.activityId][day][period].cabinGroups.includes(cabinGroup)) {
                          scheduleByActivityClone[activityData.activityId][day][period].cabinGroups.push(cabinGroup);
                        }
                      });

                      gPeriodActivitySet = true;

                      if(activityData.is_two_periods && activityAvailableKPeriod[i] !== 'K5') {
                        ['tuesday', 'thursday', 'saturday'].forEach((day) => {
                          scheduleByActivityClone[activityData.activityId][day][nextPeriod].campers.push(camperID);

                          if(!scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabins.includes(cabin)) {
                            scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabins.push(cabin);
                          }

                          if(!scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabinGroups.includes(cabinGroup)) {
                            scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabinGroups.push(cabinGroup);
                          }
                        });
                      }

                      break;

                    }
                  }

                  // if (!onlyFillExisting && (gPeriodMatchFound || kPeriodMatchFound) && !kPeriodActivitySet && !gPeriodActivitySet) {

                  //   let skip = false;
                
                  //   if (['Swim (games)', 'Swim (lessons)'].includes(matchingActivityData.currentActivity.name)) {
                  //     skip = true;
                  //   }
                  //   if (activityData.is_two_periods) {
                  //     skip = true;
                  //   }
                  //   if (matchingActivityData.currentActivity.batchBy === 'cabin') {
                  //     skip = true;
                  //   }

                  //   if (activityHasSharedLocation)  {
                  //     skip = true;
                  //   }

                  //   let currentActivityHasSharedLocation = false;
                  //   let previousActivityHasSharedLocation = false;
                  //   activities.forEach(preferencesedActivity => {
                  //     if (preferencesedActivity.location === matchingActivityData.currentActivity.location && preferencesedActivity.name !== matchingActivityData.currentActivity.name) {
                  //       currentActivityHasSharedLocation = true;
                  //     }
                  //     if (preferencesedActivity.location === matchingActivityData.previousActivity.location && preferencesedActivity.name !== matchingActivityData.previousActivity.name) {
                  //       previousActivityHasSharedLocation = true;
                  //     }
                  //   });

                  //   if (currentActivityHasSharedLocation && previousActivityHasSharedLocation) {
                  //     skip = true;
                  //   }
                
                  //   if (!skip) {
                  //       const scheduleByActivityCloneCopy = _.cloneDeep(scheduleByActivityClone);
                  //       let availablePeriods = [];
                  //       let previousMatchingCampers = new Set();
                  //       previousMatchingCampers.add(camperID);
                
                  //       for(const activity in scheduleByActivityCloneCopy) {
                  //         if (currentActivityIdObjRef[activity].name !== matchingActivityData.previousActivity.name) {
                  //           continue;
                  //         }
                  //         for(const day in scheduleByActivityCloneCopy[activity]) {
                  //           for(const preferencesPeriod in scheduleByActivityCloneCopy[activity][day]) {
                  //             if (preferencesPeriod !== matchingActivityData.period) {
                  //               continue;
                  //             }
                  //             const periodData = scheduleByActivityCloneCopy[activity][day][preferencesPeriod];
                  //             periodData.campers.forEach((camper) => {
                  //               previousMatchingCampers.add(camper);
                  //             });
                  //           }
                  //         }
                  //       }
                
                  //       let failedPeriods = [];
                  //       let conflictFound = false;
                  //       const totalPeriods = new Set(['K1', 'K2', 'K3', 'K4', 'K5', 'G1', 'G2', 'G3', 'G4', 'G5']);
                
                  //       for(const activity in scheduleByActivityCloneCopy) {
                  //         for(const day in scheduleByActivityCloneCopy[activity]) {
                  //           for(const preferencesPeriod in scheduleByActivityCloneCopy[activity][day]) {
                  //             if (preferencesPeriod !== matchingActivityData.period) {
                  //               continue;
                  //             }
                  //             if (failedPeriods.includes(preferencesPeriod)) {
                  //               continue;
                  //             }
                  //             const periodData = scheduleByActivityCloneCopy[activity][day][preferencesPeriod];

                  //             if (matchingActivityData.currentActivity.location === currentActivityIdObjRef[activity].location) {
                  //               conflictFound = true;
                  //               failedPeriods.push(preferencesPeriod); 
                  //             }
                  //             if (
                  //               matchingActivityData.currentActivity.locationIsShareable && currentActivityIdObjRef[activity].locationIsShareable === false
                  //               && matchingActivityData.currentActivity.location === currentActivityIdObjRef[activity].location
                  //             ) {
                  //               conflictFound = true;
                  //               failedPeriods.push(preferencesPeriod); 
                  //             }
                  //             if (
                  //               matchingActivityData.currentActivity.locationIsShareable === false
                  //               && matchingActivityData.currentActivity.location === currentActivityIdObjRef[activity].location
                  //               && currentActivityIdObjRef[activity].name !== matchingActivityData.currentActivity.name
                  //             ) {
                  //               conflictFound = true;
                  //               failedPeriods.push(preferencesPeriod); 
                  //             }

                  //             if (periodData.campers.length > currentActivityIdObjRef[activity].max_kids) {
                  //               conflictFound = true;
                  //               failedPeriods.push(preferencesPeriod);
                  //             }
                  //           }
                  //         }
                  //       }

                  //       let availableDifference = [...totalPeriods].filter(x => !failedPeriods.includes(x));
                  //       availablePeriods.push(availableDifference);

                  //       let commonValue = availablePeriods[0];

                  //       if(commonValue && !skip) {

                  //         let campersToBeMoved;
                  //         let cabinsToBeMoved;
                  //         let cabinGroupsToBeMoved;

                  //         for(const activity in scheduleByActivityCloneCopy) {
                  //           if (activity !== matchingActivityData.previousActivity.activityId) {
                  //             continue;
                  //           }
                  //           for(const day in scheduleByActivityCloneCopy[activity]) {
                  //             for(const preferencesPeriod in scheduleByActivityCloneCopy[activity][day]) {
                  //               if (preferencesPeriod !== matchingActivityData.period) {
                  //                 continue;
                  //               }
                  //               const periodData = scheduleByActivityCloneCopy[activity][day][preferencesPeriod];
                  //               campersToBeMoved = periodData.campers;
                  //               scheduleByActivityCloneCopy[activity][day][preferencesPeriod].campers = [];
                  //               scheduleByActivityCloneCopy[activity][day][preferencesPeriod].campers.push(camperID);

                  //               cabinsToBeMoved = periodData.cabins;
                  //               scheduleByActivityCloneCopy[activity][day][preferencesPeriod].cabins = [];
                  //               scheduleByActivityCloneCopy[activity][day][preferencesPeriod].cabins.push(cabin);

                  //               cabinGroupsToBeMoved = periodData.cabinGroups;
                  //               scheduleByActivityCloneCopy[activity][day][preferencesPeriod].cabinGroups = [];
                  //               scheduleByActivityCloneCopy[activity][day][preferencesPeriod].cabinGroups.push(cabinGroup);
                  //             }
                  //           }
                  //         }
                  //         for(const activity in scheduleByActivityCloneCopy) {
                  //           if (activity !== matchingActivityData.currentActivity.activityId) {
                  //             continue;
                  //           }
                  //           for(const day in scheduleByActivityCloneCopy[activity]) {
                  //             for(const day in scheduleByActivityCloneCopy[activity]) {
                  //               for(const preferencesPeriod in scheduleByActivityCloneCopy[activity][day]) {
                  //                 if (commonValue !== preferencesPeriod) {
                  //                   continue;
                  //                 }
                  //                 scheduleByActivityCloneCopy[activity][day][preferencesPeriod].campers = campersToBeMoved;
                  //                 scheduleByActivityCloneCopy[activity][day][preferencesPeriod].cabins = cabinsToBeMoved;
                  //                 scheduleByActivityCloneCopy[activity][day][preferencesPeriod].cabinGroups = cabinGroupsToBeMoved;
                  //               }
                  //             }
                  //           }
                  //         }

                  //         scheduleByActivityClone = scheduleByActivityCloneCopy;


                  //       }
                  //   }
                  // }

                }  

                const buildKPeriods = () => {
                  if(!gPeriodActivitySet && !kPeriodActivitySet) {
                    const availableKPeriods = new Set(activityAvailableKPeriod);
                    // const isSwimActivity = swimActivities.has(currentActivity);

                    const activityKeys = Object.keys(scheduleByActivityClone);

                    for(let i = 0; i < activityAvailableKPeriod.length; i++) {
                      let period = activityAvailableKPeriod[i];

                      if (!availableKPeriods.has(period)) {
                        continue;
                      }

                      let conflictFound = false;
                      let nextPeriod = period.charAt(0) + (parseInt(period.charAt(1)) + 1).toString();
                      let periodIsFree = true;
                      let camperCount = 0;
                      let cabinGroups = [];
                      let existingCabinGroup = false;

                      for(const activity of activityKeys) {
                        const days = scheduleByActivityClone[activity];
                        for(const day in days) {
                          const individualPeriod = days[day];
                          for(const p in individualPeriod) {
                            if(p !== period) {
                              continue;
                            }

                            cabinGroups = individualPeriod[p]?.cabinGroups;

                            if(activity === activityData.activityId) {
                              if(individualPeriod && individualPeriod[p]?.campers) {
                                camperCount = individualPeriod[p].campers.length;
                              }
                              if (batchBy === 'cabin') {
                                camperCount = 0; 
                              }
                            }

                            if (activityData.locationIsShareable === false && activityData.location === currentActivityIdObjRef[activity].location && activity !== activityData.activityId) {
                              conflictFound = true;
                            }

                            if (activityData.locationIsShareable && currentActivityIdObjRef[activity].locationIsShareable === false && activityData.location === currentActivityIdObjRef[activity].location) {
                              conflictFound = true;
                            }

                            if(individualPeriod[p]?.campers?.includes(camperID)) {
                              periodIsFree = false;
                              conflictFound = true;

                              if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                                console.log({p, activity})
                              }

                            }

                          }
                        }
                      }   
                      if (batchBy === 'cabin') {
                        for(const preferencesedActivity in scheduleByActivityClone) {
                          if (preferencesedActivity !== activityData.activityId) {
                            continue;
                          }
                          for(const day in scheduleByActivityClone[preferencesedActivity]) {
                            for(const preferencesPeriod in scheduleByActivityClone[preferencesedActivity][day]) {
      
                              const periodData = scheduleByActivityClone[preferencesedActivity][day][preferencesPeriod];
      
                              if (preferencesPeriod === period && !periodData.cabinGroups.includes(cabinGroup) && periodData.cabinGroups.length > 0) {
                                conflictFound = true;
                              }

                              if (periodData.cabinGroups.includes(cabinGroup) && preferencesPeriod !== period) {
                                existingCabinGroup = true;
                              }
                              if (existingCabinGroup) {
                                break;
                              }
                            }
                            if (existingCabinGroup) {
                              break;
                            }
                          }
                          if (existingCabinGroup) {
                            break;
                          }
                        }
                      }


                      if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                        console.log("Building K Period", currentActivity, period, conflictFound, existingCabinGroup, periodIsFree, camperCount, maxKidsInt, onlyFillExisting);
                      }

                      if (existingCabinGroup) {
                        continue;
                      }

                      if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                        console.log({period, periodIsFree, camperCount, maxKidsInt})
                      }

                      if (camperCount >= maxKidsInt) {
                        continue;
                      }


                      if (onlyFillExisting && !camperCount) {
                        continue;
                      }

                      if(conflictFound) {
                        continue;
                      }

                      ['monday', 'wednesday', 'friday'].forEach((day) => {
                        if(!scheduleByActivityClone[activityData.activityId][day][period]){
                          scheduleByActivityClone[activityData.activityId][day][period] = {
                            campers: [camperID],
                            cabins: [cabin],
                            batchByCabin: batchBy === 'cabin',
                            cabinGroups: [cabinGroup],
                            roles: {}
                          };
                        }
                        else {
                          scheduleByActivityClone[activityData.activityId][day][period].campers.push(camperID);

                          if(!scheduleByActivityClone[activityData.activityId][day][period].cabins.includes(cabin)) {
                            scheduleByActivityClone[activityData.activityId][day][period].cabins.push(cabin);
                          }

                          if(!scheduleByActivityClone[activityData.activityId][day][period].cabinGroups.includes(cabinGroup)) {
                            scheduleByActivityClone[activityData.activityId][day][period].cabinGroups.push(cabinGroup);
                          }
                        }
                      });

                      kPeriodActivitySet = true;

                      if(activityData.is_two_periods && activityAvailableKPeriod[i] !== 'K5') {
                        ['monday', 'wednesday', 'friday'].forEach((day) => {
                          if(!scheduleByActivityClone[activityData.activityId][day][nextPeriod]){
                            scheduleByActivityClone[activityData.activityId][day][nextPeriod] = {
                              campers: [camperID],
                              cabins: [cabin],
                              batchByCabin: batchBy === 'cabin',
                              cabinGroups: [cabinGroup],
                              roles: {}
                            };
                          }
                          else {
                            scheduleByActivityClone[activityData.activityId][day][nextPeriod].campers.push(camperID);

                            if(!scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabins.includes(cabin)) {
                              scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabins.push(cabin);
                            }

                            if(!scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabinGroups.includes(cabinGroup)) {
                              scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabinGroups.push(cabinGroup);
                            }
                          }
                        });
                      }

                      break;
                    }
                  }
                }

                const buildGPeriods = () => {
                  if(!kPeriodActivitySet && !gPeriodActivitySet) {
                    const availableGPeriods = new Set(activityAvailableGPeriod);

                    //const isSwimActivity = swimActivities.has(currentActivity);

                    if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                      console.log("swim games ran")
                    }

                    const activityKeys = Object.keys(scheduleByActivityClone);

                    for(let i = 0; i < activityAvailableGPeriod.length; i++) {
                      let period = activityAvailableGPeriod[i];

                      if(!availableGPeriods.has(period)) {
                        continue;
                      }

                      // if (activity === 'Swim (lessons)') {
                      //   continue;
                      // }

                      let conflictFound = false;
                      let nextPeriod = period.charAt(0) + (parseInt(period.charAt(1)) + 1).toString();
                      let periodIsFree = true;
                      let camperCount = 0;
                      let cabinGroups = [];
                      let existingCabinGroup = false;

                      for(const activity of activityKeys) {
                        const days = scheduleByActivityClone[activity];
                        for(const day in days) {
                          const individualPeriod = days[day];
                          for(const p in individualPeriod) {
                            if(p !== period) {
                              continue;
                            }

                            cabinGroups = individualPeriod[p]?.cabinGroups;

                            if(activity === activityData.activityId) {
                              if(individualPeriod && individualPeriod[p]?.campers) {
                                camperCount = individualPeriod[p].campers.length;
                              }
                              if (batchBy === 'cabin') {
                                camperCount = 0; 
                              }
                            }

                            if (activityData.locationIsShareable === false && activityData.location === currentActivityIdObjRef[activity].location && activity !== activityData.activityId) {
                              conflictFound = true;
                            }

                            if (activityData.locationIsShareable && currentActivityIdObjRef[activity].locationIsShareable === false && activityData.location === currentActivityIdObjRef[activity].location) {
                              conflictFound = true;
                            }

                            if(individualPeriod[p]?.campers?.includes(camperID)) {
                              periodIsFree = false;
                              conflictFound = true;

                              if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                                console.log({p, activity, preferences})
                              }
                            }
                          }
                        }
                      }

                      if (batchBy === 'cabin') {
                        for(const preferencesedActivity in scheduleByActivityClone) {
                          if (preferencesedActivity !== activityData.activityId) {
                            continue;
                          }
                          for(const day in scheduleByActivityClone[preferencesedActivity]) {
                            for(const preferencesPeriod in scheduleByActivityClone[preferencesedActivity][day]) {
      
                              const periodData = scheduleByActivityClone[preferencesedActivity][day][preferencesPeriod];
      
                              if (preferencesPeriod === period && !periodData.cabinGroups.includes(cabinGroup) && periodData.cabinGroups.length > 0) {
                                if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                                  console.log("cooking ran")
                                }
                                conflictFound = true;
                              }

                              if (periodData.cabinGroups.includes(cabinGroup) && preferencesPeriod !== period) {
                                existingCabinGroup = true;
                              }
                              if (existingCabinGroup) {
                                break;
                              }
                            }
                            if (existingCabinGroup) {
                              break;
                            }
                          }
                          if (existingCabinGroup) {
                            break;
                          }
                        }
                      }
                      
                      if (camper.first_name === "Blake" && camper.last_name === "Heyden" && currentActivity === "Cooking") {
                        console.log("Building G Period", currentActivity, period, conflictFound, existingCabinGroup, periodIsFree, camperCount, maxKidsInt, onlyFillExisting);
                      }

                      if (camper.first_name === "Blake" && camper.last_name === "Heyden" && currentActivity === "Cooking") {
                        console.log("cooking ran")
                      }

                      if (existingCabinGroup) {
                        continue;
                      }

                      if (camper.first_name === "Blake" && camper.last_name === "Heyden" && currentActivity === "Cooking") {
                        console.log("cooking ran")
                      }

                      if (camperCount >= maxKidsInt) {
                        if (camper.first_name === "Blake" && camper.last_name === "Heyden" && currentActivity === "Cooking") {
                          console.log({period, periodIsFree, camperCount, maxKidsInt})
                        }
                        continue;
                      }

                      if (camper.first_name === "Blake" && camper.last_name === "Heyden" && currentActivity === "Cooking") {
                        console.log("cooking ran")
                      }

                      if (onlyFillExisting && !camperCount) {
                        continue;
                      }

                      if (camper.first_name === "Blake" && camper.last_name === "Heyden" && currentActivity === "Cooking") {
                        console.log("cooking ran")
                      }

                      if(conflictFound) {
                        if (camper.first_name === "Blake" && camper.last_name === "Heyden" && currentActivity === "Cooking") {
                          console.log({period})
                        }

                        continue;
                      }

                      if (camper.first_name === "Blake" && camper.last_name === "Heyden" && currentActivity === "Cooking") {
                        console.log("cooking ran")
                      }

                      ['tuesday', 'thursday', 'saturday'].forEach((day) => {
                        if(!scheduleByActivityClone[activityData.activityId][day][period]){
                          scheduleByActivityClone[activityData.activityId][day][period] = {
                            campers: [camperID],
                            cabins: [cabin],
                            batchByCabin: batchBy === 'cabin',
                            cabinGroups: [cabinGroup],
                            roles: {}
                          };
                        }
                        else {
                          scheduleByActivityClone[activityData.activityId][day][period].campers.push(camperID);

                          if(!scheduleByActivityClone[activityData.activityId][day][period].cabins.includes(cabin)) {
                            scheduleByActivityClone[activityData.activityId][day][period].cabins.push(cabin);
                          }

                          if(!scheduleByActivityClone[activityData.activityId][day][period].cabinGroups.includes(cabinGroup)) {
                            scheduleByActivityClone[activityData.activityId][day][period].cabinGroups.push(cabinGroup);
                          }
                        }
                      });

                      gPeriodActivitySet = true;

                      if(activityData.is_two_periods && activityAvailableKPeriod[i] !== 'K5') {
                        ['tuesday', 'thursday', 'saturday'].forEach((day) => {
                          if(!scheduleByActivityClone[activityData.activityId][day][nextPeriod]){
                            scheduleByActivityClone[activityData.activityId][day][nextPeriod] = {
                              campers: [camperID],
                              cabins: [cabin],
                              batchByCabin: batchBy === 'cabin',
                              cabinGroups: [cabinGroup],
                              roles: {}
                            };
                          }
                          else {
                            scheduleByActivityClone[activityData.activityId][day][nextPeriod].campers.push(camperID);

                            if(!scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabins.includes(cabin)) {
                              scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabins.push(cabin);
                            }

                            if(!scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabinGroups.includes(cabinGroup)) {
                              scheduleByActivityClone[activityData.activityId][day][nextPeriod].cabinGroups.push(cabinGroup);
                            }
                          }
                        });
                      }

                      break;
                    }
                  }
                }

                checkForMatchingKGPeriods();


                if (!onlyFillExisting) {
                  if(preferenceLevel % 2 === 0) {
                    buildKPeriods();
                    buildGPeriods();
                  }
                  else {
                    buildGPeriods();
                    buildKPeriods();
                  }
                }
              }
            }
          }
        }
      }
      setScheduleByActivity(scheduleByActivityClone);
      resolve();
    });
  }

  const campersListsRequiredAndLocationBased = async (scheduleByActivity) => { //scheduler set to 3
    let scheduleByActivityClone = _.cloneDeep(scheduleByActivity);
    processCampers(scheduleByActivityClone, 'requiredAndLocations', false, false);

    setSchedulerProcess(4);
    setLoadingPercent(15);
  }

  const campersListByPreference = async (scheduleByActivity) => { //scheduler set to 4
    let scheduleByActivityClone = _.cloneDeep(scheduleByActivity);

    processCampers(scheduleByActivityClone, 'random', false, false);

    setSchedulerProcess(5);
    setLoadingPercent(55);
  };

  const campersListFillRandoms = async () => { //scheduler set to 6

    let scheduleByActivityClone = _.cloneDeep(scheduleByActivity);

    let deletionpreferences = _.cloneDeep(scheduleByActivityClone);

    await processCampers(scheduleByActivityClone, 'random', false, false);
    
    await processCampers(scheduleByActivityClone, 'random', true, false);

    for(const activity in scheduleByActivityClone) {
      for(const day in scheduleByActivityClone[activity]) {
        for(const preferencesPeriod in scheduleByActivityClone[activity][day]) {
          const periodData = scheduleByActivityClone[activity][day][preferencesPeriod];
          if(periodData.campers.length < currentActivityIdObjRef[activity].min_kids) {
            //remove period
            delete deletionpreferences[activity][day][preferencesPeriod];
          }
        }
      }
    }

    await processCampers(scheduleByActivityClone, 'random', true, true);

    setScheduleByActivity(deletionpreferences);


    // console.log('min removed')
    // processCampers(scheduleByActivityClone, 'random', true  );

    setSchedulerProcess(6);

  };

  const fillMissingPeriods = async () => { //scheduler set to 7
    
    let scheduleByActivityClone = await _.cloneDeep(scheduleByActivity);

    let camperSchedule = {};

    let campersMissingTop4Prefences = [];

    originalCampersRef.current.forEach((camper) => {
      const camperID = camper.camperId;

      camperSchedule[camperID] = {
        activities: [],
        periods: []
      };

      const camperName = camper.first_name + ' ' + camper.last_name;
      const camperPreferences = camper.activity_preferences;
      const top4PreferencesObjs = camper.activity_preferences.slice(0, 4);
      const top4Preferences = top4PreferencesObjs.map((preference) => preference.activity);
      // console.log({top4Preferences,camperName, camperPreferences});
      const missingTop4Preferences = [];

      const masterPeriods = ['K1', 'K2', 'K3', 'K4', 'K5', 'G1', 'G2', 'G3', 'G4', 'G5'];
      const periodsScheduled = [];

      Object.keys(scheduleByActivityClone).forEach((activity) => {
        const activityIsTopPreference = top4Preferences.includes(currentActivityIdObjRef[activity].name);
        Object.keys(scheduleByActivity[activity]).forEach((day) => {
          Object.keys(scheduleByActivity[activity][day]).forEach((period) => {
            const periodData = scheduleByActivity[activity][day][period];
            if (periodData.campers.includes(camperID)) {
              if (!periodsScheduled.includes(period)) {
                periodsScheduled.push(period);
              }
              // Keep track of already scheduled activities for the camper
              camperSchedule[camperID].activities.push(activity);
              camperSchedule[camperID].periods.push(period);
            } else {
              if (activityIsTopPreference) {
                if (!missingTop4Preferences.includes(activity)) {
                  missingTop4Preferences.push(activity);
                }
              }
            }
          });
        });
      });

      const missingPeriods = masterPeriods.filter((period) => !periodsScheduled.includes(period));
      
      campersMissingTop4Prefences.push({
        camperName: camper.first_name + ' ' + camper.last_name,
        camperID,
        missingTop4Preferences,
        missingPeriods,
      });

    });

    // console.log({campersMissingTop4Prefences});

    const activitiesForMissingTop4Preferences = {};
    campersMissingTop4Prefences.forEach((camper) => {
      camper.missingTop4Preferences.forEach((activity) => {
        if (!activitiesForMissingTop4Preferences[activity]) {
          activitiesForMissingTop4Preferences[activity] = {
            campers: [],
            periods: {}
          };
        }
        if (!activitiesForMissingTop4Preferences[activity].campers.includes(camper.camperID)) {
          activitiesForMissingTop4Preferences[activity].campers.push(camper.camperID);
        }
    
        // Here, I assume `camper.missingPeriods` is the list of periods the camper is missing for this activity.
        // Update this part based on your actual logic.
        camper.missingPeriods.forEach((period) => { 
          if (!activitiesForMissingTop4Preferences[activity].periods[period]) {
            activitiesForMissingTop4Preferences[activity].periods[period] = [];
          }
          if (!activitiesForMissingTop4Preferences[activity].periods[period].includes(camper.camperID)) {
            activitiesForMissingTop4Preferences[activity].periods[period].push(camper.camperID);
          }
        });
      });
    });

    // console.log({activitiesForMissingTop4Preferences});

    Object.keys(scheduleByActivityClone).forEach((activity) => {
      const activityData = currentActivityIdObjRef[activity];
      Object.keys(scheduleByActivityClone[activity]).forEach((day) => {
        // Loop through the periods in the activitiesForMissingTop4Preferences object
        // Object.keys(activitiesForMissingTop4Preferences[activity]?.periods || {}).forEach((missingPeriod) => {
        //   // If the period doesn't exist in scheduleByActivityClone, create it
        //   const numberOfCampersToBeScheduled = activitiesForMissingTop4Preferences[activity].periods[missingPeriod].length;
        //   const minCampers = activityData.min_kids;
        // });
    
        // Original loop through the periods
        Object.keys(scheduleByActivityClone[activity][day]).forEach((period) => {
          const periodData = scheduleByActivityClone[activity][day][period];
          const activityIsTopPreferenceData = activitiesForMissingTop4Preferences[activity];
          const minCampers = activityData.min_kids;
    
          if (activityIsTopPreferenceData) {
            const campersMissingPeriod = activityIsTopPreferenceData.periods[period];

            // console.log({campersMissingPeriod, minCampers, period, activity, day});

            if (campersMissingPeriod && campersMissingPeriod.length >= minCampers && scheduleByActivityClone[activity][day][period] === undefined) {

              scheduleByActivityClone[activity][day][period] = { 
                campers: [],
                cabins: [],
                roles: {},
                batchByCabin: activityData.batch_by === 'cabin',
                cabinGroups: []
              };

              const campersScheduled = periodData.campers;
              const campersToBeScheduled = campersMissingPeriod.filter(
                (camper) => !periodData.campers.includes(camper) &&
                            !camperSchedule[camper]?.activities.includes(activity) &&
                            !camperSchedule[camper]?.periods.includes(period)
              );
              campersToBeScheduled.forEach((camper) => {
                const camperData = originalCampersRef.current.find((c) => c.camperId === camper);
                scheduleByActivityClone[activity][day][period].campers.push(camper);
                scheduleByActivityClone[activity][day][period].cabins.push(camperData.cabin);
                if (activityData.batch_by === 'cabin') {
                  const groups = currentActivityIdObjRef[activity].cabin_groups;
                  Object.entries(groups).forEach(([group, cabins]) => {
                    if (cabins.includes(camper.cabin)) {
                      if (!scheduleByActivityClone[activity][day][period].cabinGroups.includes(group)) {
                        scheduleByActivityClone[activity][day][period].cabinGroups.push(group);
                      }
                    }
                  });
                }
                camperSchedule[camper].activities.push(activity);
                camperSchedule[camper].periods.push(period);
              });
            } else {


              if (campersMissingPeriod && scheduleByActivityClone[activity][day][period]?.campers.length > 0) {
                campersMissingPeriod.forEach((camper) => {
                  const camperData = originalCampersRef.current.find((c) => c.camperId === camper);

                  let camperAvailable = true;
                  Object.keys(scheduleByActivityClone).forEach((loopActivity) => {
                    Object.keys(scheduleByActivityClone[loopActivity]).forEach((loopedDay) => {
                      Object.keys(scheduleByActivityClone[loopActivity][loopedDay]).forEach((loopedPeriod) => {
                        const loopedPeriodData = scheduleByActivityClone[loopActivity][loopedDay][loopedPeriod];
                        if (loopedPeriodData.campers.includes(camperData.camperId)) {
                          camperAvailable = false;
                        }
                      });
                    });
                  });

                  if (camperAvailable)  {
                    if (!scheduleByActivityClone[activity][day][period].campers.includes(camper)) {
                      scheduleByActivityClone[activity][day][period].campers.push(camper);
                    }
                    if (!scheduleByActivityClone[activity][day][period].cabins.includes(camperData.cabin)) {
                      scheduleByActivityClone[activity][day][period].cabins.push(camperData.cabin);
                    }
                  } else {
                    let possibleSwapPeriods = [];
                    let swapData = {};
                    Object.keys(scheduleByActivityClone).forEach((findLoopActivity) => {
                      const findLoopedActivityData = currentActivityIdObjRef[findLoopActivity];
                      if (findLoopedActivityData?.required_cabins?.length === 0 && findLoopedActivityData.batch_by !== 'cabin') {
                        Object.keys(scheduleByActivityClone[findLoopActivity]).forEach((findLoopedDay) => {
                          Object.keys(scheduleByActivityClone[findLoopActivity][findLoopedDay]).forEach((findLoopedPeriod) => {
                            const findLoopedPeriodData = scheduleByActivityClone[findLoopActivity][findLoopedDay][findLoopedPeriod];
                            if (findLoopedPeriodData.campers.includes(camperData.camperId)) {
                              if (!possibleSwapPeriods.includes(findLoopedPeriod)) {
                                possibleSwapPeriods.push(findLoopedPeriod);
                              }
                              if (!swapData[findLoopedPeriod]) {
                                swapData[findLoopedPeriod] = {
                                  activity: findLoopActivity,
                                };
                              }
                            }
                          });
                        });
                      }
                    });

                    if (possibleSwapPeriods.includes(period)) {
                      const swapPeriodData = scheduleByActivityClone[swapData[period]?.activity][day][period];
                      swapPeriodData.campers = swapPeriodData.campers.filter((c) => c !== camperData.camperId);

                      let keepCabin = false;
                      swapPeriodData.campers.forEach((c) => {
                        const cData = originalCampersRef.current.find((camper) => camper.camperId === c);
                        if (cData.cabin === camperData.cabin) {
                          keepCabin = true;
                        }
                      });

                      if (!keepCabin) {
                        swapPeriodData.cabins = swapPeriodData.cabins.filter((c) => c !== camperData.cabin);
                      }

                      if (!scheduleByActivityClone[activity][day][period].campers.includes(camper)) {
                        scheduleByActivityClone[activity][day][period].campers.push(camper);
                      }
                      if (!scheduleByActivityClone[activity][day][period].cabins.includes(camperData.cabin)) {
                        scheduleByActivityClone[activity][day][period].cabins.push(camperData.cabin);
                      }
                    }
                  }
                });
              }
            }
          }
        });
      });
    });
    
    // console.log({scheduleByActivityClone});

    setScheduleByActivity(scheduleByActivityClone);


    setSchedulerProcess(7);
  };

  //Need this but we need to remove the schedule portion and use this to cross reference the activity portion
  const createCounselorsList = async (counselors) => { //scheduler set to 7
      const counselorList = [];

      counselors.forEach((counselor) => {
        const counselorId = counselor.counselorId;
        const counselorAvailability = counselor.days;
        const counselorSchedule = {
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
        };

        let test = {
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
        };

        // Assuming the availability object is stored in a variable called `availability`
        const days = Object.keys(counselorAvailability);

        // Pick a random period from each day
        const periodsOff = {};
        let unavailablePeriods = {};

        days.forEach(day => {

          let totalKPeriods = ['K1', 'K2', 'K3', 'K4', 'K5'];
          let totalGPeriods = ['G1', 'G2', 'G3', 'G4', 'G5'];
          if(true) {

            const periods = counselorAvailability[day];

            let randomPeriod;

            if ( periods.length < 5 && periods.length >= 0) {
              let currentPeriods = []

              periods.forEach((period) => {
                currentPeriods.push(period);
              });

              let unavailableKPeriods;
              let unavailableGPeriods;
              let availableKPeriods;
              let availableGPeriods;

              //get the letter of the period
              let periodLetter = currentPeriods[0]?.charAt(0);
              if (!(day in unavailablePeriods)) {
                // Create the day key if it does not exist
                unavailablePeriods[day] = {};
              }
              if(periodLetter === "K") {
                unavailableKPeriods = totalKPeriods.filter(period => !currentPeriods.includes(period));
                availableKPeriods = totalKPeriods.filter(period => currentPeriods.includes(period));

                unavailableKPeriods.forEach((period) => {
                  unavailablePeriods[day][period] = false;
                });

                availableKPeriods.forEach((period) => {
                  unavailablePeriods[day][period] = true;
                });
              }
              else if(periodLetter === "G") {
                unavailableGPeriods = totalGPeriods.filter(period => !currentPeriods.includes(period));
                availableGPeriods = totalGPeriods.filter(period => currentPeriods.includes(period));

                unavailableGPeriods.forEach((period) => {
                  unavailablePeriods[day][period] = false;
                });

                availableGPeriods.forEach((period) => {
                  unavailablePeriods[day][period] = true;
                });
              }

              if ((day === 'monday' || day === 'wednesday' || day === 'friday') && (periods === undefined || periods.length === 0)) {
                totalKPeriods.forEach((period) => {
                  unavailablePeriods[day][period] = false;
                });
              }


              else if ((day === 'tuesday' || day === 'thursday' || day === 'saturday') && (periods === undefined || periods.length === 0)) {
                totalGPeriods.forEach((period) => {
                  unavailablePeriods[day][period] = false;
                });
              }
              periodsOff[day] = undefined;

            }
            else {
              do {
                randomPeriod = periods[Math.floor(Math.random() * periods.length)];
              } while (randomPeriod in periodsOff);
              if (counselor?.is_activity_specialist === false) {
                periodsOff[day] = randomPeriod;
              } else {
                periodsOff[day] = undefined;
              }
            }
          }
          else {
            periodsOff[day] = undefined;
          }
        });

        // Define the desired ordering of weekdays
        const weekdayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

        // Create a new object with the sorted keys
        const sortedPeriodsOff = {};
        weekdayOrder.forEach((weekday) => {
          if (periodsOff.hasOwnProperty(weekday.toLowerCase())) {
            sortedPeriodsOff[weekday.toLowerCase()] = periodsOff[weekday.toLowerCase()];
          }
        });

        // Map the periodsOff object to the counselor schedule
        Object.keys(counselorSchedule).forEach((day, index) => {
          let periodsOffArray = Object.entries(sortedPeriodsOff);

          if(periodsOffArray.length > 0 && periodsOffArray[index][1] !== undefined ) {
            counselorSchedule[day][periodsOffArray[index][1]] = {period_off : 'Free Period'};
          }
        });

        let unavailablePeriodsArray = Object.entries(unavailablePeriods);

        if(unavailablePeriodsArray.length > 0) {
          Object.entries(unavailablePeriodsArray).forEach((periodAvailability) => {
            Object.entries(periodAvailability[1][1]).forEach((entry) => {
              if(entry[1] === false) {
                //capitalize the first letter of day
                let day = periodAvailability[1][0];
                let period = entry[0]
                if (!counselorSchedule[day]) {
                  counselorSchedule[day] = {};
                }
                counselorSchedule[day][period] = {not_available : 'Free Period'};
              }
            });
          });
        }

        counselorList.push({
          counselorId: counselorId,
          availability: counselorAvailability,
          schedule: counselorSchedule,
        });
      });

      setCounselorListData(counselorList);
      setSchedulerProcess(8);
      setLoadingPercent(75);
  };

  //comment this out
  const createActivityList = async (campersListState) => { //scheduler set to 7
    setLoadingPercent(loadingPercent + 20);
      const activityList = {
        Monday: {},
        Tuesday: {},
        Wednesday: {},
        Thursday: {},
        Friday: {},
        Saturday: {}
      };

      const dayTypes = ["kday", "gday"];

      campersListState.forEach((camper) => {
        weekdays.forEach((day, dayIndex) => {
          //uppercase the first letter of day
          day = day.charAt(0).toUpperCase() + day.slice(1);
          const dayType = dayTypes[dayIndex % 2];
          const activities = camper[dayType];

          Object.entries(activities).forEach((activity, periodIndex) => {
            const activityId = activity[1].activityId;

            if (!activityList[day][activity[0]]) {
              activityList[day][activity[0]] = {};
            }

            if (!activityList[day][activity[0]][activityId]) {
              activityList[day][activity[0]][activityId] =
                activity[1].required_counselor_skills;
            }
          });
        });
      });

      setActivityListState(activityList);
      setSchedulerProcess(7);
  };

  //Use this, comment out the schedule portion if it exists
  const findCounselorWithSkill = (
    counselorList,
    activityName,
    skill,
    day,
    period
  ) => {
    let counselorWithAvailability;
    // Calculate the total number of available periods and total skills for each counselor
    counselorList.forEach(counselor => {
      let totalAvailablePeriods = 0;
      for (const day in counselor.days) {
        totalAvailablePeriods += counselor.days[day].length;
      }

      let totalSkills = 0;
      for (const activity in counselor.counselor_skills) {
        totalSkills += counselor.counselor_skills[activity].length;
      }

      // Calculate the score
      counselor.score = totalAvailablePeriods + totalSkills;
    });

    // Sort the list by score (ascending order)
    counselorList.sort((a, b) => a.score - b.score);
    for (let counselor of counselorList) {
      const matchingCounselor = counselors.find((c) => counselor.counselorId === c.counselorId);

      if (matchingCounselor.is_admin === true) {
        continue;
      }

      if (
        counselor.schedule[day] &&
        counselor.schedule[day][period] &&
        counselor.schedule[day][period].activityId === activityName &&
        counselor.schedule[day][period].role === skill
      ) {
        
        continue;
      }

      if (matchingCounselor) {
        counselorWithAvailability = counselor;
      }

      let counselorSkills = [];

      for (const activity in matchingCounselor.counselor_skills) {
        for (const skillObject of matchingCounselor.counselor_skills[activity]) {
          if (skillObject.capability === true) {
            counselorSkills.push({ activity, skill: skillObject.skill });
          }
        }
      }

      let skills = counselorSkills
        .filter(
          (skillObject) =>
            skillObject.skill === skill &&
            skillObject.activity === activityName
        )
        .map((skillObject) => skillObject.skill);
      if (
        skills &&
        skills.includes(skill) &&
        isCounselorAvailableForPeriod(counselorWithAvailability, day, period)
      ) {
        let splitRoleExists = false;

        activities.forEach((activity) => {
          // counselors.forEach((innerCounselor) => {
            activity.required_counselor_skills.forEach((requiredSkillObject) => {
              if (counselorWithAvailability.schedule[day][period]?.role === requiredSkillObject.skill && requiredSkillObject.skill === skill && requiredSkillObject.shareable) {
                splitRoleExists = true;
              }
            });
          // });
        });

        if(splitRoleExists) {
          return null;
        } else {
          return counselor;
        }
      }
    }

    return null;
  };

  //Not going to update this to activityList but instead update scheduleByActivity
  const findCounselorWithSkillPriority = (
    counselorList,
    activityName,
    skill,
    day,
    period
  ) => {
    let counselorWithAvailability;
    // Calculate the total number of available periods and total skills for each counselor
    counselorList.forEach(counselor => {
      let totalAvailablePeriods = 0;
      for (const day in counselor.days) {
        totalAvailablePeriods += counselor.days[day].length;
      }

      let totalSkills = 0;
      for (const activity in counselor.counselor_skills) {
        totalSkills += counselor.counselor_skills[activity].length;
      }

      // Calculate the score
      counselor.score = totalAvailablePeriods + totalSkills;
    });

    // Sort the list by score (ascending order)
    counselorList.sort((a, b) => a.score - b.score);
    for (let counselor of counselorList) {
      const matchingCounselor = counselors.find((c) => counselor.counselorId === c.counselorId);
  
      if (matchingCounselor.is_admin === true) {
        continue;
      }

      if (
        counselor.schedule[day] &&
        counselor.schedule[day][period] &&
        counselor.schedule[day][period].activityId === activityName &&
        counselor.schedule[day][period].role === skill
      ) {
        continue;
      }

      if (matchingCounselor) {
        counselorWithAvailability = counselor;
      }
  
      let counselorSkills = [];
  
      for (const activity in matchingCounselor.counselor_skills) {
        for (const skillObject of matchingCounselor.counselor_skills[activity]) {
          if (skillObject.capability === true) {
            counselorSkills.push({ activity, skill: skillObject.skill, priority: skillObject.priority });
          }
        }
      }
  
      let skills = counselorSkills
        .filter(
          (skillObject) =>
            skillObject.skill === skill &&
            skillObject.activity === activityName &&
            skillObject.priority === true
        )
        .map((skillObject) => skillObject.skill);
  
      if (
        skills &&
        skills.includes(skill) &&
        isCounselorAvailableForPeriod(counselorWithAvailability, day, period)
      ) {
        let splitRoleExists = false;
  
        activities.forEach((activity) => {
          activity.required_counselor_skills.forEach((requiredSkillObject) => {
            if (
              counselorWithAvailability.schedule[day][period]?.role === requiredSkillObject.skill &&
              requiredSkillObject.skill === skill &&
              requiredSkillObject.shareable
            ) {
              splitRoleExists = true;
            }
          });
        });
  
        if(splitRoleExists) {
          return null;
        } else {
          return counselor;
        }
      }
    }
  
    return null;
  };
  

  //Leave this but make sure that schedule portion is commented out
  const isCounselorAvailableForPeriod = (counselor, day, period) => {
    // Check if the counselor is available for the given day and period
    let lowerCaseDay = day.toLowerCase();
    return (counselor?.schedule[day] === undefined || counselor?.schedule[day][period] === undefined) && (counselor?.availability[lowerCaseDay] !== undefined && counselor?.availability[lowerCaseDay].includes(period));
  };

  //scheduleByActivity needs to be factored into this and the larger data object should be removed
  const updateCounselorSchedule = async () => { //scheduler set to 7
    const activityRoleList = [];
    const missingRoleList = [];
    const scheduleByActivityClone = _.cloneDeep(scheduleByActivity);
    const counselorList = _.cloneDeep(counselorListData);

    let sortedActivities = [...activities]

    let camperPreferences = campers.map(camper => {
      let activies = [];
      camper.activity_preferences.forEach(activity => {
        activies.push(activity.activity);
      });
      return {
        camperId: camper.camperId,
        preferences: activies
      };
    });

    let activityFrequencyCount = camperPreferences.reduce((acc, camper) => {
      camper.preferences.forEach(activity => {
        if (!acc[activity]) {
          acc[activity] = 0;
        }
        acc[activity]++;
      });
      return acc;
    }, {});

    // Convert the object to an array of [activity, count] pairs
    let activityArray = Object.entries(activityFrequencyCount);

    // Sort the array by count, in descending order
    activityArray.sort((a, b) => b[1] - a[1]);

    let activityFrequencyLookup = {};
    activityArray.forEach(([activity, count]) => {
      activityFrequencyLookup[activity] = count;
    });

    // Calculate the total number of roles for each activity
    let activityRoleCount = {};
    activities.forEach(activity => {
      activityRoleCount[activity.activityId] = activity.required_counselor_skills.length;
    });

    // Adjust the sortedActivities sort function
    sortedActivities.sort((a, b) => {
      const countA = activityFrequencyLookup[a.activityId] || 0;
      const countB = activityFrequencyLookup[b.activityId] || 0;

      // Add the role count to the frequency count, with a multiplier to ensure it has a significant effect
      const scoreA = countA + activityRoleCount[a.activityId] * 15;
      const scoreB = countB + activityRoleCount[b.activityId] * 15;

      return scoreB - scoreA; // sort in descending order
    });


    let skillCounts = {};
    counselorList.forEach(counselor => {
      for (const activity in counselor.counselor_skills) {
        counselor.counselor_skills[activity].forEach(skill => {
          if (skillCounts[activity]) {
            skillCounts[activity] = {
              ...skillCounts[activity],
              [skill.skill]: skillCounts[activity][skill.skill] ? skillCounts[activity][skill.skill] + 1 : 1
            }
          } else {
            skillCounts[activity] = {
              [skill.skill]: 1
            }
          }
        });
      }
    });



    //Need to check if the role has already been taken by a priorty, and if it is in the prioority list, it should not run on the second preferences. Only set it for the initial one, otherwise leave it alone

    Object.entries(scheduleByActivityClone).forEach(([activity, days]) => {
      Object.entries(days).forEach(([day, individualPeriod]) => {
        Object.keys(individualPeriod).forEach((p) => {
          sortedActivities.forEach((campActivity) => {
            if(campActivity.activityId === activity) {
              const requiredRoles = campActivity.required_counselor_skills;
              const nonSupportRoles = requiredRoles.filter(role => role.skill !== 'Support');

              nonSupportRoles.forEach((role) => {
                if (skillCounts && skillCounts[activity] && skillCounts[activity][role.skill] && skillCounts[activity][role.skill] < 4) {

                  // Check if role is already scheduled
                  if (scheduleByActivityClone[activity][day][p] && scheduleByActivityClone[activity][day][p].roles && scheduleByActivityClone[activity][day][p].roles[role.skill]) {
                    return; // Skip to next iteration
                  }

                  const counselor = findCounselorWithSkillPriority(
                    counselorList,
                    activity,
                    role.skill,
                    day,
                    p
                  );

                  if (counselor !== null) {
                    if (!counselor.schedule[day]) {
                      counselor.schedule[day] = {};
                    }
                    counselor.schedule[day][p] = {
                      activityId: activity,
                      role: role.skill,
                    };

                    // Add the activity and role details to the activityRoleList
                    activityRoleList.push({
                      activity,
                      role: role.skill,
                      day,
                      p,
                      counselor: counselor.counselor
                    });

                      if (!scheduleByActivityClone[activity][day][p]) {
                        scheduleByActivityClone[activity][day][p] = { roles: {} };
                      } else if (!scheduleByActivityClone[activity][day][p].roles) {
                        scheduleByActivityClone[activity][day][p].roles = {};
                      }

                      scheduleByActivityClone[activity][day][p].roles[role.skill] = counselor.counselorId;

                  }
                }
              });
            }
          });
        });
      });
    });

    Object.entries(scheduleByActivityClone).forEach(([activity, days]) => {
      Object.entries(days).forEach(([day, individualPeriod]) => {
        Object.keys(individualPeriod).forEach((p) => {
          sortedActivities.forEach((campActivity) => {
            if(campActivity.activityId === activity) {
              const requiredRoles = campActivity.required_counselor_skills;
              const nonSupportRoles = requiredRoles.filter(role => role.skill !== 'Support');
              if (currentActivityIdObjRef[activity]?.run_activity_first) {
                nonSupportRoles.forEach((role) => {

                  // Check if role is already scheduled
                  if (scheduleByActivityClone[activity][day][p] && scheduleByActivityClone[activity][day][p].roles && scheduleByActivityClone[activity][day][p].roles[role.skill]) {
                    return; // Skip to next iteration
                  }

                  const counselor = findCounselorWithSkillPriority(
                    counselorList,
                    activity,
                    role.skill,
                    day,
                    p
                  );

                  if (counselor !== null) {
                    if (!counselor.schedule[day]) {
                      counselor.schedule[day] = {};
                    }
                    counselor.schedule[day][p] = {
                      activityId: activity,
                      role: role.skill,
                    };

                    // Add the activity and role details to the activityRoleList
                    activityRoleList.push({
                      activity,
                      role: role.skill,
                      day,
                      p,
                      counselor: counselor.counselor
                    });

                      if (!scheduleByActivityClone[activity][day][p]) {
                        scheduleByActivityClone[activity][day][p] = { roles: {} };
                      } else if (!scheduleByActivityClone[activity][day][p].roles) {
                        scheduleByActivityClone[activity][day][p].roles = {};
                      }

                      scheduleByActivityClone[activity][day][p].roles[role.skill] = counselor.counselorId;

                  }
                });
              }
            }
          });
        });
      });
    });

    Object.entries(scheduleByActivityClone).forEach(([activity, days]) => {
      Object.entries(days).forEach(([day, individualPeriod]) => {
        Object.keys(individualPeriod).forEach((p) => {
          sortedActivities.forEach((campActivity) => {
            if(campActivity.activityId === activity) {
              const requiredRoles = campActivity.required_counselor_skills;
              const nonSupportRoles = requiredRoles.filter(role => role.skill !== 'Support');

              nonSupportRoles.forEach((role) => {

                // Check if role is already scheduled
                if (scheduleByActivityClone[activity][day][p] && scheduleByActivityClone[activity][day][p].roles && scheduleByActivityClone[activity][day][p].roles[role.skill]) {
                  return; // Skip to next iteration
                }

                const counselor = findCounselorWithSkillPriority(
                  counselorList,
                  activity,
                  role.skill,
                  day,
                  p
                );

                if (counselor !== null) {
                  if (!counselor.schedule[day]) {
                    counselor.schedule[day] = {};
                  }
                  counselor.schedule[day][p] = {
                    activityId: activity,
                    role: role.skill,
                  };

                  // Add the activity and role details to the activityRoleList
                  activityRoleList.push({
                    activity,
                    role: role.skill,
                    day,
                    p,
                    counselor: counselor.counselor
                  });

                    if (!scheduleByActivityClone[activity][day][p]) {
                      scheduleByActivityClone[activity][day][p] = { roles: {} };
                    } else if (!scheduleByActivityClone[activity][day][p].roles) {
                      scheduleByActivityClone[activity][day][p].roles = {};
                    }

                    scheduleByActivityClone[activity][day][p].roles[role.skill] = counselor.counselorId;

                }
              });

            }
          });
        });
      });
    });

    Object.entries(scheduleByActivityClone).forEach(([activity, days]) => {
      Object.entries(days).forEach(([day, individualPeriod]) => {
        Object.keys(individualPeriod).forEach((p) => {
          sortedActivities.forEach((campActivity) => {
            if(campActivity.activityId === activity) {
              const requiredRoles = campActivity.required_counselor_skills;
              const supportRoles = requiredRoles.filter(role => role.skill === 'Support');

              supportRoles.forEach((role) => {

                // Check if role is already scheduled
                if (scheduleByActivityClone[activity][day][p] && scheduleByActivityClone[activity][day][p].roles && scheduleByActivityClone[activity][day][p].roles[role.skill]) {
                  return; // Skip to next iteration
                }

                const counselor = findCounselorWithSkillPriority(
                  counselorList,
                  activity,
                  role.skill,
                  day,
                  p
                );

                if (counselor !== null) {
                  if (!counselor.schedule[day]) {
                    counselor.schedule[day] = {};
                  }
                  counselor.schedule[day][p] = {
                    activityId: activity,
                    role: role.skill,
                  };

                  // Add the activity and role details to the activityRoleList
                  activityRoleList.push({
                    activity,
                    role: role.skill,
                    day,
                    p,
                    counselor: counselor.counselor
                  });

                    if (!scheduleByActivityClone[activity][day][p]) {
                      scheduleByActivityClone[activity][day][p] = { roles: {} };
                    } else if (!scheduleByActivityClone[activity][day][p].roles) {
                      scheduleByActivityClone[activity][day][p].roles = {};
                    }

                    scheduleByActivityClone[activity][day][p].roles[role.skill] = counselor.counselorId;

                }
              });
            }
          });
        });
      });
    });

    Object.entries(scheduleByActivityClone).forEach(([activity, days]) => {
      Object.entries(days).forEach(([day, individualPeriod]) => {
        Object.keys(individualPeriod).forEach((p) => {
          sortedActivities.forEach((campActivity) => {
            if(campActivity.activityId === activity) {
              const requiredRoles = campActivity.required_counselor_skills;
              const nonSupportRoles = requiredRoles.filter(role => role.skill !== 'Support');
              nonSupportRoles.forEach((role) => {

                if (skillCounts && skillCounts[activity] && skillCounts[activity][role.skill] && skillCounts[activity][role.skill] < 4) {

                  // Check if role is already scheduled
                  if (scheduleByActivityClone[activity][day][p] && scheduleByActivityClone[activity][day][p].roles && scheduleByActivityClone[activity][day][p].roles[role.skill]) {
                    return; // Skip to next iteration
                  }

                  const counselor = findCounselorWithSkill(
                    counselorList,
                    activity,
                    role.skill,
                    day,
                    p
                  );
  
                  if (counselor !== null) {
                    if (!counselor.schedule[day]) {
                      counselor.schedule[day] = {};
                    }
                    counselor.schedule[day][p] = {
                      activityId: activity,
                      role: role.skill,
                    };
  
                    // Add the activity and role details to the activityRoleList
                    activityRoleList.push({
                      activity,
                      role: role.skill,
                      day,
                      p,
                      counselor: counselor.counselor
                    });
  
                    if (!scheduleByActivityClone[activity][day][p]) {
                      scheduleByActivityClone[activity][day][p] = { roles: {} };
                    } else if (!scheduleByActivityClone[activity][day][p].roles) {
                      scheduleByActivityClone[activity][day][p].roles = {};
                    }
                  
                    scheduleByActivityClone[activity][day][p].roles[role.skill] = counselor.counselorId;
  
                  }
                  else {
                    missingRoleList.push({
                      activity,
                      role: role.skill,
                      day,
                      p,
                      counselor: 'none'
                    });
                  }
                }
              });
            }
          });
        });
      });
    });

    Object.entries(scheduleByActivityClone).forEach(([activity, days]) => {
      Object.entries(days).forEach(([day, individualPeriod]) => {
        Object.keys(individualPeriod).forEach((p) => {
          sortedActivities.forEach((campActivity) => {
            if(campActivity.activityId === activity) {
              const requiredRoles = campActivity.required_counselor_skills;
              const nonSupportRoles = requiredRoles.filter(role => role.skill !== 'Support');
              if (currentActivityIdObjRef[activity]?.run_activity_first) {
                nonSupportRoles.forEach((role) => {

                  // Check if role is already scheduled
                  if (scheduleByActivityClone[activity][day][p] && scheduleByActivityClone[activity][day][p].roles && scheduleByActivityClone[activity][day][p].roles[role.skill]) {
                    return; // Skip to next iteration
                  }

                  const counselor = findCounselorWithSkill(
                    counselorList,
                    activity,
                    role.skill,
                    day,
                    p
                  );

                  if (counselor !== null) {
                    if (!counselor.schedule[day]) {
                      counselor.schedule[day] = {};
                    }
                    counselor.schedule[day][p] = {
                      activityId: activity,
                      role: role.skill,
                    };

                    // Add the activity and role details to the activityRoleList
                    activityRoleList.push({
                      activity,
                      role: role.skill,
                      day,
                      p,
                      counselor: counselor.counselor
                    });

                    if (!scheduleByActivityClone[activity][day][p]) {
                      scheduleByActivityClone[activity][day][p] = { roles: {} };
                    } else if (!scheduleByActivityClone[activity][day][p].roles) {
                      scheduleByActivityClone[activity][day][p].roles = {};
                    }
                  
                    scheduleByActivityClone[activity][day][p].roles[role.skill] = counselor.counselorId;

                  }
                  else {
                    missingRoleList.push({
                      activity,
                      role: role.skill,
                      day,
                      p,
                      counselor: 'none'
                    });
                  }
                });
              }
            }
          });
        });
      });
    });

    Object.entries(scheduleByActivityClone).forEach(([activity, days]) => {
      Object.entries(days).forEach(([day, individualPeriod]) => {
        Object.keys(individualPeriod).forEach((p) => {
          sortedActivities.forEach((campActivity) => {
            if(campActivity.activityId === activity) {
              const requiredRoles = campActivity.required_counselor_skills;
              const nonSupportRoles = requiredRoles.filter(role => role.skill !== 'Support');

              nonSupportRoles.forEach((role) => {

                // Check if role is already scheduled
                if (scheduleByActivityClone[activity][day][p] && scheduleByActivityClone[activity][day][p].roles && scheduleByActivityClone[activity][day][p].roles[role.skill]) {
                  return; // Skip to next iteration
                }

                const counselor = findCounselorWithSkill(
                  counselorList,
                  activity,
                  role.skill,
                  day,
                  p
                );

                if (counselor !== null) {
                  if (!counselor.schedule[day]) {
                    counselor.schedule[day] = {};
                  }
                  counselor.schedule[day][p] = {
                    activityId: activity,
                    role: role.skill,
                  };

                  // Add the activity and role details to the activityRoleList
                  activityRoleList.push({
                    activity,
                    role: role.skill,
                    day,
                    p,
                    counselor: counselor.counselor
                  });

                  if (!scheduleByActivityClone[activity][day][p]) {
                    scheduleByActivityClone[activity][day][p] = { roles: {} };
                  } else if (!scheduleByActivityClone[activity][day][p].roles) {
                    scheduleByActivityClone[activity][day][p].roles = {};
                  }
                
                  scheduleByActivityClone[activity][day][p].roles[role.skill] = counselor.counselorId;

                }
                else {
                  missingRoleList.push({
                    activity,
                    role: role.skill,
                    day,
                    p,
                    counselor: 'none'
                  });
                }
              });
            }
          });
        });
      });
    });

    Object.entries(scheduleByActivityClone).forEach(([activity, days]) => {
      Object.entries(days).forEach(([day, individualPeriod]) => {
        Object.keys(individualPeriod).forEach((p) => {
          sortedActivities.forEach((campActivity) => {
            if(campActivity.activityId === activity) {
              const requiredRoles = campActivity.required_counselor_skills;
              const supportRoles = requiredRoles.filter(role => role.skill === 'Support');

              supportRoles.forEach((role) => {

                // Check if role is already scheduled
                if (scheduleByActivityClone[activity][day][p] && scheduleByActivityClone[activity][day][p].roles && scheduleByActivityClone[activity][day][p].roles[role.skill]) {
                  return; // Skip to next iteration
                }

                const counselor = findCounselorWithSkill(
                  counselorList,
                  activity,
                  role.skill,
                  day,
                  p
                );

                if (counselor !== null) {
                  if (!counselor.schedule[day]) {
                    counselor.schedule[day] = {};
                  }
                  counselor.schedule[day][p] = {
                    activityId: activity,
                    role: role.skill,
                  };

                  // Add the activity and role details to the activityRoleList
                  activityRoleList.push({
                    activity,
                    role: role.skill,
                    day,
                    p,
                    counselor: counselor.counselor
                  });

                  if (!scheduleByActivityClone[activity][day][p]) {
                    scheduleByActivityClone[activity][day][p] = { roles: {} };
                  } else if (!scheduleByActivityClone[activity][day][p].roles) {
                    scheduleByActivityClone[activity][day][p].roles = {};
                  }
                
                  scheduleByActivityClone[activity][day][p].roles[role.skill] = counselor.counselorId;

                }
                else {
                  missingRoleList.push({
                    activity,
                    role: role.skill,
                    day,
                    p,
                    counselor: 'none'
                  });
                }
              });
            }
          });
        });
      });
    });

  
    await optimizeSchedule(counselorList, scheduleByActivityClone, sortedActivities);


    setMissingRoles(missingRoleList);
    setMatchingCounselors(activityRoleList);
    setScheduleByActivity(scheduleByActivityClone);
    setCounselorListData(counselorList);
    setSchedulerProcess(9);
    setLoadingPercent(85);
  };

  function optimizeSchedule(counselorList, scheduleByActivityClone, sortedActivities) {
    return new Promise((resolve, reject) => {
      let swapCount = 0;
      const MAX_SWAPS = 300; // to avoid infinite loop
      while (swapCount < MAX_SWAPS) {
        console.log('swapCount', swapCount);
        let swapMade = false;
  
        for (let activityId in scheduleByActivityClone) {
          for (let day in scheduleByActivityClone[activityId]) {
            for (let period in scheduleByActivityClone[activityId][day]) {
              let roles = scheduleByActivityClone[activityId][day][period].roles;
              let activity = sortedActivities.find(a => a.activityId === activityId);
  
              for (let requiredRole of activity.required_counselor_skills) {
                if (!roles[requiredRole.skill]) { // if role is missing
                  let missingRole = requiredRole.skill;
                  let counselorsWithRequiredSkill = counselorList.filter(c => {
                    const counselor = counselors.find((innerC) => innerC.counselorId === c.counselorId);
                    if (counselor.counselor_skills?.hasOwnProperty(activityId)) {
                      let available = c.availability[day]?.includes(period);
                      let isNotAdmin = counselor.is_admin !== true;
                      let foundSkill;

                      if (available && isNotAdmin) {
                        foundSkill = counselor.counselor_skills[activityId]?.find(s => {
                          return s.skill === requiredRole.skill && s.capability;
                        });
                      }
                      return foundSkill !== undefined;
                    }
                  });
  
                  // Split counselors with required skill into those who are free and those who are not
                  let freeCounselors = counselorsWithRequiredSkill.filter(c => !c.schedule[day][period]);
                  let occupiedCounselors = counselorsWithRequiredSkill.filter(c => c.schedule[day][period]);
  
                  if (freeCounselors.length > 0) {
                    // Assign first free counselor to the missing role
                    let counselor = freeCounselors[0];
                    counselor.schedule[day][period] = {
                      activityId: activityId,
                      role: requiredRole.skill,
                    };
                    roles[requiredRole.skill] = counselor.counselorId;
                    swapMade = true;
                    swapCount++;
                    break;
                  } else {
                    for (let counselor of occupiedCounselors) {
                      const currentRole = counselor.schedule[day][period].role;
                      let potentialReplacements = counselorList.filter(c => {
                        const innerCounselor = counselors.find((innerC) => innerC.counselorId === c.counselorId);
                        let condition1 = counselor.counselorId !== innerCounselor.counselorId;
                        let condition2 = !innerCounselor.schedule?.[day]?.[period];
                        let condition3Value = innerCounselor?.counselor_skills?.[activityId]?.find(s => {
                          let hasSkillAndCapability = s.skill === missingRole && s.capability;
                          let available = c.availability[day]?.includes(period);
                          let notScheduled = !c.schedule[day]?.[period];
                          let isNotAdmin = innerCounselor.is_admin !== true;

                          let isAvailableDuringPeriod = false;
                          if (available) {
                            isAvailableDuringPeriod = true;
                          } 

                          return hasSkillAndCapability && isAvailableDuringPeriod && notScheduled && isNotAdmin;
                        });
                      
                        let condition3 = condition3Value !== undefined;

                        return condition1 && condition2 && condition3;
                      });
  
                      if (potentialReplacements.length > 0) {
                        // reassign counselor to the missing role
                        if (!counselor.schedule[day]) {
                          counselor.schedule[day] = {};
                        }
                        if (!counselor.schedule[day][period]) {
                          counselor.schedule[day][period] = {};
                        }
                        counselor.schedule[day][period].role = missingRole;
                        counselor.schedule[day][period].activityId = activityId;
                        //delete counselor.schedule[day][period].period_off
                        delete counselor.schedule[day][period].period_off;
                        delete counselor.schedule[day][period].not_available;

                        roles[missingRole] = counselor.counselorId;
  
                        // assign a replacement to the original role
                        let replacement = potentialReplacements[0];

                        if (currentRole !== undefined) {
                          if (!replacement.schedule[day]) {
                            replacement.schedule[day] = {};
                          }
                          if (!replacement.schedule[day][period]) {
                            replacement.schedule[day][period] = {};
                          }
                          replacement.schedule[day][period] = {
                            activityId: activityId,
                            role: currentRole,
                          };
                          roles[currentRole] = replacement.counselorId;
                        }
  
                        swapMade = true;
                        swapCount++;
                        break;
                      }
                    }
                  }
  
                  if (swapMade) {
                    break;
                  }
                }
              }
  
              if (swapMade) {
                break;
              }
            }
  
            if (swapMade) {
              break;
            }
          }
          if (swapMade) {
            break;
          }
        }

        if (!swapMade) {
          break; // if no swaps were made, the schedule is already optimized
        }
      }

      let test = _.cloneDeep(counselorList);

      resolve(); // call this when your function has completed its work
    });
  }
  
  

  const updateFirebase = async () => {

      console.log({scheduleByActivity, counselorListData})

      let scheduleByActivityRef = doc(db, 'schedules', activeSchedule, 'schedule', 'schedule_by_activity');
      let counselorListDataRef = doc(db, 'schedules', activeSchedule, 'schedule', 'counselor_list_data');

      await setDoc(scheduleByActivityRef, scheduleByActivity);
      await setDoc(counselorListDataRef, {counselorListData});
      setSchedulerProcess(10);
      setLoadingPercent(100);
      setScheduleExists(true);
      setFirebaseUploaded(true);
  };

  // const chunkData = (data, chunkSize) => {
  //   let result = [];

  //   if (Array.isArray(data)) { // data is an array
  //     for (let i = 0; i < data.length; i += chunkSize) {
  //       let chunk = data.slice(i, i + chunkSize);
  //       result.push(chunk);
  //     }
  //   } else if (typeof data === 'object') { // data is an object
  //     const entries = Object.entries(data);
  //     for (let i = 0; i < entries.length; i += chunkSize) {
  //       let chunk = entries.slice(i, i + chunkSize);
  //       let obj = {};
  //       chunk.forEach(([key, value]) => {
  //         obj[key] = value;
  //       });
  //       result.push(obj);
  //     }
  //   }

  //   return result;
  // };

  //filters
  const filterData = (
    data,
    searchTerm,
    selectedValues,
    selectedCabins
  ) => {
    let filteredData = data;
  
    // Filter by searchTerm
    if (searchTerm !== "") {
      const searchTerms = searchTerm.toLowerCase().split(" ");
  
      filteredData = filteredData.filter((item) => {
        const isCamper = item.camperId !== undefined;
        const isCounselor = item.counselorId !== undefined;
  
        let counselor;
  
        if (item.counselorId !== undefined) {
          counselor = counselors.find((c) => c.counselorId === item.counselorId);
        }
  
        const firstName = (
          isCamper ? item.first_name : (counselor ? counselor.first_name : '')
        ).toLowerCase();
        
        const lastName = (
          isCamper ? item.last_name : (counselor ? counselor.last_name : '')
        ).toLowerCase();        
  
        const cabin = isCamper ? item.cabin.toLowerCase() : "";
  
        const nameMatchesSearchTerms = searchTerms.every((term) =>
          firstName.includes(term) || lastName.includes(term) || cabin.includes(term)
        );
  
        return nameMatchesSearchTerms;
      });
    }
  
    // Filter by selectedValues (Counselor Ids)
    if (selectedValues.length > 0) {
      filteredData = filteredData.filter((item) => selectedValues.includes(item.counselorId));
    }
  
    // Filter by selectedCabins
    if (selectedCabins.length > 0) {
      filteredData = filteredData.filter((item) => selectedCabins.includes(item.cabin));
    }
  
    return filteredData;
  };

  const renderDay = (day, schedule, counselor) => {

    const masterKPeriods = ['K1', 'K2', 'K3', 'K4', 'K5'];
    const masterGPeriods = ['G1', 'G2', 'G3', 'G4', 'G5'];

    const masterPeriods = ['monday', 'wednesday', 'friday'].includes(day.toLowerCase()) ? masterKPeriods : masterGPeriods;

    const selectedPeriods = Object.keys(selectedValues);

    const filteredPeriods = selectedPeriods.length > 0 ? masterPeriods.filter(period => selectedValues[period]) : masterPeriods;

    const allPeriodsUnavailable = masterPeriods.every(period => schedule[period] && schedule[period].not_available !== undefined);

    // Otherwise, render the day and periods
    return (
      <div className="day" key={day}>
        <h2>{day}</h2>
        {filteredPeriods
        .sort((a, b) => {
          //sort alphanumerically
          return a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'});
        })
        .map((period) => {

          const availableActivities = [];
          // Loop through all activities in scheduleByActivity
          for (const loopActivity in missingCounselorRoles) {
            // Loop through all days for the current activity
            if (
              missingCounselorRoles[loopActivity] &&
              missingCounselorRoles[loopActivity][day.toLowerCase()] &&
              missingCounselorRoles[loopActivity][day.toLowerCase()][period]
            ) {
              let roles = [];
              missingCounselorRoles[loopActivity][day.toLowerCase()][period].forEach((role) => {
                roles.push(
                  {
                    skill: role,
                    activity: loopActivity
                  }
                );
              });
              availableActivities.push({
                activity: loopActivity,
                roles
              });
            }
          }

          const unavailableActivities = [];

          // Loop through all activities in scheduleByActivity
          for (const loopActivity in scheduleByActivity) {
            // Loop through all days for the current activity
            if (
              scheduleByActivity[loopActivity] &&
              scheduleByActivity[loopActivity][day.toLowerCase()] &&
              scheduleByActivity[loopActivity][day.toLowerCase()][period]
            ) {
              let roles = [];
              for (const role in scheduleByActivity[loopActivity][day.toLowerCase()][period].roles) {
                // Check if the role is available, if not add it to unavailable
                const isAvailable = availableActivities.some(activityObj => {
                  return activityObj.activity === loopActivity && activityObj.roles.some(roleObj => roleObj.skill === role)
                });
                
                if (!isAvailable) {
                  roles.push(
                    {
                      skill: role,
                      activity: loopActivity
                    }
                  );
                }
              }

              // Only add to unavailableActivities if there are any unavailable roles
              if (roles.length > 0) {
                unavailableActivities.push({
                  activity: loopActivity,
                  roles
                });
              }
            }
          }
          if (!schedule[period]) {
            return (
              <select 
                className="missing" 
                value="Missing Period"
                onChange={(e) => handleSelectChangeCounselor(
                  period, 
                  day, 
                  null, 
                  e.target.options[e.target.selectedIndex].getAttribute('data-activity'), 
                  counselor, 
                  '', 
                  e.target.options[e.target.selectedIndex].getAttribute('data-role')
                )}
              >
                <option disabled value="Missing Period">Missing Period</option>
                <option value="Free Period" data-activity="Free Period" data-role="Free Period">{period} - Free Period</option>
                {availableActivities
                .sort((a, b) => {
                  //sort by data.activity
                  return a.activity.localeCompare(b.activity, undefined, {numeric: true, sensitivity: 'base'});
                })
                .map((data) => (
                  Object.values(data.roles)
                  .sort((a, b) => {
                    //sort by role.skill
                    return a.skill.localeCompare(b.skill, undefined, {numeric: true, sensitivity: 'base'});
                  })
                  .map((role, index) => {
                    return (
                      <option key={index} data-activity={currentActivityObjRef[role.activity].activityId} data-role={role.skill} value={currentActivityObjRef[role.activity].activityId + role.skill}>{period} - {role.activity} - {role.skill} - (role unset)</option>
                    );
                  })
                ))}
                {unavailableActivities
                .sort((a, b) => {
                  //sort by data.activity
                  return a.activity.localeCompare(b.activity, undefined, {numeric: true, sensitivity: 'base'});
                })
                .map((data) => (
                  Object.values(data.roles)
                  .sort((a, b) => {
                    //sort by role.skill
                    return a.skill.localeCompare(b.skill, undefined, {numeric: true, sensitivity: 'base'});
                  })
                  .map((role, index) => {
                    return (
                      <option key={index} data-activity={currentActivityIdObjRef[role.activity]?.activityId} data-role={role.skill} value={currentActivityIdObjRef[role.activity]?.activityId + role.skill}>{period} - {role.activity} - {role.skill} - (fulfilled)</option>
                    );
                  })
                ))}
              </select>
            );
          }

          if(schedule[period].period_off !== undefined) {
            let period_off = schedule[period].period_off;

            return (
              <select 
                className="period-off" 
                value={period_off}
                onChange={(e) => handleSelectChangeCounselor(
                  period, 
                  day, 
                  null, 
                  e.target.options[e.target.selectedIndex].getAttribute('data-activity'), 
                  counselor, 
                  '', 
                  e.target.options[e.target.selectedIndex].getAttribute('data-role')
                )}
              >
                <option value="Free Period" data-activity="Free Period" data-role="Free Period">{period} - Free Period</option>
                {availableActivities
                .sort((a, b) => {
                  //sort by data.activity
                  return a.activity.localeCompare(b.activity, undefined, {numeric: true, sensitivity: 'base'});
                })
                .map((data) => (
                  Object.values(data.roles)
                  .sort((a, b) => {
                    //sort by role.skill
                    return a.skill.localeCompare(b.skill, undefined, {numeric: true, sensitivity: 'base'});
                  })
                  .map((role, index) => {
                    return (
                      <option key={index} data-activity={currentActivityObjRef[data.activity].activityId} data-role={role.skill} value={currentActivityObjRef[data.activity].activityId + role.skill}>{period} - {data.activity} - {role.skill} - (role unset)</option>
                    );
                  })
                ))}
                {unavailableActivities
                .sort((a, b) => {
                  //sort by data.activity
                  return a.activity.localeCompare(b.activity, undefined, {numeric: true, sensitivity: 'base'});
                })
                .map((data) => (
                  Object.values(data.roles)
                  .sort((a, b) => {
                    //sort by role.skill
                    return a.skill.localeCompare(b.skill, undefined, {numeric: true, sensitivity: 'base'});
                  })
                  .map((role, index) => {
                    return (
                      <option key={index} data-activity={currentActivityIdObjRef[role.activity]?.activityId} data-role={role.skill} value={currentActivityIdObjRef[role.activity]?.activityId + role.skill}>{period} - {role.activity} - {role.skill} - (fulfilled)</option>
                    );
                  })
                ))}
              </select>
            );
          }
          else if(schedule[period].not_available !== undefined) {
            let period_off = schedule[period].not_available;
            return (
              <select 
                className="unavailable" 
                value={period_off}
                onChange={(e) => handleSelectChangeCounselor(
                  period, 
                  day, 
                  null, 
                  e.target.options[e.target.selectedIndex].getAttribute('data-activity'), 
                  counselor, 
                  '', 
                  e.target.options[e.target.selectedIndex].getAttribute('data-role')
                )}
              >
                {allPeriodsUnavailable ? <option value={period_off}>{period} - Day Off</option> : <option value="Free Period" data-activity="Free Period" data-role="Free Period">{period} - Free Period</option>}
                {availableActivities
                .sort((a, b) => {
                  //sort by data.activity
                  return a.activity.localeCompare(b.activity, undefined, {numeric: true, sensitivity: 'base'});
                })
                .map((data) => (
                  Object.values(data.roles)
                  .sort((a, b) => {
                    //sort by role.skill
                    return a.skill.localeCompare(b.skill, undefined, {numeric: true, sensitivity: 'base'});
                  })
                  .map((role, index) => {
                    return (
                      <option key={index} data-activity={currentActivityObjRef[data.activity].activityId} data-role={role.skill} value={currentActivityObjRef[data.activity].activityId + role.skill}>{period} - {data.activity} - {role.skill} - (role unset)</option>
                    );
                  })
                ))}
                {unavailableActivities
                .sort((a, b) => {
                  //sort by data.activity
                  return a.activity.localeCompare(b.activity, undefined, {numeric: true, sensitivity: 'base'});
                })
                .map((data) => (
                  Object.values(data.roles)
                  .sort((a, b) => {
                    //sort by role.skill
                    return a.skill.localeCompare(b.skill, undefined, {numeric: true, sensitivity: 'base'});
                  })
                  .map((role, index) => {
                    return (
                      <option key={index} data-activity={currentActivityIdObjRef[role.activity]?.activityId} data-role={role.skill} value={currentActivityIdObjRef[role.activity]?.activityId + role.skill}>{period} - {role.activity} - {role.skill} - (fulfilled)</option>
                    );
                  })
                ))}
              </select>
            );
          }
          else {
            let activityId = schedule[period].activityId;
            let roleName = schedule[period].role;

            // Format the activityName and roleName strings
            let activityName = formatString(activityId);
            if (roleName !== "" && roleName !== undefined && roleName !== null) {
              roleName = formatString(roleName);
            }

            return (  
              <select 
                value={activityId + roleName}
                data-activity={activityId}
                onChange={(e) => handleSelectChangeCounselor(
                  period, 
                  day, 
                  activityId, 
                  e.target.options[e.target.selectedIndex].getAttribute('data-activity'), 
                  counselor, 
                  roleName, 
                  e.target.options[e.target.selectedIndex].getAttribute('data-role')
                )}
              >
                <option value={activityId + roleName}>{period} - {activityName} - {roleName}</option>
                <option value="Free Period" data-activity="Free Period" data-role="Free Period">{period} - Free Period</option>
                {availableActivities
                .sort((a, b) => {
                  //sort by data.activity
                  return a.activity.localeCompare(b.activity, undefined, {numeric: true, sensitivity: 'base'});
                })
                .map((data) => (
                  Object.values(data.roles)
                  .sort((a, b) => {
                    //sort by role.skill
                    return a.skill.localeCompare(b.skill, undefined, {numeric: true, sensitivity: 'base'});
                  })
                  .map((role, index) => {
                    return (
                      <option key={index} data-activity={currentActivityObjRef[data.activity].activityId} data-role={role.skill} value={currentActivityObjRef[data.activity].activityId + role.skill}>{period} - {data.activity} - {role.skill} - (role unset)</option>
                    );
                  })
                ))}
                {unavailableActivities
                .sort((a, b) => {
                  //sort by data.activity
                  return a.activity.localeCompare(b.activity, undefined, {numeric: true, sensitivity: 'base'});
                })
                .map((data) => (
                  Object.values(data.roles)
                  .sort((a, b) => {
                    //sort by role.skill
                    return a.skill.localeCompare(b.skill, undefined, {numeric: true, sensitivity: 'base'});
                  })
                  .map((role, index) => {
                    return (
                      <option key={index} data-activity={currentActivityIdObjRef[role.activity]?.activityId} data-role={role.skill} value={currentActivityIdObjRef[role.activity]?.activityId + role.skill}>{period} - {role.activity} - {role.skill} - (fulfilled)</option>
                    );
                  })
                ))}
              </select>
            );
          }
        })}
      </div>
    );
  };

  //general functions
  const formatString = (str) => {
    return str
      .replace(/_/g, " ")
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toUpperCase() : word.toLowerCase();
      })
      .replace(/\s+/g, " ");
  };

  const handleReRunCounselors = async () => {

    setScheduleExists(false);
    setLoadingPercent(50);

    let counselorListDataRef = doc(db, 'schedules', activeSchedule, 'schedule', 'counselor_list_data');
    await deleteDoc(counselorListDataRef);

    setCounselorListData([]);
    setActivityListState({});
    setMissingRoles([]);
    setFirebaseUploaded(false);

    let tempScheduleByActivity = _.cloneDeep(scheduleByActivity);
    Object.entries(tempScheduleByActivity).forEach(([activity, days]) => {
      Object.entries(days).forEach(([day, individualPeriod]) => {
        Object.keys(individualPeriod).forEach((p) => {
          if(individualPeriod[p].roles) {
            individualPeriod[p].roles = {};
          }
        });
      });
    });
    
    setScheduleByActivity(tempScheduleByActivity);
    setSchedulerProcess(5);
    

    setTimeout(() => { 
      createCounselorsList(counselors);
    }, 3000);

  }

  const handleReRunSchedule = async () => {

    setScheduleExists(false);
    setLoadingPercent(0);

    let scheduleByActivityRef = doc(db, 'schedules', activeSchedule, 'schedule', 'schedule_by_activity');
    let counselorListDataRef = doc(db, 'schedules', activeSchedule, 'schedule', 'counselor_list_data');
  
    await deleteDoc(scheduleByActivityRef);
    await deleteDoc(counselorListDataRef);

    setMissingRoles([]);
    setActivityListState({});
    setMatchingCounselors([]);
    setScheduleByActivity({});
    setCounselorListData([]);
    setSchedulerProcess(0);
    setFirebaseUploaded(false);

    setTimeout(() => {
      runActivityData(activities);
    }, 3000);
  }

  const handleSelectChange = async (period, day, oldActivityId, newActivityId, camper, replacingActivity) => {
    const scheduleByActivityClone = _.cloneDeep(scheduleByActivity);

    const days = day.startsWith('K') ? ['monday', 'wednesday', 'friday'] : ['tuesday', 'thursday', 'saturday'];
    days.forEach((day) => {
      if (replacingActivity) {
        const oldActivityPeriod = scheduleByActivityClone[oldActivityId][day][period];
        oldActivityPeriod.campers = oldActivityPeriod.campers.filter(id => id !== camper.camperId);
        
        // Check if there are any other campers from the same cabin still in the activity
        const cabinStillPresent = oldActivityPeriod.campers.some(id => {
            const foundCamper = campers.find(camper => camper.camperId === id);
            return foundCamper && foundCamper.cabin === camper.cabin;
        });
        
        if (!cabinStillPresent) {
          oldActivityPeriod.cabins = oldActivityPeriod.cabins.filter(cabinId => cabinId !== camper.cabin);
        }
      }
      let newActivityPeriod = scheduleByActivityClone[newActivityId][day][period];
      if (!newActivityPeriod) {
        scheduleByActivityClone[newActivityId][day][period] = {
          campers: [],
          cabins: [],
          roles: {},
          batchByCabin: false,
          cabinGroups: []
        };
        scheduleByActivityClone[newActivityId][day][period].campers.push(camper.camperId);
        scheduleByActivityClone[newActivityId][day][period].cabins.push(camper.cabin);
        scheduleByActivityClone[newActivityId][day][period].batchByCabin = currentActivityIdObjRef[newActivityId].batch_by === 'cabin';
        if (scheduleByActivityClone[newActivityId][day][period].batchByCabin) {
          const groups = currentActivityIdObjRef[newActivityId].cabin_groups;
          Object.entries(groups).forEach(([group, cabins]) => {
            if (cabins.includes(camper.cabin)) {
              if (!scheduleByActivityClone[newActivityId][day][period].cabinGroups.includes(group)) {
                scheduleByActivityClone[newActivityId][day][period].cabinGroups.push(group);
              }
            }
          });
        }

      } else {
        newActivityPeriod.campers.push(camper.camperId);
        if (!newActivityPeriod.cabins.includes(camper.cabin)) {
          newActivityPeriod.cabins.push(camper.cabin);
        }
      }
    });
  
    setScheduleByActivity(scheduleByActivityClone);
  
    let scheduleByActivityRef = doc(db, 'schedules', activeSchedule, 'schedule', 'schedule_by_activity');
    await setDoc(scheduleByActivityRef, scheduleByActivityClone);

    pullDatabase();

  };
  
  const handleSelectChangeCounselor = async (period, day, oldActivityId, newActivityId, counselor, oldRole, newRole) => {
    const scheduleByActivityClone = _.cloneDeep(scheduleByActivity);
    const counselorListDataClone = _.cloneDeep(counselorListData);
  
    let conflictFound = false;

    if (oldRole !== '') {
      const oldActivityPeriod = scheduleByActivityClone[oldActivityId][day][period];

      if (oldActivityPeriod && oldActivityPeriod.roles[oldRole] === counselor.counselorId) {
        delete oldActivityPeriod.roles[oldRole];
      }

      // Update counselorListData for old activity
      const counselorData = counselorListDataClone.find(item => item.counselorId === counselor.counselorId);
      if (counselorData && counselorData.schedule[day] && counselorData.schedule[day][period]) {
        delete counselorData.schedule[day][period];
      }
    }

    if (scheduleByActivityClone && scheduleByActivityClone[newActivityId] && scheduleByActivityClone[newActivityId][day] && scheduleByActivityClone[newActivityId][day][period] && scheduleByActivityClone[newActivityId][day][period]?.roles) {
      for (const role in scheduleByActivityClone[newActivityId][day][period].roles) {
        if (scheduleByActivityClone[newActivityId][day][period].roles[role] === counselor.counselorId) {
          delete scheduleByActivityClone[newActivityId][day][period].roles[role];
        }
      }
    }

    if (newActivityId === 'Free Period' && newRole === 'Free Period') {
      const counselorData = counselorListDataClone.find(item => item.counselorId === counselor.counselorId);
      if (!counselorData.schedule[day]) {
        counselorData.schedule[day] = {};
      }
      if (!counselorData.schedule[day][period]) {
        counselorData.schedule[day][period] = {};
      }
      counselorData.schedule[day][period] = {
        period_off: 'Free Period'
      }
    } else {
      let newActivityPeriod = scheduleByActivityClone[newActivityId][day][period];
      if (!newActivityPeriod) {
        alert('This activity does not exist for this day and period.');
        conflictFound = true;
      }
  
      if (!newActivityPeriod.roles) {
        newActivityPeriod.roles = {};
      }
  
      newActivityPeriod.roles[newRole] = counselor.counselorId;
      // Update counselorListData for new activity
      const counselorData = counselorListDataClone.find(item => item.counselorId === counselor.counselorId);
      if (!counselorData.schedule[day]) {
        counselorData.schedule[day] = {};
      }
  
      counselorData.schedule[day][period] = {
        activityId: newActivityId,
        role: newRole
      };
    }
  
    if (!conflictFound) {
      setScheduleByActivity(scheduleByActivityClone);
      setCounselorListData(counselorListDataClone);
    
      let scheduleByActivityRef = doc(db, 'schedules', activeSchedule, 'schedule', 'schedule_by_activity');
      await setDoc(scheduleByActivityRef, scheduleByActivityClone);
    
      let counselorListDataRef = doc(db, 'schedules', activeSchedule, 'schedule', 'counselor_list_data');
      await setDoc(counselorListDataRef, {counselorListDataClone});

      pullDatabase();
    }
  };
  

  const generatePeriodOptions = (schedule) => {
    const periodOptions = {};
  
    for (const loopActivity in schedule) {
      for (const day in schedule[loopActivity]) {
        for (const period in schedule[loopActivity][day]) {
          if (!periodOptions[period]) {
            periodOptions[period] = [];
          }
          if (!periodOptions[period].some(activityObject => activityObject.activity === loopActivity)) {
            periodOptions[period].push(
              {
                activity: loopActivity,
                numberOfCampers: parseInt(schedule[loopActivity][day][period].campers.length) ? parseInt(schedule[loopActivity][day][period].campers.length) : 0
              }
            );
          }
        }
      }
    }

    return periodOptions;
  }  
  

  return (
    <>
      <div id="date-wrapper">
        <h6>
          Week of {schedules && schedules.map((schedule) => {
            if(schedule.selected === true) {
              return schedule.dateRange;
            }
          })}
        </h6>
        {scheduleExists && <button className='btn' onClick={handleReRunSchedule}>
            <h6>Re-Run Schedule</h6>
        </button>}
      </div>
      <div className="tab-wrapper">
        <div
          className={`tab${activeTab === "counselors" ? " active" : ""}`}
          onClick={() => setActiveTab("counselors")}
        >
          Counselors
        </div>
        <div
          className={`tab${activeTab === "campers" ? " active" : ""}`}
          onClick={() => setActiveTab("campers")}
        >
          Campers
        </div>
        <div
          className={`tab${activeTab === "activities" ? " active" : ""}`}
          onClick={() => setActiveTab("activities")}
        >
          Activities
        </div>
        <div
          className={`tab${activeTab === "warnings" ? " active" : ""}`}
          onClick={() => setActiveTab("warnings")}
        >
          Warnings
        </div>
      </div>
      {!scheduleExists && scheduleReady && (
        <>
          <button onClick={() => runActivityData(activities)}>Run Scheduler</button>
        </>
      )}
      {!scheduleExists && (
        <>
          <h6>Loading Percent: {loadingPercent}</h6>
        </>
      )}
      {scheduleExists && (
        <>
          <div className="schedule-wrapper">
          {((activeTab === "counselors" &&  innerCounselorTab !== 'byDay') || activeTab === "campers") &&
            <input
              id="schedule-search"
              type="text"
              placeholder="Search for a camper or counselor"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          }

            {activeTab === 'counselors' && <button className='btn' onClick={handleReRunCounselors}>
              <h6>Re-Run Counselors</h6>
            </button>}
          </div>
          <div className="filters-wrapper">
            {activeTab === "counselors" && settings && uniqueValuesByDay && innerCounselorTab !== 'byDay' && (
              <>
                <div className="filter-wrapper">
                  <h2>Periods:</h2>
                  <ul>
                    {uniqueValuesByDay.map((value) => (
                      <li key={value}>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedValues?.[value] || false}
                            onChange={() => handleCheckboxChange(value)}
                          />
                          {value}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}

            {activeTab === "counselors" && weekdays && innerCounselorTab !== 'byDay' && (
              <>
                <div className="filter-wrapper days">
                  <h2>Days:</h2>
                  <ul>
                    {weekdays.map((value) => (
                      <li key={value.charAt(0).toUpperCase() + value.slice(1)}>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedDays?.[value.toLowerCase()] || false}
                            onChange={() => {
                              handleDaysChange(value.charAt(0).toUpperCase() + value.slice(1));
                            }}
                          />
                            {value.charAt(0).toUpperCase() + value.slice(1)}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}

            {(activeTab === "campers") && cabinGroups && cabinsNotInUnused && (
              <>
                <div className="filter-wrapper">
                  <h2>Cabins:</h2>
                  <ul>
                    {cabinsNotInUnused.map((cabin) => (
                      <li key={cabin.value}>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedCabins?.[cabin.value] || false}
                            onChange={() => handleCabinCheckboxChange(cabin)}
                          />
                          {cabin.label}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>

          <div id="schedule">
            {activeTab === "campers" && (
              <>
                <h2>Camper Schedules</h2>
                <div className="campers-wrapper">
                  {filteredCamperData && filteredCamperData
                    .sort((a, b) => {
                      const aFirstName = a.first_name.toLowerCase();
                      const bFirstName = b.first_name.toLowerCase();
                      const aLastName = a.last_name.toLowerCase();
                      const bLastName = b.last_name.toLowerCase();

                      const firstNameComparison = aFirstName.localeCompare(bFirstName);
                      return firstNameComparison !== 0 ? firstNameComparison : aLastName.localeCompare(bLastName);
                    })

                    .filter((camper) => {
                      if (Object.keys(selectedCabins).length === 0) return true;

                      let cabin = camper.cabin;
                      cabin = cabin.toLowerCase().replace(/\s/g, "_");
                      return selectedCabins[cabin];
                    })

                    .map((camper, index) => {
                      let uniquePeriods = new Set();

                      let gDayActivities = {};
                      let kDayActivities = {};

                      Object.entries(scheduleByActivity).forEach(([activity, days]) => {
                          Object.entries(days).forEach(([day, activityData]) => {
                              Object.entries(activityData).forEach(([period, activityDetails]) => {
                                  if (activityDetails.campers.includes(camper.camperId) && !uniquePeriods.has(period)) {
                                    uniquePeriods.add(period);


                                    const activityInfo = { id: activity, period: period};

                                    if (period.startsWith('G')) {
                                        gDayActivities[period] = activityInfo;
                                    } else if (period.startsWith('K')) {
                                        kDayActivities[period] = activityInfo;
                                    }
                                  }
                              });
                          });
                      });

                      const sortedGDayActivities = Object.entries(gDayActivities).sort(([period1], [period2]) => period1.localeCompare(period2));
                      const sortedKDayActivities = Object.entries(kDayActivities).sort(([period1], [period2]) => period1.localeCompare(period2));
                      const masterKPeriods = ['K1','K2','K3','K4','K5'];
                      const masterGPeriods = ['G1','G2','G3','G4','G5'];
                      const periodOptions = generatePeriodOptions(scheduleByActivity);

                      // Provided list of K and G days
                      const kDays = ['monday', 'wednesday', 'friday'];
                      const gDays = ['tuesday', 'thursday', 'saturday'];

                      const periodsMissingActivities = {};

                      Object.keys(scheduleByActivity).forEach((activity) => {
                        Object.keys(scheduleByActivity[activity]).forEach((day) => {
                          const existingPeriods = Object.keys(scheduleByActivity[activity][day]);

                          let masterPeriods;
                          if (kDays.includes(day)) {
                            masterPeriods = masterKPeriods;
                          } else if (gDays.includes(day)) {
                            masterPeriods = masterGPeriods;
                          } else {
                            return; // Skip this day as it's neither a kDay nor a gDay
                          }

                          const missingPeriods = masterPeriods.filter(period => !existingPeriods.includes(period));
                          missingPeriods.forEach(period => {
                            periodsMissingActivities[period] = periodsMissingActivities[period] || [];
                            if (!periodsMissingActivities[period].includes(activity)) {
                              periodsMissingActivities[period].push(activity);
                            }
                          });
                        });
                      });


                      return (
                        <div className="camper-wrapper" key={index}>
                          <div className="camper">
                            <h2>
                              {camper.first_name} {camper.last_name} - {camper.cabin}
                            </h2>
                            <h2>K Day</h2>
                            <h2>G Day</h2>
                            <div className="activity-block"></div>
                            <div className="activity-block">
                              <div className="activity-wrapper">
                                {masterKPeriods.map((period) => (
                                  <h6 key={period}>{period}</h6>
                                ))}
                              </div>
                              <div className="activity-wrapper days">

                              {periodOptions && masterKPeriods.map((period, index) => {
                                const activityArray = sortedKDayActivities.find(([p, a]) => p === period);
                                const activity = activityArray ? activityArray[1].id : '';
                                const replacingActivity = activity && activity === currentActivityIdObjRef[activity].name;

                                if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                                  console.log({activityArray, activity})
                                }

                                const oldActivityId = currentActivityIdObjRef[activity]?.activityId || null;

                                return (
                                  <select 
                                    value={activity === currentActivityIdObjRef[activity]?.activityId ? activity : ''} 
                                    key={index} 
                                    className={activity ? '' : 'missing'}
                                    onChange={(e) => handleSelectChange(
                                      period, 
                                      'K', 
                                      oldActivityId, 
                                      e.target.options[e.target.selectedIndex].getAttribute('data-activity'), // Get activityId from selected option
                                      camper,
                                      activity ? true : false
                                    )}
                                  >
                                    {activity === '' && <option disabled value='' hidden>Missing Period</option>}
                                    {periodOptions[period] && periodOptions[period]
                                    .sort((a, b) => {
                                      let aAvailable = parseInt(currentActivityIdObjRef[a.activity].max_kids) - parseInt(a.numberOfCampers);
                                      let bAvailable = parseInt(currentActivityIdObjRef[b.activity].max_kids) - parseInt(b.numberOfCampers);

                                      return bAvailable - aAvailable;
                                    })
                                    .map((loopActivity, index) => {
                                      let numberOfAvailableSpots = parseInt(currentActivityIdObjRef[loopActivity.activity].max_kids) - parseInt(loopActivity.numberOfCampers);

                                      return (
                                        <option key={index} data-activity={loopActivity.activity} value={loopActivity.activity}>{currentActivityIdObjRef[loopActivity.activity].name} - ({numberOfAvailableSpots})</option>
                                      );
                                    })}

                                    {periodsMissingActivities && periodsMissingActivities[period] && periodsMissingActivities[period].map((activity, index) => {
                                      return (
                                        <option key={index} data-activity={activity} value={activity}>{currentActivityIdObjRef[activity].name} - (Not Scheduled)</option>
                                      );
                                    })}
                                  </select>
                                );
                              })}



                              </div>
                            </div>
                            <div className="activity-block">
                              <div className="activity-wrapper">
                                {masterGPeriods.map((period) => (
                                  <h6 key={period}>{period}</h6>
                                ))}
                              </div>
                              <div className="activity-wrapper days">
                              {periodOptions && masterGPeriods.map((period, index) => {
                                const activityArray = sortedGDayActivities.find(([p, a]) => p === period);
                                const activity = activityArray ? activityArray[1].id : '';
                                const replacingActivity = activity && activity === currentActivityIdObjRef[activity].name;

                                if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                                  console.log({activity, sortedGDayActivities})
                                }

                                const oldActivityId = currentActivityIdObjRef[activity]?.activityId || null;

                                return (
                                  <select 
                                    value={activity === currentActivityIdObjRef[activity]?.activityId ? activity : ''} 
                                    key={index} 
                                    className={activity ? '' : 'missing'}
                                    onChange={(e) => handleSelectChange(
                                      period, 
                                      'G', 
                                      oldActivityId,
                                      e.target.options[e.target.selectedIndex].getAttribute('data-activity'), // Get activityId from selected option
                                      camper,
                                      activity ? true : false
                                    )}
                                  >
                                    {activity === '' && <option disabled value='' hidden>Missing Period</option>}
                                    {periodOptions[period] && periodOptions[period]
                                    .sort((a, b) => {
                                      let aAvailable = parseInt(currentActivityIdObjRef[a.activity].max_kids) - parseInt(a.numberOfCampers);
                                      let bAvailable = parseInt(currentActivityIdObjRef[b.activity].max_kids) - parseInt(b.numberOfCampers);

                                      return bAvailable - aAvailable;
                                    })
                                    .map((loopActivity, index) => {
                                      let numberOfAvailableSpots = parseInt(currentActivityIdObjRef[loopActivity.activity].max_kids) - parseInt(loopActivity.numberOfCampers);
                                      return (
                                        <option key={index} data-activity={loopActivity.activity} value={loopActivity.activity}>{currentActivityIdObjRef[loopActivity.activity].name} - ({numberOfAvailableSpots})</option>
                                      );
                                    })}
                                    {periodsMissingActivities && periodsMissingActivities[period] && periodsMissingActivities[period].map((activity, index) => {
                                      return (
                                        <option key={index} data-activity={activity} value={activity}>{currentActivityIdObjRef[activity].name} - (Not Scheduled)</option>
                                      );
                                    })}
                                  </select>
                                );
                              })}

                              </div>
                            </div>
                          </div>
                        </div>
                      );


                  })





                  }
                </div>

              </>
            )}
            {activeTab === "counselors" && (
              <>
                <div className="tab-wrapper">
                  <div className={`tab ${innerCounselorTab === 'byCounselor' ? 'active' : ''}`} onClick={() => setInnerCounselorTab('byCounselor')} >By Counselor</div>
                  <div className={`tab ${innerCounselorTab === 'byDay' ? 'active' : ''}`} onClick={() => setInnerCounselorTab('byDay')}>By Day</div>
                </div>

                {innerCounselorTab === 'byCounselor' && (

                  <>
                    <h2>Counselors Schedule By Counselor</h2>
                    <div className="counselors-wrapper">
                      {filteredCounselorData &&
                        filteredCounselorData
                        .sort((a, b) => {
                          const afilterCounselor = counselors.find((c) => c.counselorId === a.counselorId);
                          const bfilterCounselor = counselors.find((c) => c.counselorId === b.counselorId);

                          const aFirstName = afilterCounselor?.first_name.toLowerCase();
                          const bFirstName = bfilterCounselor?.first_name.toLowerCase();
                          const aLastName = afilterCounselor?.last_name.toLowerCase();
                          const bLastName = bfilterCounselor?.last_name.toLowerCase();

                          const firstNameComparison = aFirstName.localeCompare(bFirstName);
                          return firstNameComparison !== 0 ? firstNameComparison : aLastName?.localeCompare(bLastName);
                        })
                        .map((individualCounselor, index) => {
                          const counselor = counselors.find((c) => c.counselorId === individualCounselor.counselorId);
                          return (
                            <div className="counselor-wrapper" key={index}>
                              <h2 className="name">
                                {counselor?.first_name}{" "}
                                {counselor?.last_name}
                              </h2>
                              <div className="counselor">
                                {Object.keys(selectedDays).length > 0 ? (
                                  <>
                                    {Object.keys(selectedDays)
                                    .sort((a, b) => {
                                      return weekdays.indexOf(a) - weekdays.indexOf(b);
                                    })
                                    .map((day) => {
                                      if (selectedDays[day]) {
                                        return renderDay(
                                          day,
                                          individualCounselor.schedule[day],
                                          counselor
                                        );
                                      }
                                      return null;
                                    })}
                                  </>
                                ) : (
                                  <>
                                    {renderDay(
                                      "monday", 
                                      individualCounselor.schedule.monday, 
                                      counselor
                                    )}
                                    {renderDay(
                                      "tuesday",
                                      individualCounselor.schedule.tuesday,
                                      counselor
                                    )}
                                    {renderDay(
                                      "wednesday",
                                      individualCounselor.schedule.wednesday,
                                      counselor
                                    )}
                                    {renderDay(
                                      "thursday",
                                      individualCounselor.schedule.thursday,
                                      counselor
                                    )}
                                    {renderDay(
                                      "friday", 
                                      individualCounselor.schedule.friday,
                                      counselor
                                    )}
                                    {renderDay(
                                      "saturday",
                                      individualCounselor.schedule.saturday,
                                      counselor
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>  
                  
                )}

                {innerCounselorTab === 'byDay' && (

                  <>
                    <h2>Counselors Schedule By Day</h2>

                    {/* {Object.entries(scheduleByActivity).map(([activity, days]) => {
                      Object.entries(days).map(([day, individualPeriod]) => {
                        Object.keys(individualPeriod).map((p) => {
                          if(individualPeriod[p].roles) {
                            Object.entries(individualPeriod[p].roles).map(([role, counselorId]) => {
                              if (!scheduleByDay[day]) {
                                scheduleByDay[day] = {};
                              }
                              if (!scheduleByDay[day][counselorId]) {
                                scheduleByDay[day][counselorId] = {};
                              }
                              scheduleByDay[day][counselorId][p] = {
                                activity,
                                role,
                              }
                            });
                          }
                        });
                      });
                    })} */}

                    {counselorListData.length > 0 &&
                      counselorListData.map((individualCounselor, index) => {
                        Object.entries(individualCounselor.schedule).map(([day, period]) => {
                          Object.entries(period).map(([p, data]) => {
                            if (!scheduleByDay[day]) {
                              scheduleByDay[day] = {};
                            }
                            if (!scheduleByDay[day][individualCounselor.counselorId]) {
                              scheduleByDay[day][individualCounselor.counselorId] = {};
                            }
                            if (data.not_available) {
                              scheduleByDay[day][individualCounselor.counselorId][p] = {
                                not_available: data.not_available,
                              }
                            } else if (data.period_off) {
                              scheduleByDay[day][individualCounselor.counselorId][p] = {
                                period_off: data.period_off,
                              }
                            } else {
                              scheduleByDay[day][individualCounselor.counselorId][p] = {
                                activity: data.activityId,
                                role: data.role,
                              }
                            }
                          });
                        });
                      })}

                      <div className="counselors-by-day-wrapper">
                      {
                        scheduleByDay &&
                          Object.entries(scheduleByDay)
                            .sort(([dayA], [dayB]) => {
                              return weekdays.indexOf(dayA) - weekdays.indexOf(dayB);
                            })
                            .map(([day, queriedCounselors]) => {
                              let counselorsByPeriod = { ...queriedCounselors };
                              return (
                                <>
                                  <div className="day">{day}</div>
                                  {Object.entries(queriedCounselors)
                                  .sort(([counselorIdA], [counselorIdB]) => {
                                    const counselorA = counselors.find(counselor => counselor.counselorId === counselorIdA);
                                    const counselorB = counselors.find(counselor => counselor.counselorId === counselorIdB);
                                    const aFirstName = counselorA.first_name.toLowerCase();
                                    const bFirstName = counselorB.first_name.toLowerCase();
                                    const aLastName = counselorA.last_name.toLowerCase();
                                    const bLastName = counselorB.last_name.toLowerCase();

                                    const firstNameComparison = aFirstName.localeCompare(bFirstName);
                                    return firstNameComparison !== 0 ? firstNameComparison : aLastName.localeCompare(bLastName);

                                  })
                                  .map(([individualCounselor, period]) => {
                                    requiredPeriodsByDay[day].forEach((period) => {
                                      if (!scheduleByDay[day][individualCounselor][period]) {
                                        scheduleByDay[day][individualCounselor][period] = { missing: 'missing role' };
                                      }
                                    });

                                    const counselor = counselors.find(
                                      (c) => c.counselorId === individualCounselor
                                    );
                                    return (
                                      <div className="counselor-row">
                                        <h6 className="name">
                                          {counselor?.first_name}{" "}
                                          {counselor?.last_name}
                                        </h6>
                                        {Object.entries(period)
                                        .sort(([periodA], [periodB]) => {
                                          const prefixA = periodA.charAt(0).toUpperCase();
                                          const prefixB = periodB.charAt(0).toUpperCase();
                                          const numA = parseInt(periodA.slice(1), 10);
                                          const numB = parseInt(periodB.slice(1), 10);

                                          if (prefixA !== prefixB) {
                                            return prefixA === 'K' ? -1 : 1;
                                          } else {
                                            return numA - numB;
                                          }

                                        })
                                        .map(([p, data]) => {
                                          if (data.not_available) {
                                            return (
                                              <h6 className="period" key={p}>
                                                <span className="unavailable">
                                                  {p} - {data.not_available}
                                                </span>
                                              </h6>
                                            );
                                          } else if (data.period_off) {
                                            return (
                                              <h6 className="period" key={p}>
                                                <span className="period-off">
                                                  {p} - {data.period_off}
                                                </span>
                                              </h6>
                                            );
                                          } else if (data.missing) {
                                            return (
                                              <h6 className="period" key={p}>
                                                <span className="missing">
                                                  {p} - {data.missing}
                                                </span>
                                              </h6>
                                            );
                                          } else {
                                            let activityName = data.activity;
                                            let roleName = data.role;

                                            // Format the activityName and roleName strings
                                            activityName = formatString(activityName);
                                            roleName = formatString(roleName);

                                            return (
                                              <h6 className="period" key={p}>
                                                <span className="activity-name">{p} - {activityName}</span> |{" "}
                                                <span className="role">{roleName}</span>
                                              </h6>
                                            );
                                          }
                                        })}
                                      </div>
                                    );
                                  })}
                                </>
                              );
                            })
                      }

                      </div>
                  </>

                )}
              </>
            )}
            {activeTab === "activities" && (
              <div className="activities-schedule">
                <h2>Schedule By Activity</h2>
                {scheduleByActivity &&
                  Object.entries(scheduleByActivity)
                    .sort(([activityIdA], [activityIdB]) => activityIdA.localeCompare(activityIdB))
                    .map(([activityId, dayData]) => {
                      const activityPeriods = [];
                      const uniquePeriods = Array.from(new Set(Object.values(dayData).flatMap(day => Object.keys(day))));
                      return (
                        <div className="schedule-activity-wrapper" key={activityId}>
                          <h3 className="activity-name">{currentActivityIdObjRef[activityId].name}</h3>
                          <div className="activity">
                            {
                              uniquePeriods
                              .sort((periodA, periodB) => {
                                const prefixA = periodA.charAt(0).toUpperCase();
                                const prefixB = periodB.charAt(0).toUpperCase();
                                const numA = parseInt(periodA.slice(1), 10);
                                const numB = parseInt(periodB.slice(1), 10);

                                if (prefixA !== prefixB) {
                                  return prefixA === 'K' ? -1 : 1;
                                } else {
                                  return numA - numB;
                                }
                              })
                              .map(period => {

                                const day = Object.keys(dayData).find(dayKey => dayData[dayKey][period]);
                                const periodData = dayData[day][period];
                                return (
                                  <div className="period" key={period}>
                                    <div className="period-wrapper">
                                      <h4>{period}</h4>
                                      <p>Number of Campers: <span className="camper-number">{periodData.campers.length}</span></p>
                                      <p className="cabins">Cabins</p>
                                      <div className="cabins">
                                        {Array.from(new Set(periodData.campers
                                          .map(camperId => campers.find(camper => camper.camperId === camperId).cabin)))
                                          .sort((cabinA, cabinB) => {
                                            const cabinANumeric = parseInt(cabinA.split(" ")[1], 10);
                                            const cabinBNumeric = parseInt(cabinB.split(" ")[1], 10);
                                            if (!isNaN(cabinANumeric) && !isNaN(cabinBNumeric)) {
                                              return cabinANumeric - cabinBNumeric;
                                            }
                                            return cabinA.localeCompare(cabinB);
                                          })
                                          .map(cabin => (
                                            <div className="cabin" key={cabin}>
                                              {cabin}
                                            </div>
                                          ))
                                        }
                                      </div>
                                    </div>
                                    <div className="column">
                                      <div className="campers-wrapper">
                                        <h6>Campers</h6>
                                        <div className="campers">
                                          {periodData.campers
                                            .map(camperId => campers.find(camper => camper.camperId === camperId))
                                            .filter(individualCamper => {
                                              if (Object.keys(selectedCabins).length === 0)
                                                return true; // No filter applied if no cabins selected
                                              return selectedCabins[individualCamper.cabin.toLowerCase().replace(/\s/g, "_")];
                                            })
                                            .sort((a, b) => {
                                              // Sort campers alphabetically by first name, and if they match, by last name
                                              const aFirstName = a.first_name.toLowerCase();
                                              const bFirstName = b.first_name.toLowerCase();
                                              const aLastName = a.last_name.toLowerCase();
                                              const bLastName = b.last_name.toLowerCase();

                                              // Compare first names
                                              const firstNameComparison = aFirstName.localeCompare(bFirstName);

                                              // If first names are equal, compare last names, otherwise return the first name comparison result
                                              return firstNameComparison !== 0
                                                ? firstNameComparison
                                                : aLastName.localeCompare(bLastName);
                                            })
                                            .map(individualCamper => {
                                              let isTopPreference = false;
                                              for (let i = 0; i < 4 && i < individualCamper.activity_preferences.length; i++) {
                                                if (individualCamper.activity_preferences[i].activity === currentActivityIdObjRef[activityId].name) {
                                                  isTopPreference = true;
                                                  break;
                                                }
                                              }


                                              return (
                                                <div className="individual-camper" key={individualCamper.camperId}>
                                                  <div className="name">
                                                    <h6>{individualCamper.first_name}</h6>
                                                    <h6>{individualCamper.last_name}</h6>
                                                  </div>
                                                  <h6 className="camper-cabin">{individualCamper.cabin}</h6>
                                                  {isTopPreference && <h6 className="top-preference">Top Preference</h6>}
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                        <div className="counselors-wrapper">
                                          <h6>Counselors</h6>
                                          {
                                            Object.entries(dayData)
                                            .sort(([dayA], [dayB]) => weekDays.indexOf(dayA) - weekDays.indexOf(dayB))
                                            .map(([day, periods]) => (
                                              <div className="counselors">
                                                {Object.entries(periods).map(([individualPeriod, periodData]) => {
                                                  if (period === individualPeriod) {
                                                    return (
                                                      <div className="period-counselors-wrapper">
                                                        {Object.entries(periodData?.roles).map(([role, counselorId]) => {
                                                          let counselor = counselors.find(c => c.counselorId === counselorId);

                                                          return (
                                                            <div className="individual-counselor">
                                                              <h6 className="counselor-day">{day}</h6>
                                                              <div className="period-role">
                                                                <h6>{individualPeriod}</h6>
                                                                <h6>{role}</h6>
                                                              </div>
                                                              <div className="name">
                                                                <h6>{counselor.first_name}</h6>
                                                                <h6>{counselor.last_name}</h6>
                                                              </div>
                                                            </div>
                                                          )
                                                        })}
                                                      </div>
                                                    )
                                                  }
                                                })}
                                              </div>
                                            ))
                                          }
                                            {/* .sort(([dayA], [dayB]) => weekDays.indexOf(dayA) - weekDays.indexOf(dayB))
                                            .map(([day, periods]) => (
                                            <div className="counselors">
                                              {Object.entries(periods).map(([individualPeriod, value]) => (
                                                <div className="period-counselors-wrapper">
                                                  {value.map((valueData) => (
                                                    period === individualPeriod && (
                                                      <div className="individual-counselor">
                                                        <h6 className="counselor-day">{day}</h6>
                                                        <div className="period-role">
                                                          <h6>{individualPeriod}</h6>
                                                          <h6>{valueData.role}</h6>
                                                        </div>
                                                        <div className="name">
                                                          <h6>{valueData.counselor.first_name}</h6>
                                                          <h6>{valueData.counselor.last_name}</h6>
                                                        </div>
                                                      </div>
                                                    )
                                                  ))}
                                                </div>
                                              ))}
                                            </div> 
                                          ))}*/}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ); 
                              })
                            }
                          </div>
                        </div>
                      ) 
                    })
                }
              </div>
            )}

            {activeTab === "warnings" && (
              <>
                <h2>Warnings</h2>
                <h3>Campers</h3>
                <div className="warnings-wrapper campers">
                  {missingCamperPeriods && missingCamperPeriods.map((period, index) => {
                    const camper = campers.find(c => c.camperId === period.camperId);
                    return (
                      <div className="warning camper" key={index}>
                        <h6>{camper.first_name} {camper.last_name}</h6>
                        <div className="periods">
                          {period.periods.map((period, index) => (
                            <h6 key={index}>{period}</h6>
                          ))}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <h3>Counselors</h3>
                {nonSupportMissingCount && (
                  <h6>Number of Non Support Roles Missing: {nonSupportMissingCount}</h6>  
                )}
                <div className="warnings-wrapper counselors">
                  {missingCounselorRoles && Object.entries(missingCounselorRoles).map(([activity, days], index) => (
                    <div className="warning activity" key={index}>
                      <h4>{activity}</h4>
                      <div className="days">
                        {Object.entries(days)
                        .sort(([dayA], [dayB]) => weekdays.indexOf(dayA) - weekdays.indexOf(dayB))
                        .map(([day, periods], dayIndex) => (
                          <div className="day" key={dayIndex}>
                            <h6 className="day-title">{day}</h6>
                            <div className="periods">
                              {Object.entries(periods)
                              .sort(([periodA], [periodB]) => {
                                const prefixA = periodA.charAt(0).toUpperCase();
                                const prefixB = periodB.charAt(0).toUpperCase();
                                const numA = parseInt(periodA.slice(1), 10);
                                const numB = parseInt(periodB.slice(1), 10);

                                if (prefixA !== prefixB) {
                                  return prefixA === 'K' ? -1 : 1;
                                } else {
                                  return numA - numB;
                                }
                              })
                              .map(([period, roles], periodIndex) => (
                                <div className={`period ${Object.keys(roles).length === 1 ? 'single' : ''}`} key={periodIndex}>
                                  <h6 className="period-title">{period}</h6>
                                  <div className="roles">
                                    {roles.map((role, roleIndex) => (
                                      <h6 key={roleIndex}>{role}{Object.keys(roles).length > 1 && (roleIndex + 1) !== Object.keys(roles).length ? ',' : ''}</h6>
                                    ))}
                                  </div>  
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>

                <h3>Batch By Cabin</h3>
                <p>Campers that have the same cabin but do not have the activity</p>
                <div className="warnings-wrapper batch">
                  {scheduleByActivity && Object.entries(scheduleByActivity).map(([activity, days], index) => {
                      let missingCampers = [];
                      Object.entries(days).map(([day, periods], dayIndex) => {
                          let foundPeriods = [];
                          Object.entries(periods).map(([period, periodData], periodIndex) => {
                              if (periodData.batchByCabin === true && !foundPeriods.includes(period)) {
                                  campers.map((camper) => {
                                      if (!periodData.campers.includes(camper.camperId) && periodData.cabins.includes(camper.cabin) && !missingCampers.includes(camper.camperId)) {
                                          missingCampers.push(camper.camperId);
                                      }
                                  });
                                  foundPeriods.push(period);
                              }
                          });
                      });

                      if (missingCampers.length > 0) {
                          return (
                              <div className="activity-wrapper" key={index}>
                                  <div className="warning activity">
                                      <h4>{activity}</h4>
                                  </div>
                                  <div className="missing-campers">
                                      {missingCampers.map((camperId, index) => {
                                          const camper = campers.find(c => c.camperId === camperId);
                                          return (
                                              <div className="warning camper" key={index}>
                                                  <h6>{camper.first_name} {camper.last_name}</h6>
                                              </div>
                                          )
                                      })}
                                  </div>
                              </div>
                          )
                      }
                  })}
                </div>

                <h3>Duplicate Counselors</h3>
                <p>Counselors that have duplicate roles</p>
                <div className="warnings-wrapper batch">
                  {scheduleByActivity && (() => {
                    let duplicateCounselors = {};

                    {/* Object.entries(scheduleByActivity).forEach(([activity, days]) => {
                      Object.entries(days).forEach(([day, periods]) => {
                        Object.entries(periods).forEach(([period, periodData]) => {
                          if (periodData.roles) {
                            Object.entries(periodData.roles).forEach(([role, counselorId]) => {
                              if (!duplicateCounselors[counselorId]) {
                                duplicateCounselors[counselorId] = { schedule: {} };
                              }

                              const dayPeriodKey = `${day}-${period}`;
                              if (!duplicateCounselors[counselorId].schedule[dayPeriodKey]) {
                                duplicateCounselors[counselorId].schedule[dayPeriodKey] = [];
                              }
                              duplicateCounselors[counselorId].schedule[dayPeriodKey].push(role);
                            });
                          }
                        });
                      });
                    }); */}

                    Object.keys(scheduleByActivity).forEach(activity => {
                      Object.keys(scheduleByActivity[activity]).forEach(day => {
                        Object.keys(scheduleByActivity[activity][day]).forEach(period => {
                          if (scheduleByActivity[activity][day][period].roles) {
                            Object.entries(scheduleByActivity[activity][day][period].roles).forEach(([role, counselorId]) => {
                              if (!duplicateCounselors[counselorId]) {
                                duplicateCounselors[counselorId] = { schedule: {} };
                              }

                              const dayPeriodKey = `${day}-${period}`;
                              if (!duplicateCounselors[counselorId].schedule[dayPeriodKey]) {
                                duplicateCounselors[counselorId].schedule[dayPeriodKey] = [];
                              }
                              duplicateCounselors[counselorId].schedule[dayPeriodKey].push(role);
                            });
                          }
                        });
                      });
                    });

                    return Object.entries(duplicateCounselors)
                      .sort(([counselorIdA], [counselorIdB]) => {
                        const counselorA = counselors.find(c => c.counselorId === counselorIdA);
                        const counselorB = counselors.find(c => c.counselorId === counselorIdB);
                        const aFirstName = counselorA.first_name.toLowerCase();
                        const bFirstName = counselorB.first_name.toLowerCase();
                        const aLastName = counselorA.last_name.toLowerCase();
                        const bLastName = counselorB.last_name.toLowerCase();

                        const firstNameComparison = aFirstName.localeCompare(bFirstName);
                        return firstNameComparison !== 0 ? firstNameComparison : aLastName.localeCompare(bLastName);
                      })
                      .map(([counselorId, { schedule }], index) => {
                        const counselor = counselors.find(c => c.counselorId === counselorId);
                        let hasDuplicateRole = false;
                        let warnings = [];

                        Object.entries(schedule).forEach(([dayPeriodKey, roles]) => {
                          if (roles.length > 1) {
                            hasDuplicateRole = true;
                            let [day, period] = dayPeriodKey.split('-');
                            roles.forEach(role => {
                              warnings.push(`"${role}" is scheduled for ${day} on ${period}`);
                            });
                          }
                        });

                        if (hasDuplicateRole) {
                          return (
                            <div className="activity-wrapper" key={index}>
                              <div className="warning counselor">
                                <h4>{counselor.first_name} {counselor.last_name}</h4>
                                <div>
                                  <h6>Duplicate Role Warning:</h6>
                                  {warnings.map((warning, warningIndex) => (
                                    <div key={warningIndex}>
                                      <h6>Counselor is double scheduled, {warning}</h6>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return null; // No warnings for this counselor, so skip rendering
                      });
                  })()}
                </div>


                <h3>Duplicate Campers</h3>
                <p>Campers that have duplicate entries</p>
                <div className="warnings-wrapper batch">
                  {scheduleByActivity && (() => {
                    let duplicateCampers = {};
                    let loopDays = ['monday', 'tuesday'];
                    Object.entries(scheduleByActivity).map(([activity, days]) => {
                      Object.entries(days).map(([day, periods]) => {
                        if (!loopDays.includes(day)) {
                          return;
                        }
                        Object.entries(periods).map(([period, periodData]) => {
                          periodData.campers.map((camperId) => {
                            if (!duplicateCampers[camperId]) {
                              duplicateCampers[camperId] = { camperActivities: {}, periods: {} };
                            }
                            if (!duplicateCampers[camperId].camperActivities[activity]) {
                              duplicateCampers[camperId].camperActivities[activity] = [];
                            }
                            if (!duplicateCampers[camperId].periods[period]) {
                              duplicateCampers[camperId].periods[period] = [];
                            }
                            const entry = { period, activity };
                            const existingEntry = duplicateCampers[camperId].camperActivities[activity].find(
                              e => e.period === period
                            );
                            if (!existingEntry) {
                              duplicateCampers[camperId].camperActivities[activity].push(entry);
                            }
                            duplicateCampers[camperId].periods[period].push(activity);
                          });
                        });
                      });
                    });

                    return Object.entries(duplicateCampers)
                      .sort(([camperIdA], [camperIdB]) => {
                        const camperA = campers.find(c => c.camperId === camperIdA);
                        const camperB = campers.find(c => c.camperId === camperIdB);
                        const aFirstName = camperA.first_name.toLowerCase();
                        const bFirstName = camperB.first_name.toLowerCase();
                        const aLastName = camperA.last_name.toLowerCase();
                        const bLastName = camperB.last_name.toLowerCase();

                        const firstNameComparison = aFirstName.localeCompare(bFirstName);
                        return firstNameComparison !== 0 ? firstNameComparison : aLastName.localeCompare(bLastName);
                      })
                      .map(([camperId, { camperActivities, periods }], index) => {
                        const camper = campers.find(c => c.camperId === camperId);
                        let hasDuplicateID = false;
                        let hasDuplicateActivity = false;

                        let seenPeriods = {};
let seenActivities = {};
                        for (let loopActivity in camperActivities) {
                          for (let item of camperActivities[loopActivity]) {
                              // If the period or activity has been seen before, mark the appropriate boolean as true
                              if (seenPeriods[item.period]) {
                                  hasDuplicateID = true;
                              } else {
                                  seenPeriods[item.period] = true;
                              }
                              
                              if (seenActivities[item.activity]) {
                                  hasDuplicateActivity = true;
                              } else {
                                  seenActivities[item.activity] = true;
                              }
                          }
                      }

                        if (hasDuplicateID || hasDuplicateActivity) {
                          if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                            console.log({hasDuplicateID, hasDuplicateActivity})
                          }
                          return (
                            <div className="activity-wrapper" key={index}>
                              <div className="warning camper">
                                <h4>{camper.first_name} {camper.last_name}</h4>
                                {true && (
                                  <div>
                                    <h6>Duplicate ID Warning:</h6>
                                    {Object.entries(periods).map(([period, values]) => {
                                      if (values.length > 1) {
                                        return (
                                          <div key={period}>
                                            <h6>Camper has duplicate entry for {period} - {values.map(entry => `"${entry}"`).join(', ')}</h6>
                                          </div>
                                        )
                                      } 
                                    })}
                                  </div>
                                )}
                                {hasDuplicateActivity && (
                                  <div>
                                    <h6>Duplicate Activity Warning:</h6>
                                    {Object.entries(camperActivities).map(([activity, entries]) => {
                                      if (entries.length > 1) {
                                        return (
                                          <div key={activity}>
                                            <h6>Camper is scheduled for "{activity}" in {entries.map(entry => `"${entry.period}"`).join(', ')}</h6>
                                          </div>
                                        )
                                      }
                                    })} 
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }
                        return null; // No warnings for this camper, so skip rendering
                      });
                  })()}
                </div>



                <h3>Missing Top 4 Activities</h3>
                <p>Campers that do not have an activity within their top 4 preferences</p>
                <div className="warnings-wrapper batch">
                  {scheduleByActivity && (() => {
                    let camperActivities = {};
                    let missingTopActivitiesCampers = {};

                    // First loop to gather all activities per camper
                    Object.entries(scheduleByActivity).map(([activity, days]) => {
                      Object.entries(days).map(([day, periods]) => {
                        Object.entries(periods).map(([period, periodData]) => {
                          periodData.campers.map((camperId) => {
                            if (!camperActivities[camperId]) {
                              camperActivities[camperId] = new Set(currentActivityIdObjRef[activity].name);
                            } else {
                              camperActivities[camperId].add(currentActivityIdObjRef[activity].name);
                            }
                          });
                        });
                      });
                    });

                    // Second loop to check the top 4 preferences
                    Object.entries(camperActivities).map(([camperId, activities]) => {
                      const camper = originalCampersRef.current.find(c => c.camperId === camperId);
                      const topFourPreferences = camper.activity_preferences
                        .slice(0, 4)
                        .map(pref => pref.activity);

                      if (camper.first_name === "Blake" && camper.last_name === "Heyden") {
                        console.log({topFourPreferences, activities})
                      }

                      topFourPreferences.forEach(preference => {
                        if (!activities.has(preference)) {
                          if (!missingTopActivitiesCampers[camperId]) {
                            missingTopActivitiesCampers[camperId] = {
                              camperData: camper,
                              missingActivities: new Set([preference])
                            };
                          } else {
                            missingTopActivitiesCampers[camperId].missingActivities.add(preference);
                          }
                        }
                      });
                    });

                    return Object.entries(missingTopActivitiesCampers)
                    .sort(([camperIdA], [camperIdB]) => {
                      const camperA = campers.find(c => c.camperId === camperIdA);
                      const camperB = campers.find(c => c.camperId === camperIdB);

                      const aFirstName = camperA.first_name.toLowerCase();
                      const bFirstName = camperB.first_name.toLowerCase();
                      const aLastName = camperA.last_name.toLowerCase();
                      const bLastName = camperB.last_name.toLowerCase();

                      const firstNameComparison = aFirstName.localeCompare(bFirstName);
                      return firstNameComparison !== 0 ? firstNameComparison : aLastName.localeCompare(bLastName);
                    })
                    .map(([camperId, loopedCamper], index) => (
                      <div className="activity-wrapper" key={index}>
                        <div className="warning camper">
                          <h6>{loopedCamper.camperData.first_name} {loopedCamper.camperData.last_name}</h6>
                          {Array.from(loopedCamper.missingActivities).map((activity, index) => (
                            <p key={index}>Missing activity: {activity}</p>
                          ))}
                        </div>
                      </div>
                    ));
                  })()}
                </div>



              </>
            )}
          </div>
        </>
      )}
    </>
  );
}