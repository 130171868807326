import { db } from '../../FirebaseConfig.js';
import { getFirestore, collection, getDocs, writeBatch, doc, getDoc, setDoc, addDoc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';


export default function DuplicateSchedule({activeSchedule,  schedules, setActiveSchedule }) {
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const globalSettingsDocRef = doc(db, 'settings', 'general');
  const [isLoading, setIsLoading] = useState(false);


  //Update the global settings 'general' active_schedule_id (<- change schedule_id to this) to the schedule that was chose, this will be changed to have a label that is the date range, and the value will be the schedule_id
  //the select options will be the the date ranges as labels and values as the doc id
  //activeSchedule will need to be updated so setActiveSchedule will need to be passed down to this component in order to update the rest of the application

  //get teh start from teh firrestore database
  console.log({activeSchedule});
  const getStart = async () => {
    const docRef = doc(db, 'schedules', activeSchedule, 'settings', 'general');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      return docSnap.data().start;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  const getEnd = async () => {
    const docRef = doc(db, 'schedules', activeSchedule, 'settings', 'general');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      return docSnap.data().end;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  const copyCollections = async (currentSchedule) => {
    let dbStart = await getStart();
    let dbEnd = await getEnd();

    //add 7 days to the start and end date
    dbStart = dbStart + (7 * 24 * 60 * 60 * 1000);
    dbEnd = dbEnd + (7 * 24 * 60 * 60 * 1000);

    const sourceDocRef = doc(db, 'schedules', currentSchedule);

    // Create a new document in the 'schedules' collection and get its ID
    const newScheduleDoc = await addDoc(collection(db, 'schedules'), {});
    const newSchedule = newScheduleDoc.id;
    const targetDocRef = doc(db, 'schedules', newSchedule);

    try {
      // Get data from the source document
      const docSnapshot = await getDoc(sourceDocRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        console.log(data);
        await setDoc(targetDocRef, data);

        // List the names of the subcollections
        const subcollections = ['campers', 'counselors', 'activities', 'settings', 'schedule'];

        for (const subcollection of subcollections) {
          const sourceSubcollectionRef = collection(sourceDocRef, subcollection);
          const targetSubcollectionRef = collection(targetDocRef, subcollection);

          const querySnapshot = await getDocs(sourceSubcollectionRef);
          querySnapshot.forEach(async (document) => {
            const data = document.data();
            console.log(data);
            const targetDocRef = doc(targetSubcollectionRef, document.id);
            await setDoc(targetDocRef, data);
          });

          //If subcollections is equal to settings add the start and end time, which will be based on the most recent schedules start point, then increment the start and end date of those by 7 days (1 week)
          //First schedule that gets created should start on the current monday/saturday within that week

          // const targetSettingsDocRef = doc(targetSubcollectionRef, 'general');
          // await setDoc(targetSettingsDocRef, { active_schedule: newSchedule }, { merge: true });

        }

        // Set the active schedule in the global settings collection
        const individualSettingDocRef = doc(db, 'schedules', newSchedule, 'settings', 'general');

        await updateDoc(globalSettingsDocRef, {
          active_schedule_id: newSchedule,
          start: dbStart,
          end: dbEnd
        });

        await updateDoc(individualSettingDocRef, {
          schedule_id: newSchedule,
          start: dbStart,
          end: dbEnd
        });

        setActiveSchedule(newSchedule);
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error copying data:', error);
    }
  };

  const handleDuplicateClick = async (e) => {
    setIsLoading(true);
    try {
        await copyCollections(activeSchedule);
    } finally {
        setIsLoading(false);
    }
};


  const handleSelectChange = async (e) => {
    console.log(e.target.value);
    let selectedScheduleId = e.target.value;

    const individualSettingDocRef = doc(db, 'schedules', selectedScheduleId, 'settings', 'general');
    const individualSettingsData = await getDoc(individualSettingDocRef);
    const individualSettings = individualSettingsData.data();
    console.log(individualSettingsData)
    const startDate = individualSettings.start;
    const endDate = individualSettings.end;

    await updateDoc(globalSettingsDocRef, {
      active_schedule_id: selectedScheduleId,
      start: startDate,
      end: endDate
    });

    setActiveSchedule(selectedScheduleId);
  }

  return (
      <div className='schedule-control'>
          <select
            className='schedule-select'
            onChange={(e) => handleSelectChange(e)}
          >
              {schedules && schedules
              .sort((a,b) =>{
                  return b.startDate - a.startDate;
              })
              .map(schedule => {
                  return (
                      <option
                        selected={schedule.selected}
                        key={schedule.value}
                        value={schedule.value}
                      >
                        {schedule.label}
                      </option>
                  )
              })}
          </select>
          <button
              className='dupe-btn'
              disabled={isLoading}
              onClick={handleDuplicateClick}
          >
              +
          </button>
      </div>
  )
}
