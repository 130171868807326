import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import {firebaseConfig} from '../FirebaseConfig.js';
import { runTransaction, writeBatch, getDoc, doc, collection } from 'firebase/firestore';
import { db } from '../FirebaseConfig.js';



export default function DetailedInfoPeriods({activeSchedule, activity, kPeriods, gPeriods, activeKPeriods, activeGPeriods, setActivities, setSettings, activities, settings, setActivity, pullDatabase}) {

    const activityIndex = activities.findIndex((item) => item.activityId === activity.activityId);

    const handleActivityClick = async (e, period) => {
        if (activity) {
            console.log(period);
            //console.log(activity.activityId);
            //find the activity in activities


            const activityDoc = activity.activityId;
            let tempKArray = [...activeKPeriods];
            let tempGArray = [...activeGPeriods];

            console.log(tempKArray);
            console.log(tempGArray);

            if (period.startsWith('K')) {
                if (tempKArray.includes(period)) {
                    tempKArray = tempKArray.filter((item) => item !== period);
                } else {
                    tempKArray.push(period);
                }
            }  
            if (period.startsWith('G')){
                if (tempGArray.includes(period)) {
                    tempGArray = tempGArray.filter((item) => item !== period);
                } else {
                    tempGArray.push(period);
                }
            }   

            console.log(tempKArray);
            console.log(tempGArray);
            const docRef = doc(db, 'schedules', activeSchedule, 'activities', activityDoc);
            try {
                await runTransaction(db, async (transaction) => {
                    const doc = await transaction.get(docRef);
                    if (!doc.exists()) {
                        throw "Document does not exist!";
                    }
                    const tempDoc = doc.data();
                    tempDoc.k_periods = tempKArray;
                    tempDoc.g_periods = tempGArray;
                    console.log(tempDoc);

                    transaction.set(docRef, tempDoc);

                });
                console.log("Transaction successfully committed!");
            } catch (e) {
                console.log("Transaction failed: ", e);
            }

            // console.log(activityIndex);
            //console.log(activities[activityIndex]);

            //console.log(activities);

            //activities[activityIndex].k_periods = tempKArray;
            //activities[activityIndex].g_periods = tempGArray;
            //setActivities(activities);
            //setActivity(activity);
            pullDatabase();

            //console.log(activities);
            //console.log(activity);


            // //get a new batch
            // const batch = writeBatch(db);

            // //update active periods
            // const sfRef = doc(db, 'schedules', activeSchedule, 'activities', activityDoc);

            // if (tempKArray.includes(period)) {
            //     batch.update(sfRef, {"k_periods": tempKArray});
            //     // if (deleteValues) {
            //     //     batch.delete(sfRef, {"k_periods": tempKArray});
            //     // } else {
            //     //     batch.update(sfRef, {"k_periods": tempKArray});
            //     // }
            // } else {
            //     batch.update(sfRef, {"g_periods": tempGArray});
            //     // if (deleteValues) {
            //     //     batch.delete(sfRef, {"g_periods": tempGArray});
            //     // } else {
            //     //     batch.update(sfRef, {"g_periods": tempGArray});
            //     // }
            // }
            // //commit the batch
            // await batch.commit();
        }
    }

  return (
    <div className="detail-info-wrapper">
        <h5>Available Periods</h5>
        <div className="available-periods-wrapper">
            {kPeriods && kPeriods.map((period) => {
                const isActive = activeKPeriods.includes(period);
                return (
                    <div
                        key={period}
                        className={`period-wrapper ${isActive ? 'active' : ''}`}
                        onClick={(e) => handleActivityClick(e, period)}
                    >
                        {period}
                    </div>
                );
            })}

            {gPeriods && gPeriods.map((period, index) => {
                const isActive = activeGPeriods.includes(period);
                return (
                    <div
                        key={period}
                        className={`period-wrapper ${isActive ? 'active' : ''}`}
                        onClick={(e) => handleActivityClick(e, period)}
                    >
                        {period}
                    </div>
                );
            })}
        </div>
    </div>
  )
}