import React, { useState, useEffect } from "react";
import Select from "react-select";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
//import {firebaseConfig} from '../FirebaseConfig.js';
import {
  runTransaction,
  writeBatch,
  getDoc,
  doc,
  collection,
} from "firebase/firestore";
//import db from this path: camp-scheduler/src/FirebaseConfig.js
import { db } from "../../FirebaseConfig.js";

export default function CounselorRequiredCheckboxes({
  activities,
  counselors,
  counselor,
  pullDatabase,
  settings,
  activeSchedule
}) {
  let counselorSkills = counselor.counselor_skills || {};
  console.log(activities);
  console.log(counselorSkills);

  const handleChange = async (e, activityId, skill, isPriorityCheckbox) => {
    const checkboxChecked = e.target.checked;
  
    let activitySkills = counselorSkills;
  
    console.log({activitySkills, activityId});
  
    if (!Array.isArray(activitySkills[activityId])) {
      activitySkills[activityId] = [];
    }
  
    if (!activitySkills[activityId].some(s => s.skill === skill)) {
      activitySkills[activityId].push({
        skill: skill,
        capability: isPriorityCheckbox ? false : checkboxChecked,
        priority: isPriorityCheckbox ? checkboxChecked : false,
      });
    } else {
      activitySkills[activityId] = activitySkills[activityId].map((s) => {
        if (s.skill === skill) {
          return {
            ...s,
            capability: isPriorityCheckbox ? s.capability : checkboxChecked,
            priority: isPriorityCheckbox ? checkboxChecked : s.priority,
          };
        }
        return s;
      });
    }
    
    

    const counselorDoc = counselor.counselorId;
    const docRef = doc(
      db,
      "schedules",
      activeSchedule,
      "counselors",
      counselorDoc
    );

    try {
      await runTransaction(db, async (transaction) => {
        const doc = await transaction.get(docRef);
        if (!doc.exists()) {
          throw "Document does not exist!";
        }
        const tempDoc = doc.data();
        tempDoc.counselor_skills = activitySkills;

        console.log(tempDoc);

        transaction.set(docRef, tempDoc);
      });
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.log("Transaction failed: ", e);
    }

    pullDatabase();
  };

  return (
    <div className="requirements-wrapper">
      <div className="column">
        <div className="column-title">Counselor Requirements</div>

        {activities && activities.map((activity, i) => (
          <div className="activity" key={activity.name}>
            <h5 className="activity-name">{activity.name}</h5>
            <div className="required-skills">
              {activity.required_counselor_skills && activity.required_counselor_skills.map((skill) => {
                const counselorSkill = counselorSkills[
                  activity.activityId
                ]?.find((cs) => cs.skill === skill.skill);
                return (
                  <div key={skill.skill} className="required-skill">
                      <input
                        type="checkbox"
                        id={skill.skill}
                        name="skill"
                        value={skill.skill}
                        checked={counselorSkill?.capability || false}
                        onChange={(e) => {
                          handleChange(e, activity.activityId, skill.skill, false)
                          console.log(`handleChange called with activityId=${activity.activityId}, skill=${skill.skill}`) 
                        }}
                      />
                      <label htmlFor={skill.skill}>{skill.skill}</label>
                      <input
                        className="attribute-value is-priority-checkbox"
                        name="priority"
                        type="checkbox"
                        value="priority"
                        checked={counselorSkill?.priority || false}
                        onChange={(e) =>
                          handleChange(e, activity.activityId, skill.skill, true)
                        }
                      />
                      <label className="is-priority">Priority</label>
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
        ))}

        <div className="checkboxes-wrapper"></div>
      </div>
    </div>
  );
}
